import React, { useMemo, useState } from "react";
import { Swiper } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import PropTypes from "prop-types";
import Style from "./ChartSwiper.module.css";

export const ChartSwiper = ({ children, slidesPerPage, containerClassName }) => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  const numOfPages = useMemo(() => {
    return Math.ceil(
      React.Children.toArray(children).filter((child) => {
        return React.isValidElement(child);
      }).length / slidesPerPage
    );
  }, [children, slidesPerPage]);

  const { disablePrevButton, disableNextButton } = useMemo(() => {
    return {
      disablePrevButton: currentSlideIndex === 0,
      disableNextButton:
        numOfPages * slidesPerPage - slidesPerPage <= currentSlideIndex &&
        currentSlideIndex <= numOfPages * slidesPerPage - 1
    };
  }, [numOfPages, currentSlideIndex]);

  return (
    <div className={`${Style.flex_row} ${Style.swiper_container} ${containerClassName}`}>
      {numOfPages > 1 && (
        <div
          label="prev-button"
          data-testid="prev-button"
          className={`charts-swiper-button-prev ${Style.carousel_navigation_button} ${
            disablePrevButton && Style.carousel_navigation_button_disabled
          }`}
        >
          <ChevronLeft />
        </div>
      )}
      <div className={Style.carousel_container}>
        <Swiper
          onActiveIndexChange={({ activeIndex }) => {
            setCurrentSlideIndex(activeIndex);
          }}
          centerInsufficientSlides
          resizeObserver
          allowTouchMove={false}
          navigation={
            numOfPages > 1 && {
              prevEl: `.charts-swiper-button-prev`,
              nextEl: `.charts-swiper-button-next`
            }
          }
          pagination
          slidesPerGroup={slidesPerPage}
          slidesPerView={slidesPerPage}
          modules={[Navigation, Pagination]}
          className={`${Style.flex_row} ${Style.carousel}`}
        >
          {children}
        </Swiper>
      </div>
      {numOfPages > 1 && (
        <div
          label="prev-button"
          data-testid="next-button"
          className={`charts-swiper-button-next ${Style.carousel_navigation_button} ${
            disableNextButton && Style.carousel_navigation_button_disabled
          }`}
        >
          <ChevronRight />
        </div>
      )}
    </div>
  );
};

ChartSwiper.defaultProps = {
  slidesPerPage: 1,
  children: null,
  containerClassName: ""
};

ChartSwiper.propTypes = {
  slidesPerPage: PropTypes.number,
  children: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.node, PropTypes.object]),
  containerClassName: PropTypes.string
};
