import React, { useContext, useState } from "react";

const AddInventoryFeatureV2StateContext = React.createContext({
  formData: [],
  setFormData: () => {}
});

export const useAddInventoryFeatureV2StateContext = () => {
  return useContext(AddInventoryFeatureV2StateContext);
};

export const AddInventoryFeatureV2StateContextProvider = ({ children }) => {
  const [formData, setFormData] = useState([]);

  return (
    <AddInventoryFeatureV2StateContext.Provider
      value={{
        formData,
        setFormData
      }}
    >
      {children}
    </AddInventoryFeatureV2StateContext.Provider>
  );
};
