import React, { useContext, useState } from "react";
import { STATUS_OPTIONS } from '../../../../../../components/status-popup-component';

const initialState = {
  formData: [],
  isSubmitDisabled: true,
  submitStatus: STATUS_OPTIONS.NONE,
  printerInfo: {}
};

const AddAssetFeatureStateContext = React.createContext();

export const useAddAssetFeatureStateContext = () => {
  return useContext(AddAssetFeatureStateContext);
};

export const AddAssetFeatureStateContextProvider = ({ children }) => {
  const [formDataList, setFormDataList] = useState(initialState.formData);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(initialState.isSubmitDisabled);
  const [submitStatus, setSubmitStatus] = useState(initialState.submitStatus);

  return (
    <AddAssetFeatureStateContext.Provider
      value={{
        formDataList,
        setFormDataList,
        isSubmitDisabled,
        setIsSubmitDisabled,
        submitStatus,
        setSubmitStatus,
      }}
    >
      {children}
    </AddAssetFeatureStateContext.Provider>
  );
};
