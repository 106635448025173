import React, { useEffect, useState, useRef, useMemo } from "react";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";
import { useXemelgoClient } from "../../../../services/xemelgo-service";
import LoadingCircle from "../../../../components/loading/LoadingCircle";
import { ModalForm } from "../../../../components/modal-form";
import InventoryModalHeader from "../../../../components/add-inventory-v2-components/InventoryModalHeader";
import InventoryModalFooter from "../../../../components/add-inventory-v2-components/InventoryModalFooter";
import ItemTypeInformation from "../../../../components/add-inventory-v2-components/ItemTypeInformation";
import AdditionalInformation from "../../../../components/add-inventory-v2-components/AdditionalInformation";
import "./AddInventoryV2Style.css";
import { queryForLatestDetectionsByReader } from "../../../../services/get-recent-tags-service";
import { getFormattedDate, getCurrentTimestamp } from "../../../../common/utils";
import { naturalSort, binaryToHex, decimalToBinary } from "../../../../common/Utilities";
import generateCustomIdentifier from "../../utils/generate-custom-identifier";
import BulkCreateTabFeature from "./features/bulk-create-tab-feature";
import {
  useAddInventoryFeatureV2StateContext,
  AddInventoryFeatureV2StateContextProvider
} from "./contexts/add-inventory-feature-v2-state-context/AddInventoryFeatureV2StateContext";
import useInventoryFormDataParser from "./hooks/use-inventory-form-data-parser";
import {
  AddInventoryFeatureV2ConfigContextProvider,
  useAddInventoryFeatureV2ConfigContext
} from "./contexts/add-inventory-feature-v2-config-context/AddInventoryFeatureV2ConfigContext";
import useGeneratePrintTagCommands from "../../../../hooks/use-generate-print-tag-payload";
import { PRINT_TYPES, SOLUTIONS } from "../../../../data/constants";
import TabBar from "../../../../components/tab-bar";
import useMixpanelContext from "../../../../context/mixpanel-context";
import {
  INVENTORY_ONBOARDING_V2,
  INVENTORY_ONBOARDING_V2_STEPS
} from "../../../../constants/mixpanel-constant/inventoryOnboardingV2";
import usePrintService from "../../../../hooks/use-print-service";

const TAB_OPTIONS = {
  SINGLE: "single",
  BULK: "bulk"
};
const BATCH_SIZE = 50;

const AddInventoryPageFeatureV2 = ({ onClose }) => {
  const [itemTypeClient] = useState(useXemelgoClient().getItemTypeClient());
  const [locationClient] = useState(useXemelgoClient().getLocationClient());
  const [detectorClient] = useState(useXemelgoClient().getDetectorClient());
  const [inventoryClient] = useState(useXemelgoClient().getInventoryClient());

  const [itemTypes, setItemTypes] = useState([]);
  const [locations, setLocations] = useState([]);
  const [encodingStations, setEncodingStations] = useState([]);
  const [detectors, setDetectors] = useState([]);

  const [selectedOnboardingMode, setSelectedOnboardingMode] = useState("");
  const [selectedItemType, setSelectedItemType] = useState({});
  const [selectedLocation, setSelectedLocation] = useState({});
  const [selectedEncodingStation, setSelectedEncodingStation] = useState({});
  const [selectedItemQty, setSelectedItemQty] = useState(0);
  const [selectedItemTag, setSelectedItemTag] = useState("");
  const [itemProperties, setItemProperties] = useState({});
  const [itemTypeInput, setItemTypeInput] = useState("");

  const [loading, setLoading] = useState(true);
  const [itemTypeInputLoading, setItemTypeInputLoading] = useState(false);
  const [additionalInfoLoading, setAdditionalInfoLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("");
  const [headerMessage, setHeaderMessage] = useState("");
  const [error, setError] = useState(false);
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
  const [isQuantityDisabled, setIsQuantityDisabled] = useState(false);
  const { sendMixPanelEvent } = useMixpanelContext();
  const [currentTab, setCurrentTab] = useState(TAB_OPTIONS.SINGLE);

  const intervalId = useRef(null);
  const responseId = useRef(null);

  const tabs = [
    {
      id: "onboardMode",
      tabStructure: [
        {
          id: "add",
          display: "Add Existing Tag",
          action: () => {
            setSelectedOnboardingMode("add");
          }
        },
        {
          id: "print",
          display: "Print New Tag",
          action: () => {
            setSelectedOnboardingMode("print");
          }
        }
      ]
    }
  ];

  const {
    isLoading: isConfigLoading,
    onboardingOptions,
    defaultOnboardingLocation,
    enableOnboardingToLocation,
    hideSelectOnboardingLocationInput,
    targetLocationEnabled,
    onboardingToLocationRequired,
    possibleOnboardingLocationCategories,
    itemTypePropertiesToQuery,
    itemTypeInfo,
    additionalItemProperties,
    defaultOnboardingTab,
    isTagInputEditable,
    customTemplate,
    encodeTag,
    printOnly,
    getTagsApiUrl,
    selectItemTypeDisabled,
    singlePrint,
    customItemIdentifierTemplate,
    bartenderConfig,
    isBulkCreateEnabled,
    isPrintEnabled
  } = useAddInventoryFeatureV2ConfigContext();

  const { endpoint: bartenderUrl, propertiesToHeaderMap = {}, templateFilePath } = bartenderConfig;
  const { formData, setFormData } = useAddInventoryFeatureV2StateContext();

  const printType = bartenderUrl ? PRINT_TYPES.BARTENDER : PRINT_TYPES.ZPL;
  const { propertiesMap, label: itemTypeLabel, placeholder: itemTypePlaceholder, displayIdentifierOnly } = itemTypeInfo;

  const { generatePrintCommandBySolution } = useGeneratePrintTagCommands(SOLUTIONS.INVENTORY, printType);

  const printService = usePrintService(isPrintEnabled, printType, bartenderConfig);

  const {
    print,
    printers,
    selectedPrinterInfo,
    onSelectPrinter,
    isLoading: isPrintServiceLoading,
    printerErrorMessage
  } = printService;

  const onboardingModeTabs = [...tabs];
  const zplConfig = { customTemplate };

  onboardingModeTabs[0].tabStructure = tabs[0].tabStructure.filter((tab) => {
    return onboardingOptions.includes(tab.id) && tab;
  });

  const { generatePayload } = useInventoryFormDataParser();

  const tabList = useMemo(() => {
    const tempTabList = [
      {
        id: TAB_OPTIONS.SINGLE,
        label: "Create an Item"
      }
    ];
    if (isBulkCreateEnabled) {
      tempTabList.push({
        id: TAB_OPTIONS.BULK,
        label: "Bulk Create"
      });
    }
    return tempTabList;
  });

  const printerError = useMemo(() => {
    if (Object.keys(selectedPrinterInfo).length) {
      return !(selectedPrinterInfo?.message?.statusColor === "green" || bartenderUrl);
    }
    return false;
  }, [selectedPrinterInfo]);

  const searchForItemTypes = async (input) => {
    const newItemTypes = await itemTypeClient.getItemTypesFullTextContaining(input, itemTypePropertiesToQuery);
    let itemTypesToSet = newItemTypes.map((itemType) => {
      const { name, identifier } = itemType;
      let label = `${name}\r\n${identifier || ""}`;
      if (displayIdentifierOnly) {
        label = identifier;
      }
      return { ...itemType, label, name: name || identifier };
    });
    itemTypesToSet = naturalSort(itemTypesToSet, "label");
    return itemTypesToSet;
  };

  const handleDebounceCallback = async (debounceString) => {
    if (debounceString?.length >= 3) {
      setItemTypeInputLoading(true);
      const currentReponseId = uuidv4();
      responseId.current = currentReponseId;
      const newItemTypes = await searchForItemTypes(debounceString);

      // check for the latest response and ignore old one
      if (responseId.current === currentReponseId) {
        setItemTypes(newItemTypes);
        setItemTypeInputLoading(false);
      }
    } else {
      setItemTypes([]);
    }
  };

  const onLoad = async () => {
    setError(false);
    setHeaderMessage("");

    let newLocationsPromise;
    let newDetectorsPromise;
    const locationsToQuery = [...possibleOnboardingLocationCategories, "Encoding Station"];

    if (enableOnboardingToLocation) {
      newLocationsPromise = locationClient.getLocationsOfCategory(locationsToQuery);
    }

    if (onboardingOptions.includes("add")) {
      newDetectorsPromise = detectorClient.listDetectors();
    }

    try {
      const [newLocations = [], newDetectors = []] = await Promise.all([newLocationsPromise, newDetectorsPromise]);

      let locationsToSet = [];
      const newEncodingStations = [];

      newLocations.forEach((location) => {
        const { id, name: label, category, identifier } = location;
        if (category === "Encoding Station") {
          newEncodingStations.push({ id, label, identifier });
        } else {
          locationsToSet.push({ id, label, identifier });
        }
      });
      locationsToSet = naturalSort(locationsToSet, "label");

      newEncodingStations.forEach((station) => {
        newDetectors.forEach((detector) => {
          if (station.id === detector?.location?.id) {
            station.detectorSerial = detector.name;
          }
        });
      });

      let encodingStationsToSet = newEncodingStations.filter((station) => {
        return station.detectorSerial;
      });

      encodingStationsToSet = naturalSort(encodingStationsToSet, "label");

      const tempItemPropertyMap = additionalItemProperties.reduce((accumulator, property) => {
        accumulator[property.id] = {
          ...property
        };
        return accumulator;
      }, {});

      setItemProperties(tempItemPropertyMap);
      setLocations(locationsToSet);
      setSelectedLocation(defaultOnboardingLocation);
      setEncodingStations(encodingStationsToSet);
      setDetectors(newDetectors);
      setSelectedOnboardingMode(defaultOnboardingTab);
    } catch (e) {
      setError(true);
      setHeaderMessage(e);
    }
    setLoading(false);
  };

  const clearFormInputs = () => {
    const tempItemPropertyMap = additionalItemProperties.reduce((accumulator, property) => {
      accumulator[property.id] = {
        ...property
      };
      return accumulator;
    }, {});

    setSelectedItemType({});
    setSelectedLocation(defaultOnboardingLocation);
    setItemProperties(tempItemPropertyMap);
    setSelectedItemQty(0);
    setSelectedItemTag("");
  };

  const onSelectItemType = (itemType) => {
    if (!itemType || Object.keys(itemType).length === 0) {
      setSelectedItemType({});
      return;
    }

    Object.keys(propertiesMap).forEach((key) => {
      propertiesMap[key].value = itemType[key];

      if (key === "name") {
        propertiesMap[key].label = itemType[key];
      }
    });

    setSelectedItemType({ ...propertiesMap });
  };

  const onItemPropertyChange = (id, value) => {
    const tempItemProperties = { ...itemProperties };
    tempItemProperties[id].value = value;

    if (tempItemProperties?.item_identifier?.value) {
      setIsQuantityDisabled(true);
      setSelectedItemQty(1);
    } else {
      setIsQuantityDisabled(false);
    }
    setItemProperties(tempItemProperties);
  };

  const checkEncodingStations = async () => {
    if (!encodingStations.length) {
      setError(true);
      setHeaderMessage(`No Encoding Stations have been setup.`);
      setAdditionalInfoLoading(false);
      return;
    }

    if (encodingStations.length === 1) {
      setSelectedEncodingStation(encodingStations[0]);
    }
  };

  const queryForTags = async () => {
    setError(false);
    setHeaderMessage("");
    setSelectedItemTag("");
    setAdditionalInfoLoading(true);

    try {
      const response = await queryForLatestDetectionsByReader(getTagsApiUrl, selectedEncodingStation.detectorSerial);
      const message = await response.json();

      if (message.tags?.length > 1) {
        setError(true);
        setHeaderMessage(
          `Multiple tags detected.
          Ensure that there are no stray tags nearby.`
        );
        return;
      }
      setSelectedItemTag(message.tags[0].epc);
    } catch (e) {
      setError(true);
      setHeaderMessage(`Could not scan tags, ${e}`);
    } finally {
      setAdditionalInfoLoading(false);
    }
  };

  const generateTags = (quantity = 1) => {
    const tags = [];

    const timeStamp = getCurrentTimestamp();
    const date = getFormattedDate(timeStamp, "YYMMDD");

    const startingSerial = 1;
    for (let i = startingSerial; i <= quantity; i++) {
      const paddedString = i.toString().padStart(5, "0");
      tags.push(`${date}${timeStamp}${paddedString}`);
    }
    return tags;
  };

  const generateSGTIN96Tag = (UPC, serialToGenerate) => {
    // this is a header for a SGTIN96, Point of Sale tag with a partition of 5
    const prefix = "00110000001101";
    const paddedUPC = `0${UPC}`;
    const companyPrefix = decimalToBinary(paddedUPC.substring(0, 7)).padStart(24, "0");
    const itemNumber = decimalToBinary(paddedUPC.substring(7, paddedUPC.length - 1)).padStart(20, "0");
    const binarySerial = decimalToBinary(serialToGenerate).padStart(38, "0");
    return binaryToHex(`${prefix}${companyPrefix}${itemNumber}${binarySerial}`).toUpperCase();
  };

  const generateSGTINPayload = (itemType, quantity = 1) => {
    const payloads = [];

    const startingSerial = 1;
    for (let i = startingSerial; i <= quantity; i++) {
      const timeString = getFormattedDate(Date.now(), "MMDDHHmm");
      const paddedString = i.toString().padStart(10 - timeString.length, "0");
      // arbitrary 2 as it lets us get more bits
      const serial = `2${timeString}${paddedString}`;
      const tag = generateSGTIN96Tag(itemType.identifier, serial);
      const payload = {
        item_number: itemType.identifier,
        tracker_serial: tag,
        name: serial,
        item_identifier: serial
      };
      if (targetLocationEnabled && Object.keys(selectedLocation).length) {
        payload.initial_location = selectedLocation.identifier;
      }
      payloads.push(payload);
    }

    return payloads;
  };

  const onSubmit = async () => {
    setHeaderMessage("");
    setError(false);
    setLoading(true);
    const itemNumber = selectItemTypeDisabled ? itemTypeInput : selectedItemType.identifier.value;
    const onboardingLocation = Object.keys(selectedLocation).length ? selectedLocation.id : null;

    try {
      let responseMessage;
      if (selectedOnboardingMode === "print") {
        responseMessage = await submitPrintTags(itemNumber, onboardingLocation);
      } else if (selectedOnboardingMode === "add") {
        responseMessage = await submitAddTags(itemNumber, onboardingLocation);
      }

      setHeaderMessage(responseMessage);
    } catch (e) {
      setError(true);
      setHeaderMessage(e);
      sendMixPanelEvent(INVENTORY_ONBOARDING_V2, INVENTORY_ONBOARDING_V2_STEPS.ITEM_ONBOARD_FAILED, {
        error_message: e.message
      });
    }
    clearFormInputs();
    setLoading(false);
  };

  const submitPrintTags = async (itemNumber, onboardingLocation) => {
    const quantityToSubmit = singlePrint ? 1 : selectedItemQty;
    try {
      let payload = [];
      let generatedVids = [];
      const itemType = itemTypes.find((type) => {
        return type.identifier === selectedItemType.identifier.value;
      });
      if (encodeTag) {
        payload = generateSGTINPayload(itemType, quantityToSubmit);
      } else {
        generatedVids = generateTags(quantityToSubmit);
        generatedVids.forEach((tag) => {
          const itemPayload = {
            item_number: itemNumber,
            tracker_serial: tag,
            name: tag
          };

          payload.push(itemPayload);
        });
      }
      payload = payload.map((itemPayload) => {
        if (targetLocationEnabled && Object.keys(selectedLocation).length) {
          itemPayload.initial_location = selectedLocation.identifier;
        }

        Object.keys(itemProperties).forEach((id) => {
          if (itemProperties[id].value) {
            switch (itemProperties[id].type) {
              case "date":
              case "datepicker":
                itemPayload[id] = itemProperties[id].value.getTime();
                break;
              default:
                itemPayload[id] = itemProperties[id].numberOnly
                  ? parseFloat(itemProperties[id].value)
                  : itemProperties[id].value;
            }
          }
        });
        if (customItemIdentifierTemplate) {
          const generatedIdentifier = generateCustomIdentifier(itemPayload, customItemIdentifierTemplate);
          itemPayload.item_identifier = generatedIdentifier;
        }
        return itemPayload;
      });

      setLoadingMessage("Generating tags...");
      if (!printOnly) {
        await inventoryClient.createItemSet(payload, onboardingLocation);
        sendMixPanelEvent(INVENTORY_ONBOARDING_V2, INVENTORY_ONBOARDING_V2_STEPS.ITEM_ONBOARD_SUCCESS, {
          item_onboarded_count: payload.length
        });
      }

      // skip printing
      if (!isPrintEnabled) {
        return;
      }

      if (bartenderUrl) {
        const bartenderTagPayload = payload.map((item) => {
          return {
            ...item,
            ...itemType,
            item_type_name: itemType.name
          };
        });
        await bartenderPrintTags(bartenderTagPayload);
      } else {
        let tagPayloads = generatedVids;
        if (customTemplate) {
          tagPayloads = payload.map((item) => {
            return selectItemTypeDisabled
              ? item
              : {
                  ...itemType,
                  ...item,
                  name: itemType.name,
                  onboarding_location: selectedLocation.label
                };
          });
        }
        await printTags(tagPayloads);
      }

      return "Printed tags successfully!";
    } catch (e) {
      throw "Could not print tags";
    }
  };

  const submitAddTags = async (itemNumber, onboardingLocation) => {
    try {
      const payload = [
        {
          item_number: itemNumber,
          tracker_serial: selectedItemTag,
          name: selectedItemTag
        }
      ];
      setLoadingMessage("Adding tag...");
      await inventoryClient.createItemSet(payload, onboardingLocation);
      sendMixPanelEvent(INVENTORY_ONBOARDING_V2, INVENTORY_ONBOARDING_V2_STEPS.ITEM_ONBOARD_SUCCESS, {
        item_onboarded: payload.length
      });
      return "Added tag successfully!";
    } catch (e) {
      throw "Could not add tag";
    }
  };

  const printTags = async (tagsToPrint) => {
    let errorMessage;

    for (const [idx, tag] of tagsToPrint.entries()) {
      setLoadingMessage(`Printing tag ${idx + 1} of ${tagsToPrint.length}...`);
      try {
        const printPayload = await generatePrintCommandBySolution(tag, bartenderUrl ? bartenderConfig : zplConfig);
        await print(printPayload);
      } catch (e) {
        errorMessage = e;
        break;
      }
    }

    if (errorMessage) {
      throw errorMessage;
    }
  };

  const bartenderPrintTags = async (itemsToPrint) => {
    let errorMessage;

    for (let i = 0; i < itemsToPrint.length; i++) {
      const currentItem = itemsToPrint[i];
      const bartenderPayload = {};
      setLoadingMessage(`Printing tag ${i + 1} of ${itemsToPrint.length}...`);
      Object.keys(propertiesToHeaderMap).forEach((property) => {
        switch (property) {
          case "format_name":
            bartenderPayload[propertiesToHeaderMap[property]] = templateFilePath;
            break;
          case "printer_name":
            bartenderPayload[propertiesToHeaderMap[property]] = selectedPrinterInfo.name.label;
            break;
          case "quantity":
            bartenderPayload[propertiesToHeaderMap[property]] = 1;
            break;
          default:
            bartenderPayload[propertiesToHeaderMap[property]] = currentItem[property] || null;
            break;
        }
      });
      try {
        await print(bartenderPayload);
      } catch (e) {
        errorMessage = e;
        break;
      }
    }

    if (errorMessage) {
      throw errorMessage;
    }
  };

  const onSubmitV2 = async () => {
    setError(false);
    setHeaderMessage("");
    setLoadingMessage(
      printOnly ? "Printing tags..." : isPrintEnabled ? "Adding and printing tags..." : "Adding tags..."
    );
    setLoading(true);

    const itemTypeMap = {};
    const promises = [];

    try {
      // ------------------ CREATE ------------------ //
      const payloadsByLocations = await generatePayload(formData);

      const allPayloads = payloadsByLocations.reduce((newPayloads, payloadsByLocation) => {
        const { payloads } = payloadsByLocation;
        newPayloads.push(...payloads);
        return newPayloads;
      }, []);

      // create and onboard
      if (!printOnly) {
        if (enableOnboardingToLocation) {
          payloadsByLocations.forEach((payloadsByLocation) => {
            const { payloads, locationId } = payloadsByLocation;

            while (payloads.length) {
              const currentSlice = payloads.splice(0, BATCH_SIZE);
              promises.push(inventoryClient.createItemSet(currentSlice, locationId));
            }
          });
          await Promise.all(promises);
        } else {
          const clonedAllPayloads = _.cloneDeep(allPayloads);

          // create without onboard
          while (clonedAllPayloads.length) {
            const currentSlice = clonedAllPayloads.splice(0, BATCH_SIZE);
            promises.push(inventoryClient.createItemSet(currentSlice));
          }
        }
      }

      // ------------------ PRINT ------------------ //
      if (!isPrintEnabled) {
        return;
      }

      // get item type numbers and query for additional item type attributes
      const itemTypeNumbers = allPayloads.map((payload) => {
        const { item_number: itemNumber } = payload;
        return itemNumber;
      });

      if (itemTypeNumbers.length) {
        const itemTypesInfo = await itemTypeClient.getItemTypeByIdentifiers(itemTypeNumbers, itemTypePropertiesToQuery);
        itemTypesInfo.forEach((itemType) => {
          const { identifier } = itemType;
          itemTypeMap[identifier] = itemType;
        });
      }

      // generate print payload and print
      for (const payload of allPayloads) {
        const { item_number: itemNumber } = payload;
        const newPayload = { ...payload, ...itemTypeMap[itemNumber] };
        const printPayload = await generatePrintCommandBySolution(
          newPayload,
          bartenderUrl ? bartenderConfig : zplConfig
        );
        await print(printPayload);
      }
    } catch (e) {
      setError(true);
      setHeaderMessage("Could not print tags");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    sendMixPanelEvent(INVENTORY_ONBOARDING_V2, INVENTORY_ONBOARDING_V2_STEPS.ENTRY);
  }, []);

  useEffect(() => {
    if (isConfigLoading) {
      return;
    }

    setLoading(true);
    onLoad();
    return () => {
      if (intervalId.current) {
        clearInterval(intervalId.current);
        intervalId.current = null;
      }

      clearFormInputs();
    };
  }, [isConfigLoading]);

  useEffect(() => {
    if (loading) {
      return;
    }

    setError(!!printerErrorMessage);
    setHeaderMessage(printerErrorMessage);

    if (selectedOnboardingMode === "add") {
      checkEncodingStations();
    }
  }, [loading, selectedOnboardingMode, printerErrorMessage]);

  useEffect(() => {
    const isPrinterReady = selectedPrinterInfo?.message?.value === "Online" || !!bartenderUrl;

    if (selectedOnboardingMode === "print") {
      if (currentTab === TAB_OPTIONS.BULK) {
        setSubmitButtonDisabled(loading || !formData.length || (isPrintEnabled && !isPrinterReady));
      } else {
        setSubmitButtonDisabled(
          loading ||
            additionalInfoLoading ||
            (!singlePrint && !selectedItemQty) ||
            (selectItemTypeDisabled ? !itemTypeInput : !Object.keys(selectedItemType).length) ||
            (isPrintEnabled && !isPrinterReady) ||
            (onboardingToLocationRequired && !Object.keys(selectedLocation).length)
        );
      }
    } else if (selectedOnboardingMode === "add") {
      setSubmitButtonDisabled(
        !selectedItemTag.length || !Object.keys(selectedItemType).length || !Object.keys(selectedEncodingStation).length
      );
    }
  }, [
    singlePrint,
    selectItemTypeDisabled,
    itemTypeInput,
    selectedOnboardingMode,
    selectedItemQty,
    selectedItemTag,
    selectedItemType,
    selectedPrinterInfo,
    selectedLocation,
    onboardingToLocationRequired,
    additionalInfoLoading,
    loading,
    isBulkCreateEnabled,
    formData,
    currentTab
  ]);
  return (
    <ModalForm
      scrollable
      show
      title={
        <InventoryModalHeader
          onClose={onClose}
          error={error}
          headerMessage={headerMessage}
        />
      }
      body={
        loading ? (
          <LoadingCircle
            message={loadingMessage}
            messageStyle="loading_message"
          />
        ) : (
          <div className="modal_body">
            {isBulkCreateEnabled && (
              <TabBar
                tabList={tabList}
                currentTabId={currentTab}
                onTabClick={(newTab) => {
                  setCurrentTab(newTab);
                  clearFormInputs();
                  setFormData([]);
                }}
              />
            )}

            {currentTab === TAB_OPTIONS.SINGLE ? (
              <div className="single_create_tab">
                <ItemTypeInformation
                  label={itemTypeLabel}
                  placeholder={itemTypePlaceholder}
                  selectItemTypeDisabled={selectItemTypeDisabled}
                  itemTypeInput={itemTypeInput}
                  setItemTypeInput={setItemTypeInput}
                  itemTypes={itemTypes}
                  onSelect={onSelectItemType}
                  onDebounce={handleDebounceCallback}
                  itemTypeInputLoading={itemTypeInputLoading}
                  selectedItemType={selectedItemType}
                />

                <AdditionalInformation
                  isPrintEnabled={isPrintEnabled}
                  singlePrint={singlePrint}
                  onboardingToLocationRequired={onboardingToLocationRequired}
                  enableOnboardingToLocation={enableOnboardingToLocation}
                  hideSelectOnboardingLocationInput={hideSelectOnboardingLocationInput}
                  locations={locations}
                  selectedLocation={selectedLocation}
                  onSelectLocation={setSelectedLocation}
                  onboardingModeTabs={onboardingModeTabs}
                  selectedOnboardingMode={selectedOnboardingMode}
                  quantity={selectedItemQty}
                  onChangeQuantity={setSelectedItemQty}
                  isQuantityDisabled={isQuantityDisabled}
                  onItemPropertyChange={onItemPropertyChange}
                  itemProperties={itemProperties}
                  stations={encodingStations}
                  selectedStation={selectedEncodingStation}
                  onSelectStation={setSelectedEncodingStation}
                  tag={selectedItemTag}
                  onChangeTag={setSelectedItemTag}
                  onScanTag={queryForTags}
                  isTagInputEditable={isTagInputEditable}
                  printers={printers}
                  selectedPrinter={selectedPrinterInfo || {}}
                  onSelectPrinter={onSelectPrinter}
                  contentLoading={isPrintServiceLoading}
                  printerError={printerError}
                />
              </div>
            ) : (
              <BulkCreateTabFeature printService={printService} />
            )}
          </div>
        )
      }
      footer={
        <InventoryModalFooter
          onClose={onClose}
          onSubmit={currentTab === TAB_OPTIONS.BULK ? onSubmitV2 : onSubmit}
          disabled={submitButtonDisabled}
          submitLabel={_.capitalize(selectedOnboardingMode)}
        />
      }
      prefix="add-inv"
      className="inv_modal_container"
    />
  );
};

export default (props) => {
  return (
    <AddInventoryFeatureV2ConfigContextProvider>
      <AddInventoryFeatureV2StateContextProvider>
        <AddInventoryPageFeatureV2 {...props} />
      </AddInventoryFeatureV2StateContextProvider>
    </AddInventoryFeatureV2ConfigContextProvider>
  );
};
