import React, { useEffect } from "react";
import _ from "lodash";
import { useQueryClient } from "@tanstack/react-query";
import Style from "./CustomizableDashboardFeature.module.css";
import useCustomizableDashboardDataSourceContext, {
  CustomizableDashboardDataSourceContextProvider
} from "./contexts/customizable-dashboard-data-source-context";
import { CustomizableDashboardConfigContextProvider } from "./contexts/customizable-dashboard-config-context";
import useCustomizableDashboardStateContext, {
  CustomizableDashboardStateContextProvider
} from "./contexts/customizable-dashboard-state-context";
import EditingBanner from "./features/editing-banner";
import ViewTabs from "./features/view-tabs";
import DashboardGrid from "./features/dashboard-grid";
import AddNewWidgetModal from "./features/add-new-widget-modal";
import LoadingCircle from "../../components/loading/LoadingCircle";
import queryFns from "./hooks/use-widget-helper/queryFns";
import useMixpanelContext from "../../context/mixpanel-context";
import {
  CUSTOMIZABLE_DASHBOARD_EVENT,
  CUSTOMIZABLE_DASHBOARD_STEPS
} from "../../constants/mixpanel-constant/customizableDashboard";

const CustomizableDashboardFeature = () => {
  const { sendMixPanelEvent } = useMixpanelContext();
  const queryClient = useQueryClient();

  const { isLoading: isDataSourceLoading } = useCustomizableDashboardDataSourceContext();

  const { isEditMode, setIsEditMode, setEditingLayouts, editingLayouts, setCurrentViewTabIndex } =
    useCustomizableDashboardStateContext();

  const fetchAllWidgetsData = async () => {
    let hasError = false;
    const promiseList = Object.keys(queryFns).map(async (eachQueryKey) => {
      const queryFn = queryFns[eachQueryKey];
      try {
        return await queryClient.fetchQuery({
          queryKey: ["widgets", eachQueryKey],
          queryFn,
          staleTime: 20 * 1000
        });
      } catch (err) {
        sendMixPanelEvent(CUSTOMIZABLE_DASHBOARD_EVENT, CUSTOMIZABLE_DASHBOARD_STEPS.DATA_FETCHING_FAILURE, {
          errorMessage: err.message
        });
        hasError = true;
      }
    });

    await Promise.all(promiseList);
    if (!hasError) {
      sendMixPanelEvent(CUSTOMIZABLE_DASHBOARD_EVENT, CUSTOMIZABLE_DASHBOARD_STEPS.DATA_PRE_FETCH_SUCCESS);
    }
  };

  useEffect(() => {
    sendMixPanelEvent(CUSTOMIZABLE_DASHBOARD_EVENT, CUSTOMIZABLE_DASHBOARD_STEPS.ENTRY);
    window.fcWidget.hide();
    fetchAllWidgetsData();
    return () => {
      window.fcWidget.show();
    };
  }, []);

  if (isDataSourceLoading) {
    return <LoadingCircle />;
  }

  return (
    <>
      <div className={Style.container}>
        {isEditMode ? (
          <EditingBanner />
        ) : (
          <div className={Style.layout_container}>
            <ViewTabs />
            <div className={Style.layout_buttons_container}>
              <button
                type="button"
                className={Style.action_button}
                onClick={() => {
                  setIsEditMode(true);
                }}
              >
                Edit View
              </button>
              <button
                type="button"
                className={Style.action_button}
                onClick={() => {
                  const newEditingLayout = _.cloneDeep(editingLayouts);
                  newEditingLayout.push({
                    id: `view-${newEditingLayout.length + 1}`,
                    name: `View ${newEditingLayout.length + 1}`,
                    layout: []
                  });

                  setIsEditMode(true);
                  setEditingLayouts(newEditingLayout);
                  setCurrentViewTabIndex(newEditingLayout.length - 1 || 0);
                }}
              >
                + New View
              </button>
            </div>
          </div>
        )}
        <DashboardGrid />
      </div>
      <AddNewWidgetModal />
    </>
  );
};

export default () => {
  return (
    <CustomizableDashboardConfigContextProvider>
      <CustomizableDashboardDataSourceContextProvider>
        <CustomizableDashboardStateContextProvider>
          <CustomizableDashboardFeature />
        </CustomizableDashboardStateContextProvider>
      </CustomizableDashboardDataSourceContextProvider>
    </CustomizableDashboardConfigContextProvider>
  );
};
