import { useCallback, useState } from "react";
import { AddPageInputTypeMap, convertAsciiToHex } from "../../../../common/Utilities";
import { useXemelgoClient } from "../../../../services/xemelgo-service";

const ONBOARDING_LOCATION_KEY = "onboardingLocation";
const WO_IDENTIFIER_KEY = "order_number";

export const useUploadWorkOrderCSVDataParser = (defaultAttributeMap, customAttributeMap) => {
  const [locationClient] = useState(useXemelgoClient().getLocationClient());

  const getValue = (propValue, attributeMap) => {
    const { type, transformInput, convertToHex, numberOnly } = attributeMap;

    let finalValue = propValue;
    switch (type) {
      case AddPageInputTypeMap.DATE_PICKER:
        finalValue = propValue ? Date.parse(propValue) : null;
        break;

      case AddPageInputTypeMap.INPUT:
        if (transformInput) {
          if (transformInput === "toUpperCase") {
            finalValue = propValue.toUpperCase();
            break;
          }
        }
      // eslint-disable-next-line
      case AddPageInputTypeMap.SEARCH_DROP_DOWN:
      case AddPageInputTypeMap.SEARCH_DROP_DOWN_FROM_API:
      case AddPageInputTypeMap.CHECK_BOX_GROUP:
      default:
        if (numberOnly) {
          finalValue = propValue ? parseFloat(propValue) : 0;
        } else {
          finalValue = propValue || null;
        }
        break;
    }

    if (convertToHex && finalValue) {
      finalValue = convertAsciiToHex(finalValue);
    }

    return finalValue;
  };

  const convertLocationIdentifierMapToLocationIdMap = async (locationIdentifierMap) => {
    if (!Object.keys(locationIdentifierMap).length) {
      return {};
    }
    const locationList = await locationClient.getLocationsByIdentifiers(Object.keys(locationIdentifierMap));
    return locationList.reduce((accumulator, { id, identifier }) => {
      return { ...accumulator, [id]: locationIdentifierMap[identifier] };
    }, {});
  };
  const parse = useCallback(
    async (csvWorkOrderList) => {
      const locationIdentifierToWorkOrderMap = {};

      const workOrdersPayload = csvWorkOrderList.map((eachCSVWorkOrder) => {
        const processedCSVValue = {};
        Object.keys(defaultAttributeMap).forEach((eachDefaultAttributeKey) => {
          if (eachDefaultAttributeKey === ONBOARDING_LOCATION_KEY) {
            if (eachCSVWorkOrder[ONBOARDING_LOCATION_KEY]) {
              locationIdentifierToWorkOrderMap[eachCSVWorkOrder[ONBOARDING_LOCATION_KEY]] = [
                ...(locationIdentifierToWorkOrderMap[eachCSVWorkOrder[ONBOARDING_LOCATION_KEY]] || []),
                eachCSVWorkOrder[WO_IDENTIFIER_KEY]
              ];
            }
          } else {
            processedCSVValue[eachDefaultAttributeKey] = getValue(
              eachCSVWorkOrder[eachDefaultAttributeKey],
              defaultAttributeMap[eachDefaultAttributeKey]
            );
          }
        });

        Object.keys(customAttributeMap).forEach((eachCustomAttributeKey) => {
          processedCSVValue.custom_properties = {
            ...processedCSVValue.custom_properties,
            [eachCustomAttributeKey]: getValue(
              eachCSVWorkOrder[eachCustomAttributeKey],
              customAttributeMap[eachCustomAttributeKey]
            )
          };
        });
        return processedCSVValue;
      });

      const onboardingLocationToWorkOrderMap = await convertLocationIdentifierMapToLocationIdMap(
        locationIdentifierToWorkOrderMap
      );
      return { workOrdersPayload, onboardingLocationToWorkOrderMap };
    },
    [defaultAttributeMap, customAttributeMap]
  );

  return { parse };
};
