import { OPTION_FROM_CONFIG } from "../constants/bulkUpdateItemStateConstant";
import { XemelgoService } from "../services/XemelgoService";
import { genericSort } from "./Utilities";

const ONE_DAY_IN_MILLISECOND = 1000 * 60 * 60 * 24;

export const BulkUpdateFeatureFunctionMap = {
  locations: (args) => {
    return getLocationIdentifierData(args);
  },
  "item-identifier": (args) => {
    return getItemIdentifierData(args);
  },
  "task-identifier": (args) => {
    return getTaskIdentifierData(args);
  },
  "item-type-id": (args) => {
    return getItemTypeIdData(args);
  }
};

/* TODO: abstract out this file to be more genric so the features send args for
 * API call as well as to specify how the result from API needs to be
 * processed and returned
 */
export const AddAssetFeatureFunctionMap = {
  onboardingLocation: (argsForAPI) => {
    return getOnboardingLocationOptions(argsForAPI);
  },
  itemType: (argsForAPI) => {
    return getAssetTypeOptions(argsForAPI);
  }
};

export const AddOrderFeatureFunctionMap = {
  onboardingLocation: (argsForAPI) => {
    return getOnboardingLocationOptions(argsForAPI);
  }
};

export const AddInventoryFeatureFunctionMap = {
  onboardingLocation: (argsForAPI) => {
    return getOnboardingLocationOptions(argsForAPI);
  },
  cabinet: (argsForAPI) => {
    return getTargetLocationOptions(argsForAPI);
  },
  item_number: (argsForAPI) => {
    return getInventoryItemTypeOptions(argsForAPI);
  },
  customer_part_number: (argsForAPI) => {
    return getInventoryCustomerPartNumberOptions(argsForAPI);
  }
};

export const AddItemTypeFeatureFunctionMap = {
  category: (argsForAPI) => {
    return getCategories(argsForAPI);
  }
};

export async function processOnboardingPageAttributes(defaultAttributeMap, customAttributeMap, featureFunctionMap) {
  if (defaultAttributeMap) {
    for (const attributeId of Object.keys(defaultAttributeMap)) {
      if (defaultAttributeMap[attributeId].type === "dropdownWithOptionsFromAPI") {
        const getOptionsFn = featureFunctionMap[attributeId];
        defaultAttributeMap[attributeId].options = getOptionsFn
          ? await getOptionsFn(defaultAttributeMap[attributeId].argsForAPI)
          : [];
      }
    }
  }

  if (customAttributeMap) {
    for (const attributeId of Object.keys(customAttributeMap)) {
      if (customAttributeMap[attributeId].type === "dropdownWithOptionsFromAPI") {
        const getOptionsFn = featureFunctionMap[attributeId];
        customAttributeMap[attributeId].options = getOptionsFn
          ? await getOptionsFn(customAttributeMap[attributeId].argsForAPI)
          : [];
      }
    }
  }
  return { defaultAttributeMap, customAttributeMap };
}

async function processAttributeMap(attributeMap, featureFunctionMap, additionalConfig, filterOnly) {
  if (filterOnly) {
    const filteredAttributeMap = {};
    Object.keys(attributeMap)
      .filter((attribute) => {
        return attributeMap[attribute].editable && attributeMap[attribute].editable.bulk;
      })
      .forEach((id) => {
        filteredAttributeMap[id] = attributeMap[id];
      });

    return { ...filteredAttributeMap };
  }

  for (const attributeId of Object.keys(attributeMap)) {
    const { validateCSVInputWithOptions } = attributeMap[attributeId];
    if (validateCSVInputWithOptions && validateCSVInputWithOptions !== OPTION_FROM_CONFIG) {
      const getOptionsFn = featureFunctionMap[validateCSVInputWithOptions];
      const additionalConfigCopy = { ...additionalConfig };
      const { inputValues } = attributeMap[attributeId];
      if (inputValues) {
        additionalConfigCopy.inputValues = inputValues;
      }
      const result = getOptionsFn ? await getOptionsFn(additionalConfigCopy) : {};
      attributeMap[attributeId].options = result || {};
    }
  }
  return { ...attributeMap };
}

export async function processBulkUpdateAttributes(
  defaultAttributeMap,
  customAttributeMap,
  featureFunctionMap,
  additionalConfig,
  filterOnly
) {
  let filteredDefaultAttributeMap;
  let filteredCustomAttributeMap = {};
  if (defaultAttributeMap) {
    filteredDefaultAttributeMap = await processAttributeMap(
      defaultAttributeMap,
      featureFunctionMap,
      additionalConfig,
      filterOnly
    );
  }

  if (customAttributeMap) {
    filteredCustomAttributeMap = await processAttributeMap(
      customAttributeMap,
      featureFunctionMap,
      additionalConfig,
      filterOnly
    );
  }

  return {
    defaultAttributeMap: filteredDefaultAttributeMap,
    customAttributeMap: filteredCustomAttributeMap
  };
}

const getInventoryItemTypeOptions = async (argsForAPI) => {
  const itemTypeClient = XemelgoService.getClient().getItemTypeClient();
  const data = await itemTypeClient.listItemTypes(["id", "identifier"], "Inventory");
  let sortedItemTypeList = [];
  const itemTypes = data.map((item) => {
    return {
      id: item.id,
      value: item.identifier,
      label: item.identifier
    };
  });

  if (itemTypes && itemTypes.length) {
    sortedItemTypeList = genericSort(itemTypes, "label", "ascending");
  }

  return sortedItemTypeList;
};

const getAssetTypeOptions = async (argsForAPI) => {
  const itemTypeClient = XemelgoService.getClient().getItemTypeClient();
  const fields = (argsForAPI && argsForAPI.fields) || [];
  const data = await itemTypeClient.listAssetTypes(fields);
  let sortedAssetTypeList = [];
  const assetTypes = data.map((item) => {
    const {
      id,
      identifier,
      expected_lifespan: expectedLifespan,
      schedule_refresh_interval: scheduleRefreshInterval,
      usage_limit: usageLimit,
      description,
      customFields
    } = item;
    return {
      id,
      value: identifier,
      label: identifier,
      itemTypeIdentifier: identifier,
      item_number: identifier,
      expected_lifespan: expectedLifespan ? expectedLifespan / ONE_DAY_IN_MILLISECOND : null,
      schedule_refresh_interval: scheduleRefreshInterval ? scheduleRefreshInterval / ONE_DAY_IN_MILLISECOND : null,
      usage_limit: usageLimit || null,
      description,
      ...customFields
    };
  });

  if (assetTypes && assetTypes.length) {
    sortedAssetTypeList = genericSort(assetTypes, "label", "ascending");
  }

  return sortedAssetTypeList;
};

const getOnboardingLocationOptions = async (locationCategory) => {
  const locationClient = XemelgoService.getClient().getLocationClient();
  const locationList = await locationClient.getLocationsOfCategory(locationCategory);
  let sortedLocationOptions = [];
  if (locationList.length) {
    const locationOptions = [];
    locationList.forEach((location) => {
      if (location.identifier || location.name) {
        locationOptions.push({
          id: location.id,
          label: `Name: ${location.name || "-"} ${location.identifier ? `(Identifier: ${location.identifier})` : ""}`,
          value: location.id,
          valueToValidateWith: `${location.name || ""}-${location.identifier || ""}`,
          identifier: location.identifier
        });
      }
    });
    if (locationOptions.length) {
      sortedLocationOptions = genericSort(locationOptions, "label", "ascending");
    }
  }

  return sortedLocationOptions;
};

const getTargetLocationOptions = async (locationCategory) => {
  const locationClient = XemelgoService.getClient().getLocationClient();
  const locationList = await locationClient.getLocationsOfCategory(locationCategory);
  let sortedLocationOptions = [];
  if (locationList.length) {
    const locationOptions = [];
    locationList.forEach((location) => {
      if (location.identifier) {
        locationOptions.push({
          id: location.id,
          label: `Name: ${location.name} (Identifier: ${location.identifier})`,
          value: location.identifier,
          valueToValidateWith: location.identifier
        });
      }
    });
    if (locationOptions.length) {
      sortedLocationOptions = genericSort(locationOptions, "label", "ascending");
    }
  }

  return sortedLocationOptions;
};

const getLocationIdentifierData = async (additionalConfig) => {
  const { possibleDetectorLocationCategories: locationCategories = [], inputValues = [] } = additionalConfig;
  const locationIdentifierToLocationMap = {};

  try {
    // Get locations to find out id from identifier in CSV
    const locationClient = XemelgoService.getClient().getLocationClient();

    const allLocations = await locationClient.getLocationsOfCategory(locationCategories, false, inputValues);
    allLocations &&
      allLocations.length &&
      allLocations.forEach((location) => {
        if (location.identifier) {
          const locationIdentifier = location.identifier.trim().toUpperCase();
          locationIdentifierToLocationMap[locationIdentifier] = location;
        }
      });
    return locationIdentifierToLocationMap;
  } catch (e) {
    return `Error fetching data to validate - ${e}`;
  }
};

const getItemIdentifierData = async (additionalConfig) => {
  const { itemClass: itemClassFilter = ["Asset", "Inventory"], inputValues = [] } = additionalConfig;
  const itemIdentifierToItemMap = {};

  try {
    const itemClient = XemelgoService.getClient().getItemClient();
    const items = await itemClient.getItemDataForBulkUpdate(itemClassFilter, inputValues);
    items &&
      items.length > 0 &&
      items.forEach((item, i) => {
        if (item && item.identifier) {
          const identifier = item.identifier.trim().toUpperCase();
          itemIdentifierToItemMap[identifier] = item;
        }
      });
    return itemIdentifierToItemMap;
  } catch (e) {
    return `Error fetching data to validate - ${e}`;
  }
};

const getTaskIdentifierData = async (additionalConfig) => {
  const { inputValues = [] } = additionalConfig;
  try {
    const itemClient = XemelgoService.getClient().getItemClient();
    const taskIdentifierToObjectMap = await itemClient.getTaskDataForBulkUpdate(inputValues);
    return taskIdentifierToObjectMap;
  } catch (e) {
    return `Error fetching data to validate - ${e}`;
  }
};

const getItemTypeIdData = async (additionalConfig) => {
  const { inputValues = [] } = additionalConfig;
  try {
    const itemTypeClient = XemelgoService.getClient().getItemTypeClient();
    const itemTypes = await itemTypeClient.getItemTypeByIdentifiers(inputValues);
    const itemTypeIdentifierToIdMap = {};
    itemTypes.forEach((eachItemType) => {
      const { id, identifier } = eachItemType;
      itemTypeIdentifierToIdMap[identifier.trim().toUpperCase()] = id;
    });
    return itemTypeIdentifierToIdMap;
  } catch (e) {
    return `Error fetching data to validate - ${e}`;
  }
};

const getCategories = async () => {
  try {
    const categoryClient = XemelgoService.getClient().getCategoryClient();
    const categories = await categoryClient.listCategories();
    return categories.map((category) => {
      return {
        id: category.id,
        label: category.identifier,
        value: category.id
      };
    });
  } catch (e) {
    return `Error fetching data to validate - ${e}`;
  }
};

const getInventoryCustomerPartNumberOptions = async () => {
  const itemTypeClient = XemelgoService.getClient().getItemTypeClient();
  const data = await itemTypeClient.listVendorClientInfos(["id", "identifier"], "Customer Part Number");
  // Remove duplicate identifiers
  const customerPartNumbersMap = data.reduce((accum, vci) => {
    accum[vci.identifier] = {
      id: vci.id,
      value: vci.identifier,
      label: vci.identifier
    };
    return accum;
  }, {});

  return genericSort(Object.values(customerPartNumbersMap), "label", "ascending");
};
