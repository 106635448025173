/**
 * This function validate CSV data and return a valid state, validated data and number of invalid items
 * @param data
 * @param csvAttributeMap
 * @param xemelgoClient
 * @returns
 */
export const validateCsvData = async (data, csvAttributeMap, xemelgoClient) => {
  const itemTypeClient = xemelgoClient.getItemTypeClient();
  const locationClient = xemelgoClient.getLocationClient();
  const sensorProfileClient = xemelgoClient.getSensorProfileClient();

  let valid = true;
  let numberOfInvalidItems = 0;
  const validatedData = [...data];
  const itemTypesToQuery = [];
  const locationsToQuery = [];
  const vidsToQuery = [];

  // get list of things to query
  validatedData.forEach((eachDataSet) => {
    const { item_number: itemNumber, location, tracker_serial: vid } = eachDataSet;
    if (itemNumber && csvAttributeMap.item_number?.type === "dropdownWithOptionsFromAPI") {
      itemTypesToQuery.push(itemNumber);
    }
    if (location && csvAttributeMap.location?.type === "dropdownWithOptionsFromAPI") {
      locationsToQuery.push(location);
    }

    if (vid) {
      vidsToQuery.push(vid.toUpperCase());
    }
  });

  // issue queries
  let itemTypesQueryPromise;
  let locationsQueryPromise;
  let vidsToQueryPromise;

  if (itemTypesToQuery.length) {
    itemTypesQueryPromise = itemTypeClient.getItemTypeByIdentifiers(itemTypesToQuery);
  }
  if (locationsToQuery.length) {
    const categories = csvAttributeMap?.location?.argsForAPI?.split(",");
    locationsQueryPromise = locationClient.getLocationsByIdentifiers(locationsToQuery, categories);
  }

  if (vidsToQuery.length) {
    vidsToQueryPromise = sensorProfileClient.activeSensorProfilesCheckByVid(vidsToQuery);
  }

  const [itemTypes = [], locations = [], vids = []] = await Promise.all([
    itemTypesQueryPromise,
    locationsQueryPromise,
    vidsToQueryPromise
  ]);

  const itemNumberMap = itemTypes.reduce((newItemNumberMap, itemType) => {
    const { identifier } = itemType;
    newItemNumberMap[identifier] = true;
    return newItemNumberMap;
  }, {});

  const locationMap = locations.reduce((newLocationMap, location) => {
    const { identifier } = location;
    newLocationMap[identifier] = true;
    return newLocationMap;
  }, {});

  const existingVidsMap = vids.reduce((newVidsMap, eachVid) => {
    const { vid } = eachVid;
    newVidsMap[vid] = true;
    return newVidsMap;
  }, {});

  // validate csv data
  validatedData.forEach((eachRow) => {
    let validRow = true;

    Object.keys(eachRow).forEach((id) => {
      const { isRequired, type } = csvAttributeMap[id];
      const value = eachRow[id];

      // if cell value exist
      if (value) {
        switch (type) {
          case "datepicker":
            const date = Date.parse(value);
            if (Number.isNaN(date)) {
              validRow = false;
            }
            break;
          case "number":
            if (Number.isNaN(value)) {
              validRow = false;
            }
            break;
          case "dropdownWithOptionsFromAPI":
            switch (id) {
              case "item_number":
                if (!itemNumberMap[value]) {
                  validRow = false;
                }
                break;
              case "location":
                if (!locationMap[value]) {
                  validRow = false;
                }
                break;
              default:
                break;
            }
            break;
          default:
            if (id === "tracker_serial") {
              if (existingVidsMap[value]) {
                validRow = false;
              }
              existingVidsMap[value] = true;
            }
            break;
        }
      } else if (isRequired) {
        validRow = false;
      }
    });

    if (!validRow) {
      valid = false;
      eachRow.error = true;
      numberOfInvalidItems++;
    }
  });
  return { valid, validatedData, numberOfInvalidItems };
};
