import React, { useState, useEffect } from "react";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import { useQuery } from "@tanstack/react-query";
import Popover from "@material-ui/core/Popover";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import xemelgoStyle from "../../../../../../styles/variable";
import Style from "./HeatMapMetrics.module.css";
import useWidgetSharableContext from "../../../../features/dashboard-grid/contexts/widget-sharable-context";
import LoadingCircle from "../../../../../../components/loading/LoadingCircle";
import queryFns from "../../queryFns";

const STATUS_COLORS = {
  healthy: xemelgoStyle.theme.STATUS_GREEN,
  critical: xemelgoStyle.theme.STATUS_RED
};

// until we have proper percentages
const MOCK_PERCENTAGE_DATA = [
  {
    percentageHealth: "critical",
    direction: "down",
    percentage: "10"
  },
  {
    percentageHealth: "healthy",
    direction: "down",
    percentage: "10"
  },
  {
    percentageHealth: "healthy",
    direction: "down",
    percentage: "5"
  },
  {
    percentageHealth: "critical",
    direction: "up",
    percentage: "1"
  },
  {
    percentageHealth: "healthy",
    direction: "down",
    percentage: "2"
  },
  {
    percentageHealth: "healthy",
    direction: "down",
    percentage: "2"
  },
  undefined,
  {
    percentageHealth: "healthy",
    direction: "down",
    percentage: "4"
  },
  {
    percentageHealth: "critical",
    direction: "up",
    percentage: "3"
  },
  {
    percentageHealth: "healthy",
    direction: "up",
    percentage: "5"
  },
  undefined,
  undefined,
  {
    percentageHealth: "healthy",
    direction: "down",
    percentage: "2"
  }
];

export const HeatMapMetrics = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [popupMessage, setPopupMessage] = useState("");
  const { selectedHeatMapTableId, setSelectedHeatMapTableId } = useWidgetSharableContext();

  const metricsQuery = useQuery({
    queryKey: ["widgets", "heat-map-metrics"],
    queryFn: queryFns["heat-map-metrics"],
    refetchOnWindowFocus: false
  });

  useEffect(() => {
    if (metricsQuery.data && metricsQuery.data.length > 0 && !selectedHeatMapTableId) {
      setSelectedHeatMapTableId(metricsQuery.data[0].id);
    }
  }, [metricsQuery.data, selectedHeatMapTableId]);

  const onMouseLeave = () => {
    setAnchorEl(null);
  };

  return (
    <div className={Style.container}>
      <div className={Style.title}>Metrics</div>
      {metricsQuery.isLoading ? (
        <LoadingCircle />
      ) : (
        <div className={Style.metrics_group_container}>
          {(metricsQuery.data || []).map((metric, index) => {
            const isSelected = selectedHeatMapTableId === metric.id;

            // temp until we have proper percentage
            const PERCENTAGE_OBJECT = MOCK_PERCENTAGE_DATA[index];

            return (
              <div
                key={metric.id}
                className={`${Style.metrics_container} ${isSelected && Style.metrics_container_active}`}
                onMouseEnter={(event) => {
                  setPopupMessage(metric.description);
                  setAnchorEl(event.currentTarget);
                }}
                onMouseLeave={onMouseLeave}
                onClick={() => {
                  setSelectedHeatMapTableId(isSelected ? "" : metric.id);
                }}
              >
                <div className={Style.metrics_label}>{metric.label}</div>
                <div className={Style.metrics_subTitle_container}>
                  <div
                    className={`${Style.metrics_subTitle} ${
                      !metric.percentChange && Style.metrics_sub_title_with_no_percentage
                    }`}
                    style={{ color: metric.subTitleColor }}
                  >
                    {metric.subTitle}
                  </div>
                  {PERCENTAGE_OBJECT ? (
                    <div
                      className={Style.metrics_percentage}
                      style={{
                        color: STATUS_COLORS[PERCENTAGE_OBJECT.percentageHealth],
                        borderColor: STATUS_COLORS[PERCENTAGE_OBJECT.percentageHealth]
                      }}
                    >
                      {!PERCENTAGE_OBJECT ? null : PERCENTAGE_OBJECT.direction === "up" ? (
                        <ArrowUpwardIcon fontSize="small" />
                      ) : (
                        <ArrowDownwardIcon fontSize="small" />
                      )}
                      {PERCENTAGE_OBJECT.percentage ? `${PERCENTAGE_OBJECT.percentage} %` : "-"}
                    </div>
                  ) : (
                    <div />
                  )}
                </div>
              </div>
            );
          })}
          <Popover
            id="mouse-over-popover"
            style={{ pointerEvents: "none" }}
            open={!!anchorEl}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left"
            }}
            onClose={onMouseLeave}
            disableRestoreFocus
          >
            <div className={Style.popover_content_container}>
              <InfoOutlinedIcon className={Style.popover_content_icon} />
              <div className={Style.popover_content_text}>{popupMessage}</div>
            </div>
          </Popover>
        </div>
      )}
    </div>
  );
};
