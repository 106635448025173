import { createChatBotMessage } from "react-chatbot-kit";
import React from "react";
import chatbotLogo from "./assets/images/vallen-favicon.png";
import { ReactComponent as PopoutIcon } from "./assets/icons/popout.svg";
import InitialMessageComponent from "./components/initial-message-component/InitialMessageComponent";
import ResultTableComponent from "./components/result-table-component/ResultTableComponent";
import SummaryCardComponent from "./components/summary-card-component/SummaryCardComponent";
import Header from "./features/header";
const botName = `VAL`;

const config = {
  initialMessages: [
    createChatBotMessage("", {
      widget: "initialMessage"
    })
  ],
  botName: botName,
  customStyles: {
    botMessageBox: {
      backgroundColor: "#F1F1F2"
    },
    chatButton: {
      backgroundColor: "#4A90FF"
    }
  },
  customComponents: {
    botAvatar: (props) => (
      <img
        width="40px"
        height="40px"
        alt="logo"
        style={{ marginRight: "12.5px" }}
        src={chatbotLogo}
      />
    ),
    header: () => {
      return <Header botName={botName} />;
    }
  },
  widgets: [
    {
      widgetName: "initialMessage",
      widgetFunc: (props) => <InitialMessageComponent {...props} />
    },
    {
      widgetName: "resultTable",
      widgetFunc: (props) => <ResultTableComponent {...props} />
    },
    {
      widgetName: "summary",
      widgetFunc: (props) => <SummaryCardComponent {...props} />
    }
  ]
};
export default config;
