import React, { useContext, useState, useEffect } from "react";
import { useAppConfigProvider } from "../../../../services/soft-cache-service";

const GlobalSearchConfigContext = React.createContext();

const initialState = {
  useV2Order: false,
  orderStatusFlagMap: {},
  inventoryStatusFlagMap: {},
  assetStatusFlagMap: {},
  orderPartStatusFlagMap: {},
  daysForHotOrder: 7,
  resultDisplayControl: {
    itemType: [
      { id: "identifier", label: "Part", type: "string", isSearchable: true, bold: true },
      { id: "name", label: "Name", type: "string", isSearchable: true },
      { id: "description", label: "Description", type: "string" }
    ],
    item: [
      { id: "identifier", labelTemplate: "getLabelByItemClass", type: "string", isSearchable: true, bold: true },
      { id: "sensorProfile", label: "Tag", type: "string", defaultValue: "-Not Provided-" },
      { id: "parts", label: "Contains Part(s)", type: "array", bold: true, autoHide: true }
    ]
  }
};

const ASSET_SOLUTION = "asset";
const ORDER_SOLUTION = "order";
const INVENTORY_SOLUTION = "inventory";
const STATUS_FLAG_MAP_FEATURE = "statusFlagMap";
const ORDER_PART_FEATURE = "part";
const USE_V2_ORDER = "useV2";
const DAYS_FOR_HOT_ORDER = "daysForHot";
const GLOBAL_SEARCH_APP = "globalSearch";
const RESULT_DISPLAY_CONTROL = "resultDisplayControl";

export const useGlobalSearchConfigContext = () => {
  return useContext(GlobalSearchConfigContext);
};

export const GlobalSearchConfigContextProvider = ({ children }) => {
  const workOrderSolutionConfigProvider = useAppConfigProvider(ORDER_SOLUTION);
  const assetSolutionConfigProvider = useAppConfigProvider(ASSET_SOLUTION);
  const inventorySolutionConfigProvider = useAppConfigProvider(INVENTORY_SOLUTION);
  const globalSearchConfigProvider = useAppConfigProvider(GLOBAL_SEARCH_APP);

  const [isLoading, setIsLoading] = useState(true);

  const [orderStatusFlagMap, setOrderStatusFlagMap] = useState(initialState.orderStatusFlagMap);
  const [assetStatusFlagMap, setAssetStatusFlagMap] = useState(initialState.assetStatusFlagMap);
  const [inventoryStatusFlagMap, setInventoryStatusFlagMap] = useState(initialState.inventoryStatusFlagMap);
  const [orderPartStatusFlagMap, setOrderPartStatusFlagMap] = useState(initialState.orderPartStatusFlagMap);
  const [useV2Order, setUseV2Order] = useState(initialState.useV2Order);
  const [daysForHotOrder, setDaysForHotOrder] = useState(initialState.daysForHotOrder);
  const [resultDisplayControl, setResultDisplayControl] = useState(initialState.resultDisplayControl);

  useEffect(() => {
    setIsLoading(true);
    const newOrderStatusFlagMap = workOrderSolutionConfigProvider.getValue(STATUS_FLAG_MAP_FEATURE, "object");
    const newAssetStatusFlagMap = assetSolutionConfigProvider.getValue(STATUS_FLAG_MAP_FEATURE, "object");
    const newInventoryStatusFlagMap = inventorySolutionConfigProvider.getValue(STATUS_FLAG_MAP_FEATURE, "object");
    const newOrderPartStatusFlagMap = workOrderSolutionConfigProvider.getValue(ORDER_PART_FEATURE, "object")?.[
      STATUS_FLAG_MAP_FEATURE
    ];

    const newUseV2Order = workOrderSolutionConfigProvider.getValue(USE_V2_ORDER, "bool");
    const newDaysForHotOrder = workOrderSolutionConfigProvider.getValue(DAYS_FOR_HOT_ORDER, "number");

    const newResultDisplayControl = globalSearchConfigProvider.getValue(RESULT_DISPLAY_CONTROL, "object");

    setOrderStatusFlagMap(newOrderStatusFlagMap || initialState.orderStatusFlagMap);
    setAssetStatusFlagMap(newAssetStatusFlagMap || initialState.AssetStatusFlagMap);
    setInventoryStatusFlagMap(newInventoryStatusFlagMap || initialState.inventoryStatusFlagMapStatusFlagMap);
    setOrderPartStatusFlagMap(newOrderPartStatusFlagMap || initialState.orderPartStatusFlagMapStatusFlagMap);
    setUseV2Order(newUseV2Order || initialState.useV2Order);
    setDaysForHotOrder(newDaysForHotOrder || initialState.daysForHotOrder);
    setResultDisplayControl(newResultDisplayControl || initialState.resultDisplayControl);

    setIsLoading(false);
  }, [workOrderSolutionConfigProvider]);

  return (
    <GlobalSearchConfigContext.Provider
      value={{
        isLoading,
        orderStatusFlagMap,
        assetStatusFlagMap,
        inventoryStatusFlagMap,
        orderPartStatusFlagMap,
        useV2Order,
        daysForHotOrder,
        resultDisplayControl
      }}
    >
      {children}
    </GlobalSearchConfigContext.Provider>
  );
};
