import { useEffect, useState } from "react";
import { v4 } from 'uuid';
import bartenderService from "../../services/bartender-service";
import useZebraPrinter from "../use-zebra-printer";
import { useXemelgoAppsyncClient } from '../../services/xemelgo-appsync-service';
import { PRINT_TYPES } from '../../data/constants';

const printerInfo = {
  name: { hiddenOnInfoCard: true },
  message: { label: "Status" },
  id: { label: "IP Address" }
};

export const usePrintService = (isEnabled, printType, printConfig) => {
  const [printers, setPrinters] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [printerErrorMessage, setPrinterErrorMessage] = useState("");
  const xemelgoClientAppSync = useXemelgoAppsyncClient();

  const {
    isPrinterScriptsReady,
    queryPrinters,
    selectedPrinterInfo,
    setSelectedPrinterInfo,
    sendToPrinter,
    registerPrinter,
    stopGetPrinterStatus
  } = useZebraPrinter();

  const { endpoint: bartenderUrl, printerName: bartenderPrinterName = "" } = printConfig || {};
  const { printTag: bartenderPrint, listAvailablePrinters: bartenderListAvailablePrinters } =
    bartenderService(bartenderUrl);

  const queryBartenderPrinters = async () => {
    const availablePrinters = await bartenderListAvailablePrinters();
    const defaultPrinter = { id: "default_printer", name: "default_printer", label: bartenderPrinterName };
    return { printers: availablePrinters || [defaultPrinter], defaultPrinter };
  };

  const print = async (printPayload) => {
    stopGetPrinterStatus();
    try {
      switch (printType) {
        case PRINT_TYPES.ZPL:
          return await sendToPrinter(printPayload);
        case PRINT_TYPES.BARTENDER:
          return await bartenderPrint(printPayload);
        case PRINT_TYPES.BARTENDER_UPLOAD:
          const uploadCSVClient = xemelgoClientAppSync.getUploadCSVClient();
          return uploadCSVClient.uploadCSVToS3(
            `${new Date().getTime()}-${v4()}-tags.csv`,
            "print-files",
            printPayload
          );
        default:
          return null;
      }
    } catch (e) {
      setPrinterErrorMessage("Could not print");
    }
  };

  const getPrinterList = async () => {
    let newPrinters = [];
    let newDefaultPrinter;

    // get list of printers
    try {
      if (bartenderUrl) {
        const { printers: bartenderPrinters, defaultPrinter: defaultBartenderPrinter } = await queryBartenderPrinters();
        newPrinters = [...bartenderPrinters];
        newDefaultPrinter = { ...defaultBartenderPrinter };
      } else if (isPrinterScriptsReady) {
        const { printers: zebraPrinters, defaultPrinter: defaultZebraPrinter } = (await queryPrinters()) || {};
        newPrinters = [...zebraPrinters];
        newDefaultPrinter = { ...defaultZebraPrinter };
      }
      setPrinters(newPrinters);

      // set default printer
      await onSelectPrinter(newDefaultPrinter);
    } catch (e) {
      setPrinterErrorMessage(String(e));
    }

    setIsLoading(false);
  };

  const onSelectPrinter = async (printer) => {
    setPrinterErrorMessage("");
    if (!printer) {
      setSelectedPrinterInfo({});
      await registerPrinter({});
      return;
    }

    // TODO: temporary for default bartender printer
    if (bartenderUrl) {
      setSelectedPrinterInfo({ ...printerInfo, name: { ...printerInfo.name, label: printer.label } });
      return;
    }
    try {
      await registerPrinter(printer);
    } catch (e) {
      setPrinterErrorMessage(String(e));
    }
  };

  useEffect(() => {
    if (isEnabled) {
      setIsLoading(true);
      getPrinterList();
    }
  }, [isEnabled, isPrinterScriptsReady]);

  return { print, printers, selectedPrinterInfo, onSelectPrinter, isLoading, printerErrorMessage };
};
