export const exportCSVQueryBatchHelper = async (executeFn, batchSize = 2000) => {
  let finalResults = [];
  let results = [];
  let index = 0;
  do {
    const promiseList = [];

    promiseList.push(executeFn(batchSize * index, batchSize));
    index++;
    promiseList.push(executeFn(batchSize * index, batchSize));
    index++;
    promiseList.push(executeFn(batchSize * index, batchSize));
    index++;
    promiseList.push(executeFn(batchSize * index, batchSize));
    index++;
    promiseList.push(executeFn(batchSize * index, batchSize));
    index++;

    const responses = await Promise.all(promiseList);

    results = responses.reduce((acc, eachResponse) => {
      return acc.concat(eachResponse);
    }, []);
    finalResults = finalResults.concat(results);
  } while (results?.length);

  return finalResults;
};
