import { UserProfile } from "../domains/user-profile";
import { UserConfiguration } from "../domains/user-configuration";

const CacheKeys = {
  userConfigKey: "Xemelgo-user-configuration",
  userTestConfigKey: "Xemelgo-user-test-configuration",
  userProfileKey: "Xemelgo-user-profile",
  customerTestModeKey: "CustomerTestMode",
  workOrderTrackPageDataViewKey: "workOrderTrackPageDataView",
  sideNavigationNestedTabVerticalCollapsedKey: "Xemelgo-side-navigation-nested-tab-vertical-collapsed",
  sideNavigationHorizontalCollapsedKey: "Xemelgo-side-navigation-horizontal-collapsed",
  chatbotCollapsedKey: "Xemelgo-chatbot-collapsed",
  managementDashboardSiteLocationKey: "managementDashboardSiteLocation"
};

const retrieveCachedObject = (keyName, newDomainFn) => {
  const content = localStorage.getItem(keyName);

  if (content) {
    return newDomainFn(content);
  }

  return null;
};

export const LocalCacheService = {
  saveUserProfile(userProfile) {
    localStorage.setItem(CacheKeys.userProfileKey, userProfile.toJson());
  },

  loadUserProfile() {
    return retrieveCachedObject(CacheKeys.userProfileKey, (content) => {
      return new UserProfile(content);
    });
  },

  clearUserProfile() {
    localStorage.removeItem(CacheKeys.userProfileKey);
  },

  saveUserConfig(userConfiguration) {
    localStorage.setItem(CacheKeys.userConfigKey, userConfiguration.toJson());
  },

  saveUserTestConfig(userConfiguration) {
    localStorage.setItem(CacheKeys.userTestConfigKey, userConfiguration.toJson());
  },

  clearUserTestConfig() {
    localStorage.removeItem(CacheKeys.userTestConfigKey);
  },

  loadUserConfig() {
    let configKey;
    const currentMode = sessionStorage.getItem(CacheKeys.customerTestModeKey);
    if (currentMode === "Test") {
      configKey = CacheKeys.userTestConfigKey;
    } else {
      configKey = CacheKeys.userConfigKey;
    }
    return retrieveCachedObject(configKey, (config) => {
      return UserConfiguration.parse(config);
    });
  },

  clearUserConfig() {
    localStorage.removeItem(CacheKeys.userConfigKey);
  },

  clearAllCaches() {
    localStorage.clear();
  },

  saveWorkOrderTrackPageDataView(dataViewKey) {
    localStorage.setItem(CacheKeys.workOrderTrackPageDataViewKey, dataViewKey);
  },

  getWorkOrderTrackPageDataView() {
    return retrieveCachedObject(CacheKeys.workOrderTrackPageDataViewKey, (content) => {
      return content;
    });
  },

  saveSideNavigationNestedTabVerticalCollapsed(isCollapsed) {
    localStorage.setItem(CacheKeys.sideNavigationNestedTabVerticalCollapsedKey, isCollapsed);
  },

  getSideNavigationNestedTabVerticalCollapsed(tabId) {
    return retrieveCachedObject(CacheKeys.sideNavigationNestedTabVerticalCollapsedKey, (collapsedState) => {
      const cache = JSON.parse(collapsedState);
      return cache && cache[tabId] ? cache[tabId] : false;
    });
  },

  saveSideNavigationHorizontalCollapsed(isCollapsed) {
    localStorage.setItem(CacheKeys.sideNavigationHorizontalCollapsedKey, isCollapsed);
  },

  getSideNavigationHorizontalCollapsed() {
    return retrieveCachedObject(CacheKeys.sideNavigationHorizontalCollapsedKey, (isCollapsed) => {
      return isCollapsed === "true";
    });
  },

  saveChatbotCollapsed(isCollapsed) {
    localStorage.setItem(CacheKeys.chatbotCollapsedKey, isCollapsed);
  },

  getChatbotCollapsed() {
    return retrieveCachedObject(CacheKeys.chatbotCollapsedKey, (isCollapsed) => {
      return isCollapsed === "true";
    });
  },

  saveManagementDashboardSiteLocation(locationId, viewState) {
    localStorage.setItem(CacheKeys.managementDashboardSiteLocationKey, JSON.stringify({ locationId, viewState }));
  },

  getManagementDashboardSiteLocation() {
    return retrieveCachedObject(CacheKeys.managementDashboardSiteLocationKey, (locationData) => {
      return JSON.parse(locationData || {}) || {};
    });
  }
};
