/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-case-declarations */
/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from "react";
import { Card, CardBody } from "mdbreact";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import { ThemeProvider } from "@material-ui/core/styles";
import _ from "lodash";
import useAutoFocus from "../../hooks/use-auto-focus";
import AddPageComponentStyle from "./AddPageComponent.module.css";
import PartCrudTable from "../../pages/track/PartCrudTable";
import ListView from "../TrackPageComponents/ListView";
import LoadingCircle from "../loading/LoadingCircle";
import { AddPageTheme } from "./add-page-inputs/AddPageInputsStyle";
import CheckBoxGroup from "./add-page-inputs/CheckBoxGroup";
import DatePickerInput from "./add-page-inputs/DatePickerInput";
import SearchDropDown from "./add-page-inputs/SearchDropdown";
import TextInput from "./add-page-inputs/TextInput";
import { getFormattedDate, convertAsciiToHex, AddPageInputTypeMap } from "../../common/Utilities";
import InputGroup from "./add-page-inputs/InputGroup";
import DisplayBanner from "../display-banner/DisplayBanner";
import useKeyPress, { MODIFIER_KEYS } from "../../hooks/use-key-press";
import xemelgoStyle from "../../styles/variable";

const MAX_DATE = 4102444800000;
const MIN_DATE = -2208988800000;

const AddPageComponent = ({
  history,
  title,
  bannerError,
  showBanner,
  setShowBanner,
  validCSVHeaderCheck,
  validCSVDataCheck,
  bannerMessage,
  onCloseBanner,
  bannerRightComponent,
  defaultAttributeMap,
  customAttributeMap,
  partConstraint,
  onSubmit,
  onUploadCSVSubmit,
  loading,
  loadingMessage,
  uploadCsv,
  attributeGroupMap,
  isNavigationToTrackPageDisabled
}) => {
  const [formData, setFormData] = useState({});
  const [partData, setPartData] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [csvHeaderData, setCSVHeaderData] = useState([]);
  const [displayListView, setDisplayListView] = useState(false);
  const [csvSubmitted, setCsvSubmitted] = useState(false);
  const [canSubmit, setCanSubmit] = useState(true);
  const autoFocusCallbackRef = useAutoFocus();

  // This useEffect ise used to update formData if defaultAttributeMap or customAttributeMap has been changed
  // Also act like a componentDidMount when AddPageComponent starts to receive props "defaultAttributeMap" and "customAttributeMap"
  useEffect(() => {
    onLoad();
    // eslint-disable-next-line
  }, [defaultAttributeMap, customAttributeMap]);

  useKeyPress(
    () => {
      return displayListView ? (csvSubmitted ? onExitListView() : onListViewSubmit()) : onSingleSubmit();
    },
    "Enter",
    MODIFIER_KEYS.Ctrl
  );

  const onLoad = () => {
    const newFormData = {};
    Object.keys(defaultAttributeMap)
      .sort((a, b) => {
        return defaultAttributeMap[a].index - defaultAttributeMap[b].index;
      })
      .forEach((eachKey) => {
        const {
          defaultValue,
          index,
          label,
          type,
          defaultValue: value,
          transformInput,
          required,
          options,
          canInput,
          groupId,
          hasCreationOption,
          creationOptionLabel,
          metaDataOf,
          numberOnly,
          dependency,
          disabled,
          skipForCreation,
          hidden,
          convertToHex,
          autoGenerateValueUsingKey,
          multiLine,
          multiOrder,
          millisecondsConversionUnit
        } = defaultAttributeMap[eachKey];

        newFormData[eachKey] = {
          defaultValue,
          index,
          label,
          type,
          value,
          transformInput,
          required,
          canInput,
          error: false,
          options,
          groupId,
          hasCreationOption,
          creationOptionLabel,
          metaDataOf,
          numberOnly,
          dependency,
          disabled,
          skipForCreation,
          hidden,
          convertToHex,
          autoGenerateValueUsingKey,
          multiLine,
          multiOrder,
          millisecondsConversionUnit
        };
      });
    Object.keys(customAttributeMap)
      .sort((a, b) => {
        return customAttributeMap[a].index - customAttributeMap[b].index;
      })
      .forEach((eachKey) => {
        const {
          defaultValue,
          index,
          label,
          type,
          defaultValue: value,
          transformInput,
          required,
          options,
          canInput,
          groupId,
          hasCreationOption,
          creationOptionLabel,
          metaDataOf,
          numberOnly,
          dependency,
          disabled,
          skipForCreation,
          hidden,
          convertToHex,
          autoGenerateValueUsingKey,
          multiLine,
          multiOrder,
          millisecondsConversionUnit
        } = customAttributeMap[eachKey];
        newFormData[eachKey] = {
          defaultValue,
          index,
          label,
          type,
          value,
          transformInput,
          required,
          error: false,
          options,
          canInput,
          groupId,
          hasCreationOption,
          creationOptionLabel,
          metaDataOf,
          numberOnly,
          dependency,
          disabled,
          skipForCreation,
          hidden,
          convertToHex,
          autoGenerateValueUsingKey,
          multiLine,
          multiOrder,
          millisecondsConversionUnit
        };
      });
    setFormData(newFormData);
  };

  const parseCSV = (event) => {
    setShowBanner(false);
    // Create headersToIdMap for later use that will map the Headers to Ids
    const headersToIdMap = {};
    const dependencyAttributes = [];
    const defaultAttributes = [];
    Object.keys(defaultAttributeMap).forEach((id) => {
      headersToIdMap[defaultAttributeMap[id].label] = id;

      if (defaultAttributeMap[id].dependency) {
        dependencyAttributes.push(id);
      }

      if (defaultAttributeMap[id].defaultValue) {
        defaultAttributes.push(id);
      }
    });
    Object.keys(customAttributeMap).forEach((id) => {
      headersToIdMap[customAttributeMap[id].label] = id;
    });

    if (event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = () => {
        const allRows = reader.result.split(/\r|\n/).filter((each) => {
          return each;
        });
        const headers = allRows[0].replace("ï»¿", "").split(",");
        setCSVHeaderData(headers);
        if (validCSVHeaderCheck(headers)) {
          // looping through each row except the header and return an array of object that has multiple attribute based on headers
          const result = allRows.slice(1, allRows.length).reduce((accumulator, eachRow) => {
            const columns = eachRow.replace('"', "").split(",");
            const currentRow = columns.reduce((accu, eachData, index) => {
              const headerId = headersToIdMap[headers[index]];
              if (headerId) {
                accu[headerId] = eachData.trim() || undefined;
              }
              return accu;
            }, {});

            defaultAttributes.forEach((attribute) => {
              const { defaultValue, type } = defaultAttributeMap[attribute];
              if (!currentRow[attribute]) {
                if (type === AddPageInputTypeMap.CHECK_BOX_GROUP && typeof defaultValue === "object") {
                  currentRow[attribute] = Object.keys(defaultValue)
                    .filter((value) => {
                      return defaultValue[value];
                    })
                    .join(", ");
                } else {
                  currentRow[attribute] = defaultValue?.label || defaultValue;
                }
              }
            });

            dependencyAttributes.forEach((attribute) => {
              const { dependency, type = "input", numberOnly = false } = defaultAttributeMap[attribute];
              const { dependentOn = [], action } = dependency;
              if (!currentRow[attribute]) {
                let totalValue = "";
                if (numberOnly || type === "datepicker") {
                  totalValue = 0;
                }
                dependentOn.forEach((each) => {
                  if (currentRow[each]) {
                    switch (action) {
                      case "add":
                        totalValue += currentRow[each];
                        break;
                      default:
                        totalValue = currentRow[each];
                        break;
                    }
                  }
                });

                if (totalValue) {
                  currentRow[attribute] = totalValue;
                }
              }
            });
            accumulator.push(currentRow);
            return accumulator;
          }, []);
          switchToCSVView(result);
        }
      };
      reader.readAsBinaryString(event.target.files[0]);
    }
  };

  const switchToCSVView = (data) => {
    const tempCanSubmit = validCSVDataCheck(data);
    setCanSubmit(tempCanSubmit);
    setCsvData(data);
    setDisplayListView(true);
  };

  const onInputCheck = (id, value) => {
    const {
      required,
      maximumChecked = formData[id].options ? formData[id].options.length : 0,
      minimumChecked = 0,
      type,
      label,
      metaDataOf
    } = formData[id];
    switch (type) {
      case AddPageInputTypeMap.CHECK_BOX_GROUP:
        let totalCheck = 0;
        Object.keys(value).forEach((key) => {
          if (value[key]) {
            totalCheck += 1;
          }
        });
        if (totalCheck < minimumChecked) {
          return {
            hasError: true,
            errorMessage: `Please choose a minimum of ${minimumChecked} options(s)`
          };
        }
        if (totalCheck > maximumChecked) {
          return {
            hasError: true,
            errorMessage: `Please choose a maximum of ${maximumChecked} option(s)`
          };
        }
        break;
      case AddPageInputTypeMap.DATE_PICKER:
        const timestamp = value && value.getTime();
        if (required && !value) {
          return { hasError: true, errorMessage: `${label} is a required field` };
        }
        // eslint-disable-next-line no-restricted-globals
        if (isNaN(timestamp)) {
          return { hasError: true, errorMessage: `${label} is not a valid date` };
        }
        if (timestamp < MIN_DATE) {
          return {
            hasError: true,
            errorMessage: `${label} can't be earlier than ${getFormattedDate(MIN_DATE, "MM/DD/YYYY")}`
          };
        }
        if (timestamp > MAX_DATE) {
          return {
            hasError: true,
            errorMessage: `${label} can't be later than  ${getFormattedDate(MAX_DATE, "MM/DD/YYYY")}`
          };
        }
        break;
      case AddPageInputTypeMap.SEARCH_DROP_DOWN_FROM_API:
      case AddPageInputTypeMap.SEARCH_DROP_DOWN:
      case AddPageInputTypeMap.INPUT:
        const isNewItemType =
          metaDataOf &&
          formData[metaDataOf].options &&
          !formData[metaDataOf]?.value?.value &&
          id === "itemTypeIdentifier";
        if (isNewItemType) {
          let isDuplicateType;
          formData[metaDataOf].options.forEach((option) => {
            if (option.value === value) {
              isDuplicateType = true;
            }
          });
          if (isDuplicateType) {
            return { hasError: true, errorMessage: `Asset type "${value}" already exists` };
          }
        }
      default:
        if (required && !value) {
          return { hasError: true, errorMessage: `${label} is a required field` };
        }
        break;
    }
    return { hasError: false, errorMessage: null };
  };

  const onFormDataChange = async (id, value, transformInput) => {
    // idsNeedToCheckForCombinationValue is a list of id that has dependency or is dependent by other attributes
    const idsNeedToCheckForDependency = [id];
    const { hasError, errorMessage } = onInputCheck(id, value);
    let transformedInput = value;
    if (transformInput) {
      switch (transformInput) {
        case "toUpperCase":
          transformedInput = value.toUpperCase();
          break;
        default:
          transformedInput = value;
      }
    }

    const newFormData = _.cloneDeep(formData);
    newFormData[id] = {
      ...newFormData[id],
      value: transformedInput,
      error: hasError,
      errorMessage
    };

    // Apply the value from metaDataOf attribute to the attribute
    Object.keys(newFormData).forEach((eachKey) => {
      const { metaDataOf } = newFormData[eachKey];
      if (metaDataOf === id) {
        idsNeedToCheckForDependency.push(eachKey);
        newFormData[eachKey].value =
          newFormData[id].value && newFormData[id].value[eachKey]
            ? newFormData[id].value[eachKey]
            : newFormData[eachKey].defaultValue;
        newFormData[eachKey].error = false;
        newFormData[eachKey].errorMessage = "";
      }
    });

    // try to combine the value for dependencies
    // supported combinations are : 1. all number -> add all numbers together 2. combined one date with number(s), number will be treated as days and added to the date
    idsNeedToCheckForDependency.forEach((eachId) => {
      const eachIdValue = newFormData[eachId].value;
      Object.keys(newFormData)
        .filter((eachKey) => {
          const { dependency = {} } = newFormData[eachKey];
          const { dependentOn = [] } = dependency;
          return dependentOn.includes(eachId);
        })
        .forEach((eachKey) => {
          const { dependency = {}, type = "input", numberOnly = false } = newFormData[eachKey];
          const { dependentOn = [], action } = dependency;

          // totalValue is the sum of all number or combined string
          let totalValue = "";
          let date = null;
          let hasValue = true;
          if (numberOnly || type === "datepicker") {
            totalValue = 0;
          }

          dependentOn.forEach((each) => {
            if (newFormData[each].value) {
              switch (newFormData[each].type) {
                case "input":
                  switch (action) {
                    case "add":
                    default:
                      totalValue += newFormData[each].value;
                  }
                  break;
                case "datepicker":
                  date = newFormData[each].value;
                  break;
                case "dropdownWithOptionsFromAPI":
                case "dropdown":
                  totalValue = newFormData[each].value.value;
                  break;
              }
            } else {
              hasValue = false;
            }
          });
          if (hasValue) {
            let finalValue;
            // if trying to combine date and numbers
            if (date && totalValue) {
              switch (action) {
                case "add":
                default:
                  finalValue = new Date(date.getTime() + totalValue * 1000 * 60 * 60 * 24);
              }
              // if trying to combine dates, just assign one date to it
            } else if (date) {
              finalValue = date;
              // if trying to combine all numbers only, assign totalValue
            } else if (totalValue) {
              finalValue = totalValue;
            }
            newFormData[eachKey].value = finalValue;
          } else {
            newFormData[eachKey].value = eachIdValue ? newFormData[eachKey].value : newFormData[eachKey].defaultValue;
          }
          newFormData[eachKey].error = false;
          newFormData[eachKey].errorMessage = "";
        });
    });

    setFormData(newFormData);
  };

  const onInputBlur = (event) => {
    const { id } = event.target;
    const { value } = formData[id];
    const { hasError, errorMessage } = onInputCheck(id, value);
    setFormData({
      ...formData,
      [id]: { ...formData[id], error: hasError, errorMessage }
    });
  };

  const onSingleSubmit = () => {
    let canSubmit = true;
    let newFormData = { ...formData };
    const formDataToSubmit = _.cloneDeep(formData);
    Object.keys(formData).forEach((id) => {
      const { value, metaDataOf, convertToHex, numberOnly } = formData[id];
      let { hasError, errorMessage } = onInputCheck(id, value);
      if (metaDataOf && !formData[metaDataOf].value) {
        hasError = false;
        errorMessage = "";
      }
      if (numberOnly && value) {
        const parsedValue = parseFloat(value);
        if (isNaN(parsedValue)) {
          hasError = true;
          errorMessage = "Please enter a valid number";
        } else {
          formDataToSubmit[id].value = parsedValue;
        }
      }
      if (hasError) {
        canSubmit = false;
      }
      if (convertToHex) {
        formDataToSubmit[id].value = convertAsciiToHex(value);
      }
      newFormData = { ...newFormData, [id]: { ...newFormData[id], error: hasError, errorMessage } };
    });
    if (!canSubmit) {
      setFormData(newFormData);
    } else {
      onSubmit(formDataToSubmit, partData);
      onLoad();
    }
  };

  const onListViewSubmit = async () => {
    const csvDataWithResult = await onUploadCSVSubmit(csvData);
    setCsvData(csvDataWithResult);
    setCsvSubmitted(true);
  };

  const onExitListView = () => {
    setCsvData([]);
    setCanSubmit(true);
    setCsvSubmitted(false);
    setDisplayListView(false);
    onCloseBanner();
  };

  const inputTypeControl = (type) => {
    switch (type) {
      case AddPageInputTypeMap.CHECK_BOX_GROUP:
        return CheckBoxGroup;
      case AddPageInputTypeMap.DATE_PICKER:
        return DatePickerInput;
      case AddPageInputTypeMap.SEARCH_DROP_DOWN:
      case AddPageInputTypeMap.SEARCH_DROP_DOWN_FROM_API:
        return SearchDropDown;
      case AddPageInputTypeMap.INPUT:
      default:
        return TextInput;
    }
  };

  const RenderCSVOption = () => {
    if (uploadCsv) {
      return (
        <div className={AddPageComponentStyle.list_view_container}>
          <form style={{ maxWidth: 500 }}>
            <p className={AddPageComponentStyle.main_text}> or upload a CSV </p>
            <div>
              <input
                data-cy-addpage-upload-csv-input
                type="file"
                onChange={parseCSV}
                accept=".csv"
              />
            </div>
            <p className={AddPageComponentStyle.sub_text}>
              Please upload a csv file with the following column headers:
              <br />
              {Object.keys(defaultAttributeMap)
                .filter((eachKey) => {
                  return (
                    !Object.keys(defaultAttributeMap).find((each) => {
                      return defaultAttributeMap[each].metaDataOf === eachKey;
                    }) && !defaultAttributeMap[eachKey]?.hidden
                  );
                })
                .sort((a, b) => {
                  return defaultAttributeMap[a].index - defaultAttributeMap[b].index;
                })
                .map((eachKey, i) => {
                  const { label, required } = defaultAttributeMap[eachKey];

                  return `${i ? ", " : ""}${label}${required ? "*" : ""}`;
                })}
              {Object.keys(customAttributeMap)
                .filter((eachKey) => {
                  return (
                    !Object.keys(customAttributeMap).find((each) => {
                      return customAttributeMap[each].metaDataOf === eachKey;
                    }) && !customAttributeMap[eachKey]?.hidden
                  );
                })
                .sort((a, b) => {
                  return customAttributeMap[a].index - customAttributeMap[b].index;
                })
                .map((eachKey) => {
                  const { label, required } = customAttributeMap[eachKey];
                  return `, ${label}${required ? "*" : ""}`;
                })}
            </p>
            <p className={AddPageComponentStyle.sub_text}>* - required</p>
          </form>
        </div>
      );
    }
    return null;
  };

  const RenderListView = () => {
    const headerStructureList = [];

    csvHeaderData.forEach((label) => {
      const id = Object.keys(defaultAttributeMap).find((each) => {
        return defaultAttributeMap[each].label === label;
      });
      if (id) {
        headerStructureList.push({ id, label });
      }
    });

    csvHeaderData.forEach((label) => {
      const id = Object.keys(customAttributeMap).find((each) => {
        return customAttributeMap[each].label === label;
      });
      if (id) {
        headerStructureList.push({ id, label });
      }
    });
    return (
      <ListView
        onboarding
        handleDelete={
          csvSubmitted
            ? null
            : (row) => {
                setCsvData(
                  csvData.filter((each) => {
                    return JSON.stringify(each) !== JSON.stringify(row);
                  })
                );
              }
        }
        dataList={csvData}
        headerStructureList={headerStructureList}
      />
    );
  };

  const RenderEachInputField = (inputKey, index) => {
    const {
      value,
      error,
      label,
      required,
      transformInput,
      options,
      type,
      errorMessage,
      minimumChecked,
      canInput,
      hasCreationOption,
      creationOptionLabel,
      numberOnly,
      disabled,
      metaDataOf,
      hidden,
      multiLine
    } = formData[inputKey];

    if (hidden) {
      return null;
    }

    const InputByTypeComponent = inputTypeControl(type);

    let secondaryDisabled = false;
    if (!!metaDataOf && typeof formData[metaDataOf].value[inputKey] !== "undefined") {
      secondaryDisabled = true;
    }

    return (
      <div
        key={inputKey}
        className={AddPageComponentStyle.input_container}
      >
        <InputByTypeComponent
          ref={type === AddPageInputTypeMap.INPUT && index === 0 ? autoFocusCallbackRef : null}
          numberOnly={numberOnly}
          disabled={disabled || secondaryDisabled}
          hasCreationOption={hasCreationOption}
          creationOptionLabel={creationOptionLabel}
          options={options}
          id={inputKey}
          value={value}
          error={error}
          label={label}
          required={required}
          transformInput={transformInput}
          onChange={onFormDataChange}
          onBlur={onInputBlur}
          minimumChecked={minimumChecked}
          canInput={canInput}
          minDate={MIN_DATE}
          maxDate={MAX_DATE}
          multiline={multiLine}
        />
        <p className={AddPageComponentStyle.input_error_text}>{errorMessage}</p>
      </div>
    );
  };

  const { partTracking, quantity } = partConstraint;

  if (loading) {
    return <LoadingCircle message={loadingMessage} />;
  }

  return (
    <div>
      {showBanner && (
        <DisplayBanner
          bannerError={bannerError}
          onCloseBanner={onCloseBanner}
          bannerTitle={bannerError ? "Error" : "Success!"}
          bannerMessage={bannerMessage}
          rightComponent={bannerRightComponent}
        />
      )}
      <Card>
        <div
          className={AddPageComponentStyle.title}
          data-cy-add-page__title
        >
          {`New ${title}`}
        </div>
        <CardBody className={AddPageComponentStyle.content_container}>
          {!isNavigationToTrackPageDisabled && (
            <div
              role="button"
              onClick={() => {
                if (displayListView) {
                  setShowBanner(false);
                  setDisplayListView(false);
                  setCsvData({});
                  setCanSubmit(true);
                  setCsvSubmitted(false);
                } else {
                  history.goBack();
                }
              }}
              tabIndex={-1}
              className={AddPageComponentStyle.cancel_button_group}
            >
              <KeyboardArrowLeftIcon
                style={{ color: xemelgoStyle.theme.APP_BLUE }}
                className={AddPageComponentStyle.cancel_button_icon}
              />
              <div
                className={AddPageComponentStyle.cancel_button_text}
                data-cy-add-page__cancel-button
              >
                Cancel
              </div>
            </div>
          )}
          {displayListView ? (
            <RenderListView />
          ) : (
            <div className={AddPageComponentStyle.outer_attribute_container}>
              {Object.keys(attributeGroupMap)
                .sort((a, b) => {
                  return attributeGroupMap[a].index - attributeGroupMap[b].index;
                })
                .map((eachGroupId) => {
                  const { label: groupLabel, attributeKey } = attributeGroupMap[eachGroupId];
                  return (
                    <div
                      key={eachGroupId}
                      className={AddPageComponentStyle.left_attribute_container}
                    >
                      <InputGroup label={groupLabel}>
                        <ThemeProvider theme={AddPageTheme}>
                          {Object.keys(formData)
                            .filter((eachKey) => {
                              const { metaDataOf } = formData[eachKey];
                              if (metaDataOf) {
                                return (
                                  typeof formData[metaDataOf].value === "object" &&
                                  formData[metaDataOf].value &&
                                  attributeKey.includes(eachKey)
                                );
                              }
                              return attributeKey.includes(eachKey);
                            })
                            .sort((a, b) => {
                              const aIndex = (defaultAttributeMap[a] || customAttributeMap[a]).index;
                              const bIndex = (defaultAttributeMap[b] || customAttributeMap[b]).index;
                              return aIndex - bIndex;
                            })
                            .map((eachKey) => {
                              return RenderEachInputField(eachKey);
                            })}
                        </ThemeProvider>
                      </InputGroup>
                    </div>
                  );
                })}
              {!!Object.keys(formData).filter((eachKey) => {
                const hasGroup = Object.keys(attributeGroupMap).find((eachGroupId) => {
                  const { attributeKey } = attributeGroupMap[eachGroupId];
                  return attributeKey.includes(eachKey);
                });
                return !hasGroup;
              }).length && (
                <div className={AddPageComponentStyle.left_attribute_container}>
                  <ThemeProvider theme={AddPageTheme}>
                    {Object.keys(formData)
                      .filter((eachKey) => {
                        const hasGroup = Object.keys(attributeGroupMap).find((eachGroupId) => {
                          const { attributeKey } = attributeGroupMap[eachGroupId];
                          return attributeKey.includes(eachKey);
                        });
                        return !hasGroup;
                      })
                      .sort((a, b) => {
                        const aIndex = (defaultAttributeMap[a] || customAttributeMap[a]).index;
                        const bIndex = (defaultAttributeMap[b] || customAttributeMap[b]).index;
                        return aIndex - bIndex;
                      })
                      .map((eachKey, index) => {
                        return RenderEachInputField(eachKey, index);
                      })}
                  </ThemeProvider>
                </div>
              )}
              <div className={AddPageComponentStyle.right_attribute_container}>
                <div style={{ marginBottom: quantity && quantity !== "none" ? 80 : 0 }}>
                  <PartCrudTable
                    partConstraint={{ quantity }}
                    partInfo={partData}
                    partTracking={partTracking}
                    changeHandler={(returnData) => {
                      setPartData(returnData);
                    }}
                  />
                </div>
                <RenderCSVOption />
              </div>
            </div>
          )}
          <div className={AddPageComponentStyle.submit_button_container}>
            <button
              type="submit"
              onClick={displayListView ? (csvSubmitted ? onExitListView : onListViewSubmit) : onSingleSubmit}
              className="request-submit-button"
              disabled={!canSubmit}
              data-cy-add-page__submit-button
            >
              {csvSubmitted ? "OK" : `Create ${title}${displayListView ? "(s)" : ""}`}
            </button>
            <div className={AddPageComponentStyle.button_subtext}>(Ctrl+Enter)</div>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

AddPageComponent.defaultProps = {
  history: {},
  title: "",
  bannerError: false,
  showBanner: false,
  validCSVDataCheck: () => {},
  validCSVHeaderCheck: () => {},
  bannerMessage: "",
  onCloseBanner: () => {},
  defaultAttributeMap: {},
  customAttributeMap: {},
  partConstraint: {},
  onSubmit: () => {},
  loading: false,
  onUploadCSVSubmit: () => {},
  loadingMessage: "",
  uploadCsv: false,
  attributeGroupMap: {},
  setShowBanner: () => {},
  isNavigationToTrackPageDisabled: false
};

AddPageComponent.propTypes = {
  history: PropTypes.object,
  title: PropTypes.string,
  bannerError: PropTypes.bool,
  showBanner: PropTypes.bool,
  validCSVDataCheck: PropTypes.func,
  validCSVHeaderCheck: PropTypes.func,
  bannerMessage: PropTypes.string,
  onCloseBanner: PropTypes.func,
  defaultAttributeMap: PropTypes.object,
  customAttributeMap: PropTypes.object,
  partConstraint: PropTypes.object,
  onSubmit: PropTypes.func,
  loading: PropTypes.bool,
  onUploadCSVSubmit: PropTypes.func,
  loadingMessage: PropTypes.string,
  uploadCsv: PropTypes.bool,
  attributeGroupMap: PropTypes.object,
  setShowBanner: PropTypes.func,
  isNavigationToTrackPageDisabled: PropTypes.bool
};

export default withRouter(AddPageComponent);
