import React, { useState, useMemo } from "react";
import { useQueries } from "@tanstack/react-query";
import useSortableHeader from "../../../../../../hooks/use-sortable-header";
import useWidgetSharableContext from "../../../../features/dashboard-grid/contexts/widget-sharable-context";
import PaginatedListTable from "../../components/paginated-list-table";
import Style from "./StrategicSourcingTable.module.css";
import queryFns from "../../queryFns";

const TableIdIndexMap = {
  "Site Excess Material on Order": 0,
  "Potential Core Supplier Buy": 1,
  "Obsolete Material on Order": 2,
  "Material Sharing Opportunity": 3,
  "EB Conversion Potential": 4
};

const TableIdHeaderMap = {
  "EB Conversion Potential": [
    { id: "PO Number", label: "PO #" },
    { id: "Part Number", label: "Part #", size: "medium" },
    { id: "Description", label: "Description", size: "medium" },
    { id: "SXe Part #", label: "EB Part #", size: "medium" },
    { id: "Vendor Name", label: "Vendor", size: "medium" },
    {
      id: "Unit Stock",
      label: "Unit Stock"
    },
    { id: "Qty Ordered", label: "Qty Ordered" },
    {
      id: "UOM",
      label: "UOM"
    },
    {
      id: "Price",
      label: "Price"
    },
    { id: "Warehouse", label: "Warehouse" }
  ],

  "Material Sharing Opportunity": [
    { id: "po nbr", label: "PO #" },
    { id: "part nbr", label: "Part #", size: "medium" },
    { id: "vend name", label: "Vendor", size: "medium" },
    {
      id: "unit stock",
      label: "Unit Stock"
    },
    { id: "qty_onhand", label: "Qty On-Hand" },
    { id: "qty ord", label: "Qty Ordered" },
    {
      id: "po uom",
      label: "UOM"
    },
    { id: "whse", label: "Warehouse" },
    { id: "optype", label: "Cover" },
    {
      id: "price",
      label: "Price"
    },
    {
      id: "value",
      label: "Total Price"
    },
    { id: "source", label: "Source" },
    { id: "geolocation_reserve", label: "Reserve Location" },
    { id: "as_of_date", label: "Last Updated" }
  ],

  "Obsolete Material on Order": [
    { id: "PO Number", label: "PO #", size: "medium" },
    { id: "Part Number", label: "Part #", size: "medium" },
    { id: "Product Description", label: "Description", size: "large" },
    { id: "Vendor Name", label: "Vendor", size: "large" },
    { id: "Qty on Hand", label: "Qty On-Hand" },
    { id: "Qty Ordered", label: "Qty Ordered" },
    {
      id: "UOM",
      label: "UOM"
    },
    {
      id: "Unit Price",
      label: "Unit Price"
    },
    {
      id: "Total Price",
      label: "Total Price"
    },
    { id: "Email", label: "Email", size: "large" },
    { id: "Warehouse", label: "Warehouse", size: "medium" }
  ],

  "Potential Core Supplier Buy": [
    { id: "PO Number", label: "PO #" },
    { id: "Email", label: "Email", size: "medium" },
    { id: "Part Number", label: "Part #" },
    { id: "Product Description", label: "Description", size: "medium" },
    { id: "Vendor Name", label: "Vendor" },
    { id: "Quantity On Hand", label: "Qty On-Hand" },
    { id: "Qty Ordered", label: "Qty Ordered" },
    {
      id: "UOM",
      label: "UOM"
    },
    {
      id: "Unit Price",
      label: "Unit Price"
    },
    { id: "Warehouse", label: "Warehouse" },

    {
      id: "Due Date",
      label: "Due Date"
    },
    { id: "as_of_date", label: "Last Updated" }
  ],
  "Site Excess Material on Order": [
    { id: "PO Number", label: "PO #" },
    { id: "Part Number", label: "Part #", size: "medium" },
    { id: "Vendor Name", label: "Vendor", size: "large" },
    { id: "Qty on Hand", label: "Qty On-Hand" },
    { id: "Qty Ordered", label: "Qty Ordered" },
    {
      id: "UOM",
      label: "UOM"
    },
    { id: "Warehouse", label: "Warehouse", size: "medium" },

    { id: "Price", label: "Price" }
  ]
};

export const StrategicSourcingTable = () => {
  const { selectedStrategicSourcingTableId } = useWidgetSharableContext();
  const [filterInput, setFilterInput] = useState("");

  const strategicSourcingTablesQueries = useQueries({
    queries: [
      {
        queryKey: ["widgets", "strategic-sourcing-table-Site Excess Material on Order"],
        queryFn: queryFns["strategic-sourcing-table-Site Excess Material on Order"],
        refetchOnWindowFocus: false
      },
      {
        queryKey: ["widgets", "strategic-sourcing-table-Potential Core Supplier Buy"],
        queryFn: queryFns["strategic-sourcing-table-Potential Core Supplier Buy"],
        refetchOnWindowFocus: false
      },
      {
        queryKey: ["widgets", "strategic-sourcing-table-Obsolete Material on Order"],
        queryFn: queryFns["strategic-sourcing-table-Obsolete Material on Order"],
        refetchOnWindowFocus: false
      },
      {
        queryKey: ["widgets", "strategic-sourcing-table-Material Sharing Opportunity"],
        queryFn: queryFns["strategic-sourcing-table-Material Sharing Opportunity"],
        refetchOnWindowFocus: false
      },
      {
        queryKey: ["widgets", "strategic-sourcing-table-EB Conversion Potential"],
        queryFn: queryFns["strategic-sourcing-table-EB Conversion Potential"],
        refetchOnWindowFocus: false
      }
    ]
  });

  const strategicSourcingTablesExportCsvQueries = useQueries({
    queries: [
      {
        queryKey: ["widgets", "strategic-sourcing-table-Site Excess Material on Order-export-csv"],
        queryFn: queryFns["strategic-sourcing-table-Site Excess Material on Order-export-csv"],
        refetchOnWindowFocus: false
      },
      {
        queryKey: ["widgets", "strategic-sourcing-table-Potential Core Supplier Buy-export-csv"],
        queryFn: queryFns["strategic-sourcing-table-Potential Core Supplier Buy-export-csv"],
        refetchOnWindowFocus: false
      },
      {
        queryKey: ["widgets", "strategic-sourcing-table-Obsolete Material on Order-export-csv"],
        queryFn: queryFns["strategic-sourcing-table-Obsolete Material on Order-export-csv"],
        refetchOnWindowFocus: false
      },
      {
        queryKey: ["widgets", "strategic-sourcing-table-Material Sharing Opportunity-export-csv"],
        queryFn: queryFns["strategic-sourcing-table-Material Sharing Opportunity-export-csv"],
        refetchOnWindowFocus: false
      },
      {
        queryKey: ["widgets", "strategic-sourcing-table-EB Conversion Potential-export-csv"],
        queryFn: queryFns["strategic-sourcing-table-EB Conversion Potential-export-csv"],
        refetchOnWindowFocus: false
      }
    ]
  });

  const csvData = useMemo(() => {
    return strategicSourcingTablesExportCsvQueries[TableIdIndexMap[selectedStrategicSourcingTableId]]?.data || [];
  }, [
    selectedStrategicSourcingTableId,
    strategicSourcingTablesExportCsvQueries?.[TableIdIndexMap[selectedStrategicSourcingTableId]]?.data
  ]);

  const [tableData, tableHeader] = useMemo(() => {
    return [
      strategicSourcingTablesQueries[TableIdIndexMap[selectedStrategicSourcingTableId]]?.data || [],
      TableIdHeaderMap[selectedStrategicSourcingTableId] || []
    ];
  }, [
    selectedStrategicSourcingTableId,
    strategicSourcingTablesQueries?.[TableIdIndexMap[selectedStrategicSourcingTableId]]?.data
  ]);

  const { defaultOrderBy, defaultOrderDirection } = useMemo(() => {
    const defaultHeader =
      tableHeader.find((eachHeader) => {
        return eachHeader.defaultSort;
      }) ||
      tableHeader[0] ||
      {};

    return {
      defaultOrderBy:
        selectedStrategicSourcingTableId === "Material Sharing Opportunity" ||
        selectedStrategicSourcingTableId === "Potential Core Supplier Buy"
          ? "as_of_date"
          : defaultHeader.id,
      defaultOrderDirection: "asc"
    };
  }, [tableHeader, selectedStrategicSourcingTableId]);

  const { getSortedData, getSortableHeader } = useSortableHeader(defaultOrderDirection, defaultOrderBy);

  const filteredData = useMemo(() => {
    if (filterInput.length) {
      const keywords = filterInput.trim().split(" ");
      return tableData.filter((data) => {
        let matches = true;
        const searchProperties = tableHeader.reduce((accumulator, header) => {
          return `${accumulator}${data[header.id] || ""} `;
        }, "");

        for (const keyword of keywords) {
          if (!searchProperties.toLowerCase().includes(keyword.toLowerCase())) {
            matches = false;
            break;
          }
        }

        return matches;
      });
    }
    return tableData;
  }, [tableData, tableHeader, filterInput]);

  const isLoading =
    strategicSourcingTablesQueries[TableIdIndexMap[selectedStrategicSourcingTableId]]?.isLoading ?? true;

  return (
    <PaginatedListTable
      isLoading={isLoading}
      title={selectedStrategicSourcingTableId || "Strategic Sourcing Table"}
      header={tableHeader}
      data={getSortedData(filteredData)}
      isCSVDataLoading={
        strategicSourcingTablesExportCsvQueries[TableIdIndexMap[selectedStrategicSourcingTableId]]?.isLoading
      }
      csvData={csvData}
      onFilterChange={setFilterInput}
      filterInput={filterInput}
      renderItem={(eachItem, index) => {
        return (
          <div
            key={`${eachItem?.[tableHeader?.[0]?.id]} ${index}`}
            className={`${Style.flex_row} ${Style.table_item}`}
          >
            {tableHeader.map((eachHeader, subIndex) => {
              return (
                <div
                  key={`${eachItem?.[eachHeader.id]}${subIndex}`}
                  className={`${Style.flex_row} ${Style.table_column} ${Style[`table_column_${eachHeader.size}`]}`}
                >
                  <div className={`${Style.table_item_text} `}>{eachItem[eachHeader.id]}</div>
                </div>
              );
            })}
          </div>
        );
      }}
      headerContainerClass={Style.table_header}
      renderHeaderProp={(eachHeader) => {
        return getSortableHeader(eachHeader, {
          headerContainerClass: `${Style.flex_row} ${Style.table_column} ${Style.table_header} ${
            Style[`table_column_${eachHeader.size}`]
          }`,
          className: Style.table_header_text
        });
      }}
    />
  );
};
