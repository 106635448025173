import React, { useState } from "react";
import SummaryCardComponent from "../summary-card-component/SummaryCardComponent";
import Style from "./ResultTableComponent.module.css";

const ResultTableComponent = (props) => {
  const [jsonData] = useState(props.payload);
  const [showSummary, setShowSummary] = useState(false);

  return (
    <div>
      {showSummary && (
        <>
          <SummaryCardComponent payload={jsonData} />
        </>
      )}
      <table className={Style.table}>
        <tbody>
        <tr className={Style.tr}>
          {jsonData.items &&
            jsonData.items.map !== undefined &&
            Object.keys(jsonData.items[0]).map((key) => {
              return (
                <th
                  className={Style.th}
                  key={key}
                >
                  {key}
                </th>
              );
            })}
        </tr>
        {jsonData.items &&
          jsonData.items.map !== undefined &&
          jsonData.items.map((item, index) => {
            return (
              <tr
                className={Style.tr}
                key={index}
              >
                {Object.keys(item).map((key) => {
                  return (
                    <td
                      className={Style.td}
                      key={key}
                    >
                      {item[key]}
                    </td>
                  );
                })}
              </tr>
            );
          })}
                  </tbody>
      </table>
    </div>
  );
};

export default ResultTableComponent;
