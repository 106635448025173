import React, { useState, useMemo } from "react";
import Skeleton from "react-loading-skeleton";
import PropTypes from "prop-types";
import Style from "./CheckboxListFilter.module.css";
import FreeTextSearchInput from "../free-text-search-input";
/**
 * @typedef CheckboxListType
 * @property {string} id
 * @property {string} label
 * @property {bool} value
 */

/**
 * This component is a filter triggers that enables user to adds or removes
 * by checking or unchecking boxes next to filter criteria.
 * @param {string} title
 * @param {CheckboxListType} checkboxList
 * @param {func} onChange
 * @param {string} containerClassName
 * @param {bool} isLoading
 * @returns {JSX.Element}
 */
export const CheckboxListFilter = ({ title, checkboxList, onChange, containerClassName, isLoading }) => {
  const [showAll, setShowAll] = useState(false);
  const [search, setSearch] = useState("");

  const handleOnChange = (boxId) => {
    const boxIndex = checkboxList.findIndex((a) => {
      return a.id === boxId;
    });
    const newCheckBoxList = [...checkboxList];
    newCheckBoxList[boxIndex].value = !newCheckBoxList[boxIndex].value;
    onChange(newCheckBoxList);
  };

  const filteredCheckboxList = useMemo(() => {
    return checkboxList.filter((eachCheckbox, index) => {
      if (index < 3 || search || showAll) {
        const { label = "" } = eachCheckbox;
        return label.toLowerCase().includes(search.toLowerCase());
      }
    });
  }, [checkboxList, search, showAll]);

  if (isLoading) {
    return (
      <Skeleton
        height={20}
        count={4}
      />
    );
  }

  // TODO: create a share component for each check-box
  return (
    <div className={`${Style.container} ${containerClassName}`}>
      {title && <p className={Style.title}>{title}</p>}
      <FreeTextSearchInput
        value={search}
        onChangeText={(value) => {
          setSearch(value);
        }}
        placeholder="Search"
      />
      <div />
      <div className={Style.option_list}>
        {filteredCheckboxList.map((each, index) => {
          const { label = "", id, value } = each;
          return (
            <div
              key={`${id}_${index}`}
              onClick={() => {
                handleOnChange(id);
              }}
              className={Style.item_container}
            >
              <div className={Style.checkbox_container}>
                <div className={`${value ? Style.checkbox_filled : ""}`} />
              </div>
              <p className={Style.checkbox_title}>{label}</p>
            </div>
          );
        })}
      </div>
      {!search && checkboxList.length > 3 && (
        <div
          className={Style.more}
          onClick={() => {
            setShowAll(!showAll);
          }}
        >
          {showAll ? "Collapse" : "See more"}
        </div>
      )}
    </div>
  );
};

CheckboxListFilter.propTypes = {
  title: PropTypes.string,
  checkboxList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      value: PropTypes.bool
    }).isRequired
  ),
  onChange: PropTypes.func,
  isLoading: PropTypes.bool,
  containerClassName: PropTypes.string
};

CheckboxListFilter.defaultProps = {
  title: "",
  checkboxList: [],
  onChange: () => {},
  isLoading: false,
  containerClassName: ""
};
