import React from "react";
import { ReactComponent as LinkIcon } from "../../../../../../assets/icons/link.svg";
import xemelgoStyle from "../../../../../../styles/variable";
import { ReactComponent as WidgetIcon } from "./Frame 3.svg";
import Style from "./LinkGroup.module.css";

const LINKS = [
  {
    title: "Vallen Hub",
    link: "https://app.powerbi.com/links/8aB0fImHwU?ctid=204a1bdd-0e3b-4ae9-ba95-61c52b064dee&pbi_source=linkShare"
  },
  {
    title: "IMPACT",
    link: "https://app.powerbi.com/links/jbDITeavIN?ctid=204a1bdd-0e3b-4ae9-ba95-61c52b064dee&pbi_source=linkShare"
  },
  {
    title: "Vallen Compass",
    link: "https://app.powerbi.com/links/1OmgWPObcV?ctid=204a1bdd-0e3b-4ae9-ba95-61c52b064dee&pbi_source=linkShare"
  },
  {
    title: "Exclusive Brands",
    link: "https://app.powerbi.com/links/x3jMc0YS40?ctid=204a1bdd-0e3b-4ae9-ba95-61c52b064dee&pbi_source=linkShare"
  },
  {
    title: "C.O.R.E",
    link: "https://app.powerbi.com/links/v3-uz9Yu9_?ctid=204a1bdd-0e3b-4ae9-ba95-61c52b064dee&pbi_source=linkShare"
  },
  {
    title: "Data Science",
    link: "https://app.powerbi.com/links/LbRMJyIU3H?ctid=204a1bdd-0e3b-4ae9-ba95-61c52b064dee&pbi_source=linkShare"
  }
];

export const LinkGroup = ({}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignSelf: "flex-start",
        width: "100%",
        alignItems: "center"
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
          borderBottom: `1px solid ${xemelgoStyle.theme.APP_BORDER_GREY}`,
          paddingBottom: "10px",
          marginBottom: "10px"
        }}
      >
        <div style={{ display: "flex", flexDirection: "row", gap: 10, alignItems: "center" }}>
          <WidgetIcon
            width={20}
            height={20}
          />
          <div style={{ fontWeight: "bold" }}>Vallen Line of Sight</div>
        </div>
        <div style={{ color: xemelgoStyle.theme.APP_BLUE, cursor: "pointer" }}>Settings</div>
      </div>
      {LINKS.map((link) => {
        return (
          <a
            href={link.link}
            target="_blank"
            role="button"
            key={link.link}
            className={Style.link}
            rel="noreferrer"
          >
            {link.title}
            <LinkIcon
              style={{ marginLeft: "5px" }}
              color={xemelgoStyle.theme.APP_BLUE}
            />
          </a>
        );
      })}
    </div>
  );
};
