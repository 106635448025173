import React from "react";
import queryString from "query-string";
import { Modal } from "react-bootstrap";
import InputLabel from "@material-ui/core/InputLabel";
import SearchDropdown from "../../SearchDropdown/SearchDropdown";
import { multipleSortComparison } from "../../../utils";
import TextareaAutosize from "react-textarea-autosize";
import { DropdownItem } from "mdbreact";
import { XemelgoService } from "../../../services/XemelgoService";
import ConfigurationService from "../../../services/ConfigurationService";
import LoadingCircle from "../../loading/LoadingCircle";
import Style from "./css/AssetDetailPage.module.css";
import DetailCard from "../DetailCard";
import DataTable from "../DataTable";
import NotFoundComponent from "../../not-found-component/NotFoundComponent";
import { ReactComponent as AssetIcon } from "../../../assets/icons/asset.svg";
import ScreenFrame from "../../../components/ScreenFrame/ScreenFrame";
import xemelgoStyle from "../../../styles/variable";
import {
  getFormattedDate,
  msToTime,
  getStatusFlags,
  getAttributeList,
  formatProcessStateRoute
} from "../../../common/Utilities";

const defaultShipmentIssueOptionsList = [
  {
    key: "broken",
    label: "Broken Part(s)",
    value: "broken"
  }
];

const customerSpecificShipmentIssueOptionsList = [
  {
    key: "damaged",
    label: "Fallout",
    value: "damaged"
  }
];

export default class AssetDetailPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      titleStructure: {},
      contentStructureList: [],
      assetState: {},
      route: [],
      locations: [],
      markAsFoundOptions: [],
      routeTitleList: [],
      itemId: "",
      addLocation: false,
      locationToAdd: {},
      commentBoxText: "",
      loading: true,
      showShipmentIssueModal: false,
      selectedShipmentIssue: null,
      isSubmitting: false,
      processStateHistory: false,
      processStateRoute: []
    };

    this.renderDeleteAssetModal = this.renderDeleteAssetModal.bind(this);
  }

  componentDidMount() {
    const values = queryString.parse(window.location.search);
    const { itemId } = values;
    this.onLoad(itemId);
  }

  onLoad = async (itemId) => {
    const { configData = {} } = await ConfigurationService.getFullConfiguration();
    const { detailsPage = {} } = configData.webClient?.appConfigurationMap?.asset;
    const processStateHistory = detailsPage?.processStateHistory || false;
    const processStates = detailsPage?.processStates || [];
    const AssetClient = XemelgoService.getClient().getAssetClient();
    const LocationClient = XemelgoService.getClient().getLocationClient();
    const solutionType = await ConfigurationService.getSolutionType();
    const solutionConfiguration = await ConfigurationService.getSolutionConfiguration();
    const availableFlags = (await ConfigurationService.getStatusConfiguration("asset")) || {};
    const attributeMap = (await ConfigurationService.getSolutionAttributeMap("asset")) || {};
    const { defaultAttributeMap } = attributeMap;
    const shipmentsAppConfiguration = solutionConfiguration.uiPreferences.shipments;
    const possibleDetectorLocations = await ConfigurationService.getDetectorLocationCategoriesForSolution("asset");

    const showShipmentData =
      solutionType.includes("shipments") && shipmentsAppConfiguration.shipmentsTrackingItemClasses.includes("Asset");
    const locationRoleEnabled = configData?.features?.locationRole?.enabled;
    const result =
      (await AssetClient.getAssetDetailsById(itemId, showShipmentData, defaultAttributeMap, locationRoleEnabled)) || {};
    const locations = await LocationClient.getLocationsOfCategory(possibleDetectorLocations);
    const { item = {}, shipmentHistory = [] } = result;
    const { is_active, route = [], processStateRoute = [], status_flags = [], identifier, data = {} } = item;
    const formattedProcessStateRoute = formatProcessStateRoute(processStateRoute, processStates);

    const { is_missing, state } = data;

    item.state = processStates.find((processState) => {
      return processState.id === state;
    });

    const assetState = { ...item, is_missing };
    const assetAttributeList = getAttributeList(attributeMap, item);

    const titleStructure = {
      key: assetAttributeList[0].id,
      name: assetAttributeList[0].name,
      value: assetAttributeList[0].value,
      editable: assetAttributeList[0].editable
    };

    const contentStructureList = assetAttributeList.slice(1);
    is_active &&
      contentStructureList.push({
        key: "markAsMissingOrFound",
        name: is_missing ? "Mark As Found" : "Mark As Missing",
        type: "button",
        onClick: () => {
          this.setState({ showMissingOrFoundModal: true });
        }
      });
    const routeTitleList = [
      {
        key: "location",
        value: "Location"
      },
      {
        key: "entryTime",
        value: "Entry Time"
      },
      { key: "exitTime", value: "Exit Time" },
      { key: "timeDuration", value: "Time Duration" }
    ];
    const processStateRouteTitleList = [
      {
        key: "location",
        value: "Location"
      },
      { key: "state", value: "State" },
      {
        key: "eventTime",
        value: "Time"
      },
      { key: "timeDuration", value: "Time Duration" }
    ];
    const shipmentTitleList = [
      {
        key: "shipmentLocation",
        value: "Location"
      },
      {
        key: "eventTime",
        value: "Event Time"
      },
      { key: "eventDetails", value: "Event Details", extraData: true },
      { key: "detector", value: "Detector" },
      { key: "shipmentTimeDuration", value: "Time Duration" }
    ];

    route
      .sort((a, b) => b.entryTime - a.entryTime)
      .forEach((each) => {
        const duration = each.exitTime
          ? msToTime(each.exitTime - each.entryTime)
          : msToTime(Date.now() - each.entryTime);

        each.timeDuration = duration;
        each.entryTime = getFormattedDate(each.entryTime, "hh:mm A MMM D");
        each.exitTime = each.exitTime ? getFormattedDate(each.exitTime, "hh:mm A MMM D") : "-";
      });

    if (status_flags.length > 0) {
      assetState.statusFlags = getStatusFlags(status_flags, availableFlags);
    }

    const filteredLocations = locations
      .filter((eachLocation) => eachLocation.name !== assetState.last_known_location)
      .sort(multipleSortComparison([{ id: "name" }]));
    let newLocations = [];
    filteredLocations.map((each) => {
      return newLocations.push({
        key: each.id,
        label: each.name,
        value: each.id
      });
    });

    const shipmentIssueOptions = [
      ...this.getDefaultShipmentIssueOptions(),
      ...this.getCustomerSpecificShipmentIssueOptions()
    ].sort(multipleSortComparison([{ id: "label" }]));

    // Process event time and duration for each event
    if (showShipmentData) {
      shipmentHistory.forEach((row, index) => {
        const currEvent = row.eventTime;
        const prevEvent = shipmentHistory[index + 1] && shipmentHistory[index + 1].eventTime;
        row.shipmentTimeDuration = msToTime((prevEvent || Date.now()) - currEvent);
        row.eventTime = getFormattedDate(row.eventTime, "hh:mm A MMM D");
        const { condition, comment, submittedBy } = row.eventDetails;
        if (condition || comment || submittedBy) {
          row.extraData = [
            { key: "condition", value: "Condition", data: condition },
            { key: "comment", value: "Comment", data: comment },
            { key: "submittedBy", value: "Submitted By", data: submittedBy }
          ];
        }
        row.eventDetails = row.eventDetails.class;
      });
    }
    let newMarkAsFoundOptions = [];
    locations.map((each) => {
      return newMarkAsFoundOptions.push({
        key: each.id,
        label: each.name,
        value: each.id
      });
    });
    newMarkAsFoundOptions.sort(multipleSortComparison([{ id: "label" }]));
    this.setState({
      itemId,
      assetState,
      showShipmentData,
      route,
      shipmentHistory,
      routeTitleList,
      shipmentTitleList,
      processStateRouteTitleList,
      titleStructure,
      contentStructureList,
      shipmentIssueOptions,
      locations: newLocations,
      markAsFoundOptions: [
        ...newMarkAsFoundOptions,
        { key: "untrackedLocation", label: "Other (Untracked Location)", value: "untrackedLocation" }
      ],
      addLocation: false,
      locationToAdd: {},
      showDeleteAssetModal: false,
      loading: false,
      isSubmitting: false,
      processStateHistory,
      formattedProcessStateRoute
    });
  };

  renderDeleteAssetModal = () => {
    const { showDeleteAssetModal, assetState } = this.state;

    return (
      <Modal
        centered
        backdrop="static"
        backdropClassName={Style.backdrop}
        show={showDeleteAssetModal}
      >
        <Modal.Header className="route-modal-header">
          <Modal.Title className="route-modal-title">{`Delete Asset ${assetState.identifier}?`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Deleting an asset would permanently remove the asset from Xemelgo.</p>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="cancel-button"
            onClick={() => {
              this.setState({ showDeleteAssetModal: false });
            }}
          >
            Cancel
          </button>
          <button
            className="confirm-delete-button"
            onClick={this.deleteAsset}
          >
            Confirm
          </button>
        </Modal.Footer>
      </Modal>
    );
  };

  handleCommentBox = ({ currentTarget }) => {
    this.setState({ [currentTarget.id]: currentTarget.value });
  };

  renderMarkAsMissingOrFoundModal = () => {
    const { showMissingOrFoundModal, assetState, locationToAdd, markAsFoundOptions, commentBoxText } = this.state;

    return (
      <Modal
        centered
        backdrop="static"
        backdropClassName={Style.backdrop}
        show={showMissingOrFoundModal}
      >
        <Modal.Header className="route-modal-header">
          <Modal.Title className="route-modal-title">
            {`Mark ${assetState.identifier} As ${assetState.is_missing ? "Found" : "Missing"}`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!assetState.is_missing && (
            <p>
              You're about to mark
              <b style={{ fontWeight: "bold" }}>{` ${assetState.identifier} `}</b>
              as Missing, are you sure you want to do this?
            </p>
          )}

          {assetState.is_missing && (
            <div>
              <InputLabel>{`Select location where ${assetState.identifier} was found`}</InputLabel>
              <SearchDropdown
                selectedItem={locationToAdd || {}}
                showIcon
                options={markAsFoundOptions}
                onItemSelected={(event) => {
                  this.setState({
                    locationToAdd: event
                  });
                }}
              />

              {locationToAdd.value === "untrackedLocation" && (
                <div className={Style.commentBoxContainer}>
                  <InputLabel>Specify where asset was found (Optional)</InputLabel>
                  <TextareaAutosize
                    id="commentBoxText"
                    className={Style.detail_value_input}
                    value={commentBoxText}
                    onChange={this.handleCommentBox}
                  />
                </div>
              )}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button
            className="cancel-button"
            onClick={() => {
              this.setState({
                showMissingOrFoundModal: false,
                locationToAdd: {},
                commentBoxText: ""
              });
            }}
          >
            Cancel
          </button>
          <button
            className="default-button"
            onClick={this.markAsMissingOrFound}
          >
            Confirm
          </button>
        </Modal.Footer>
      </Modal>
    );
  };

  markAsMissingOrFound = async () => {
    const { itemId, locationToAdd, commentBoxText, assetState, markAsFoundOptions } = this.state;
    const selectedLocation = markAsFoundOptions.find((option) => option.value === locationToAdd.value);

    const contextPayload = {
      missing: {
        itemIdentifier: assetState.identifier,
        value: !assetState.is_missing,
        comment: commentBoxText,
        lastSeenLocation: assetState.is_missing ? selectedLocation.name : assetState.data.last_known_location
      }
    };

    if (!assetState.is_missing) {
      contextPayload.missing.flipConditions = "ManualOrAnyDetectionAtLocation";
    }

    const AssetClient = XemelgoService.getClient().getAssetClient();

    try {
      await AssetClient.markAssetAsMissingOrFound(
        itemId,
        locationToAdd.value === "untrackedLocation" ? null : locationToAdd.value,
        contextPayload
      );
    } catch (e) {
      window.alert(e);
    } finally {
      this.setState({
        commentBoxText: "",
        showMissingOrFoundModal: false
      });
      await this.onLoad(itemId);
    }
  };

  deleteAsset = async () => {
    const { itemId } = this.state;
    const AssetClient = XemelgoService.getClient().getAssetClient();

    try {
      await AssetClient.deleteAsset(itemId);
    } catch (e) {
      console.log(e);
    } finally {
      window.history.back();
    }
  };

  renderAddLocationModal = (addLocation) => {
    const { locations, locationToAdd, isSubmitting } = this.state;

    return (
      <Modal
        centered
        show={addLocation}
        backdrop="static"
        backdropClassName={Style.backdrop}
      >
        <Modal.Header>
          <Modal.Title>Add Location</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputLabel>Select a location</InputLabel>
          <SearchDropdown
            selectedItem={locationToAdd || {}}
            showIcon
            options={locations}
            onItemSelected={(event) => {
              this.setState({
                locationToAdd: event
              });
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className={`${Style.button} ${Style.cancel_button}`}
            onClick={() =>
              this.setState({
                locationToAdd: {},
                addLocation: !addLocation
              })
            }
          >
            Cancel
          </button>
          <button
            disabled={isSubmitting || Object.keys(locationToAdd).length === 0}
            type="button"
            className={`${Style.button} ${Style.save_button}`}
            onClick={this.handleAddLocation}
          >
            Submit
          </button>
        </Modal.Footer>
      </Modal>
    );
  };

  handleAddLocation = async () => {
    const { itemId, locationToAdd } = this.state;
    const publishClient = XemelgoService.getClient().getPublishClient();
    try {
      this.setState({ isSubmitting: true });
      await publishClient.publishUserEvent([itemId], locationToAdd.value);
    } catch (e) {
      console.log(e);
    } finally {
      await this.onLoad(itemId);
    }
  };

  handleSubmitDetail = async (dataForm, event) => {
    const { itemId } = this.state;
    const AssetClient = XemelgoService.getClient().getAssetClient();
    if (Object.keys(dataForm).length !== 0) {
      try {
        await AssetClient.updateAsset(itemId, dataForm);
      } catch (e) {
        window.alert(e);
      } finally {
        await this.onLoad(itemId);
      }
    }
  };

  getAdditionalMoreButtonOptions = () => {
    return (
      <DropdownItem
        className="dropdown-item"
        onClick={() => {
          this.setState({ showDeleteAssetModal: true });
        }}
      >
        <div className={Style.red_text}>Delete Asset</div>
      </DropdownItem>
    );
  };

  getDefaultShipmentIssueOptions = () => {
    return defaultShipmentIssueOptionsList;
  };

  getCustomerSpecificShipmentIssueOptions = () => {
    return customerSpecificShipmentIssueOptionsList;
  };

  reportShipmentIssue = async () => {
    const { itemId, selectedShipmentIssue, commentBoxText } = this.state;
    const context = {
      shipment: {
        value: "Shipment Issue",
        condition: selectedShipmentIssue.value,
        comment: commentBoxText || ""
      }
    };

    const publishClient = XemelgoService.getClient().getPublishClient();
    try {
      this.setState({ isSubmitting: true });
      await publishClient.publishUserEvent([itemId], null, context);
    } catch (e) {
      console.log(e);
    } finally {
      this.setState({
        showShipmentIssueModal: false,
        selectedShipmentIssue: null,
        commentBoxText: ""
      });
      await this.onLoad(itemId);
    }
  };

  renderShipmentIssueModal = () => {
    const {
      showShipmentIssueModal,
      assetState,
      shipmentIssueOptions,
      selectedShipmentIssue,
      commentBoxText,
      isSubmitting
    } = this.state;

    return (
      <Modal
        centered
        backdrop="static"
        backdropClassName={Style.backdrop}
        show={showShipmentIssueModal}
      >
        <Modal.Header className="route-modal-header">
          <Modal.Title className="route-modal-title">
            {`Report Issue With Shipment for ${assetState.identifier}`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <InputLabel>What is wrong with the shipment?</InputLabel>
            <SearchDropdown
              selectedItem={selectedShipmentIssue || {}}
              showIcon
              options={shipmentIssueOptions}
              onItemSelected={(event) => {
                this.setState({
                  selectedShipmentIssue: event
                });
              }}
            />
            <div className={Style.commentBoxContainer}>
              <InputLabel>Comment (Optional)</InputLabel>
              <TextareaAutosize
                id="commentBoxText"
                className={Style.detail_value_input}
                value={commentBoxText}
                onChange={this.handleCommentBox}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="cancel-button"
            onClick={() => {
              this.setState({
                showShipmentIssueModal: false,
                selectedShipmentIssue: null,
                commentBoxText: ""
              });
            }}
          >
            Cancel
          </button>
          <button
            disabled={!selectedShipmentIssue || isSubmitting}
            className="default-button"
            onClick={this.reportShipmentIssue}
          >
            Confirm
          </button>
        </Modal.Footer>
      </Modal>
    );
  };

  render() {
    const {
      titleStructure,
      contentStructureList,
      assetState,
      showShipmentData,
      route,
      shipmentHistory,
      routeTitleList,
      processStateRouteTitleList,
      shipmentTitleList,
      addLocation,
      showShipmentIssueModal,
      loading,
      processStateHistory,
      formattedProcessStateRoute
    } = this.state;
    if (loading) {
      return <LoadingCircle />;
    }

    if (!assetState?.id) {
      return (
        <NotFoundComponent
          path="/asset"
          buttonLabel="Go To Track Page"
          mainText="Sorry, the asset you have requested has been deleted."
          subText="Please click the following button to go back to track page."
        />
      );
    }

    return (
      <>
        <ScreenFrame
          title="Asset Details"
          color={xemelgoStyle.theme.ASSET_PRIMARY}
          secondaryColor={xemelgoStyle.theme.ASSET_SECONDARY}
          titleIconComponent={
            <AssetIcon
              width={25}
              height={25}
              style={{ color: xemelgoStyle.theme.ASSET_PRIMARY }}
            />
          }
        >
          <div className={Style.content_holder}>
            <div className={Style.detail_group}>
              <DetailCard
                imagePath={assetState.type && assetState.type.imagePath}
                isActive={assetState.is_active}
                statusList={assetState.statusFlags}
                titleStructure={titleStructure}
                detailStructureList={contentStructureList}
                onSubmit={this.handleSubmitDetail}
                getAdditionalMoreButtonOptions={this.getAdditionalMoreButtonOptions}
              />
            </div>
            <div className={Style.table_group}>
              <div className={Style.top_table}>
                <div className={Style.title_group}>
                  <div className={Style.title_label}>Asset Route</div>
                  {assetState.is_active && (
                    <div
                      role="button"
                      tabIndex={-1}
                      onClick={() => this.setState({ addLocation: !addLocation })}
                      className={Style.part_edit_btn}
                    >
                      Add Location
                    </div>
                  )}
                </div>
                {processStateHistory && formattedProcessStateRoute.length ? (
                  <DataTable
                    titleList={processStateRouteTitleList}
                    dataList={formattedProcessStateRoute}
                  />
                ) : (
                  <DataTable
                    titleList={routeTitleList}
                    dataList={route}
                  />
                )}
              </div>
              {showShipmentData && (
                <div className={Style.bottom_table}>
                  <div className={Style.title_group}>
                    <div className={Style.title_label}>Shipping History</div>
                    {assetState.is_active && (
                      <div
                        role="button"
                        tabIndex={-1}
                        onClick={() => {
                          this.setState({ showShipmentIssueModal: !showShipmentIssueModal });
                        }}
                        className={Style.part_edit_btn}
                      >
                        Issue with Shipment
                      </div>
                    )}
                  </div>
                  <DataTable
                    titleList={shipmentTitleList}
                    dataList={shipmentHistory}
                  />
                </div>
              )}
            </div>
          </div>
        </ScreenFrame>
        {this.renderAddLocationModal(addLocation)}
        {this.renderMarkAsMissingOrFoundModal()}
        {this.renderDeleteAssetModal()}
        {this.renderShipmentIssueModal()}
      </>
    );
  }
}
