import React, { useState, useEffect, useMemo, useRef } from "react";
import PropTypes from "prop-types";
import { KeyboardArrowDown, Clear } from "@material-ui/icons";
import AutoSizeTextArea from "../AutoSizeTextArea/AutoSizeTextArea";
import Style from "./SearchDropdown.module.css";

const useOutsideAlerter = (ref, func) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        func();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
};

const SearchDropdown = ({
  disableTabFocus,
  options,
  selectedItem,
  onItemSelected,
  inputValue,
  onChangeText,
  error,
  errorMessage,
  withoutOptionFilter,
  showIcon,
  disableDropDownChevIcon,
  textareaClassName,
  iconContainerClassName,
  renderContent,
  placeholder,
  disabled,
  color,
  showSearchIcon,
  searchIconContainerClassName
}) => {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, () => {
    setIsFocused(false);
  });
  const [inputText, setInputText] = useState("");
  const [isFocused, setIsFocused] = useState(false);

  const handleOnClear = () => {
    onItemSelected({});
    onChangeText("");
    setInputText("");
    setIsFocused(false);
  };

  useEffect(() => {
    setInputText(inputValue);
  }, [inputValue]);

  useEffect(() => {
    if (!isFocused && inputText !== selectedItem.label) {
      setInputText(selectedItem?.label || "");
    }
  }, [selectedItem?.label, isFocused, inputText]);

  const filteredOptions = useMemo(() => {
    if (withoutOptionFilter) {
      return options;
    }
    return options.filter(({ label }) => {
      return label.toLowerCase().includes(inputText.toLowerCase());
    });
  }, [inputText, options]);

  return (
    <div
      className={Style.dropdown_container}
      ref={wrapperRef}
    >
      <div className={`${Style.input_group} ${withoutOptionFilter && Style.input_group_hide_caret}`}>
        {showSearchIcon && (
          <div className={`${Style.search_icon_container} ${searchIconContainerClassName}`}>
            <span className={`fa fa-search ${Style.input_icon}`} />
          </div>
        )}
        <AutoSizeTextArea
          disableTabFocus={disableTabFocus}
          error={error}
          value={withoutOptionFilter ? selectedItem?.label : inputText}
          onChangeText={(text) => {
            if (!withoutOptionFilter) {
              setInputText(text);
              onChangeText(text);
            }
          }}
          onFocus={() => {
            setIsFocused(true);
          }}
          placeholder={placeholder}
          disabled={disabled}
          textareaClassName={`${withoutOptionFilter && Style.input_group_hide_caret} ${
            showSearchIcon && Style.input_with_search_icon
          } ${textareaClassName}`}
        />
        {showIcon && (
          <div className={`${Style.icon_container} ${iconContainerClassName}`}>
            {!withoutOptionFilter && (Object.keys(selectedItem).length > 0 || inputText.length > 0) && (
              <Clear
                onClick={handleOnClear}
                style={{ color: disableDropDownChevIcon && "#979799" }}
              />
            )}
            {!disableDropDownChevIcon && (
              <KeyboardArrowDown
                style={{ color }}
                onClick={() => {
                  setIsFocused(true);
                }}
              />
            )}
          </div>
        )}
      </div>
      {isFocused && (
        <div className={Style.dropdown_menu_container}>
          <ul className={`${Style.dropdown_menu} ${Style.flex_column}`}>
            {renderContent ? (
              renderContent(options, () => {
                setIsFocused(false);
              })
            ) : filteredOptions.length ? (
              filteredOptions.map((eachOption) => {
                const { id, label } = eachOption;
                return (
                  <li
                    key={id}
                    className={Style.dropdown_item}
                    onClick={() => {
                      setInputText(label);
                      onItemSelected(eachOption);
                      setIsFocused(false);
                    }}
                  >
                    {label}
                  </li>
                );
              })
            ) : (
              <li className={Style.dropdown_no_result_message}>No matching items</li>
            )}
          </ul>
        </div>
      )}
      {error && errorMessage && <p className={Style.error_message}>{errorMessage}</p>}
    </div>
  );
};

SearchDropdown.defaultProps = {
  options: [],
  onItemSelected: () => {},
  onChangeText: () => {},
  inputValue: "",
  selectedItem: {},
  error: false,
  errorMessage: "",
  withoutOptionFilter: false,
  showIcon: false,
  disableDropDownChevIcon: false,
  textareaClassName: "",
  iconContainerClassName: "",
  renderContent: null,
  color: "",
  placeholder: "",
  disabled: false,
  showSearchIcon: false,
  searchIconContainerClassName: ""
};

SearchDropdown.propTypes = {
  disableTabFocus: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string, label: PropTypes.string, value: PropTypes.any })),
  onItemSelected: PropTypes.func,
  onChangeText: PropTypes.func,
  inputValue: PropTypes.string,
  selectedItem: PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.any
  }),
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  withoutOptionFilter: PropTypes.bool,
  showIcon: PropTypes.bool,
  disableDropDownChevIcon: PropTypes.bool,
  textareaClassName: PropTypes.string,
  iconContainerClassName: PropTypes.string,
  renderContent: PropTypes.func,
  color: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  showSearchIcon: PropTypes.bool,
  searchIconContainerClassName: PropTypes.string
};

export default SearchDropdown;
