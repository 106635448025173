import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { ChevronLeft, ChevronRight, LocationOn } from "@material-ui/icons";
import _ from "lodash";
import OptionalLink from "../../../../components/optional-link/OptionalLink";
import FloorMap from "../../../../components/FloorMap/FloorMap";
import ScreenFrame from "../../../../components/ScreenFrame/ScreenFrame";
import { useAppConfigProvider } from "../../../../services/soft-cache-service";
import { useXemelgoClient } from "../../../../services/xemelgo-service";
import Style from "./AssetMap.module.css";
import SearchDropdownStyle from "../../../../components/SearchDropdown/SearchDropdown.module.css";
import SearchDropdown from "../../../../components/SearchDropdown/SearchDropdown";
import StatusTile from "../../../../components/StatusTile/StatusTile";
import { getDaysToMs, naturalSort } from "../../../../common/Utilities";
import { useXemelgoAppsyncClient } from "../../../../services/xemelgo-appsync-service";
import PaginatedListTable from "../../../../components/PaginatedList/PaginatedList";

const APP_ID = "asset";
const ASSET_MAP_CONFIG = "assetMap";
const INITIAL_LOCATION_CONFIG = "initialLocationId";
const POSSIBLE_DETECTOR_LOCATIONS = "possibleDetectorLocations";
const MAIN_COLOR = "#F66469";
const SECONDARY_COLOR = "#FEF0F1";
const SEARCH_TYPE_MAP = {
  asset_number: { id: "asset_number", label: "Asset #", value: "asset_number" },
  asset_type: { id: "asset_type", label: "Asset Type", value: "asset_type" }
};

const STATUS_COLOR_MAP = {
  EmptyLocation: "#4A90FF",
  Healthy: "#4B9910",
  Warning: "#F6B044",
  Critical: "#FF3D46"
};

const TILE_TITLE_MAP = {
  calibrationDueSoonCount: "Warning - Calibration Due Soon",
  calibrationOverdueCount: "Critical - Calibration Overdue"
};

const DAY_IN_MS = getDaysToMs(1);

const debounce = (callback, delay = 500) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      callback(...args);
    }, delay);
  };
};

const getStatusByExpiredTime = (expiredTime) => {
  let currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);
  currentDate = currentDate.getTime();
  if (!expiredTime) {
    return "EmptyLocation";
  }
  let status = "Healthy";

  const daysToExpired = (expiredTime - currentDate) / DAY_IN_MS;
  if (daysToExpired < 0) {
    status = "Critical";
  } else if (daysToExpired <= 7) {
    status = "Warning";
  }
  return status;
};

const getLocationStatusByAssetsCalibrationDate = (locationData) => {
  let locationStatusDate;
  const { assets } = locationData;
  assets.forEach((asset) => {
    // will update to use refresh_date
    const { refresh_date, expiry_date } = asset;
    const calibrationDate = refresh_date || expiry_date;
    if (calibrationDate) {
      if (!locationStatusDate) {
        locationStatusDate = calibrationDate;
      } else if (calibrationDate < locationStatusDate) {
        locationStatusDate = calibrationDate;
      }
    }
  });
  return getStatusByExpiredTime(locationStatusDate);
};

const getLocationStatusById = (locationId, locationStatusMap) => {
  let status = "";
  Object.keys(locationStatusMap).forEach((eachStatus) => {
    if (locationStatusMap[eachStatus].includes(locationId)) {
      status = eachStatus;
    }
  });
  return status;
};
export const AssetMap = ({ history }) => {
  const mapRef = useRef(null);
  const configProvider = useAppConfigProvider(APP_ID);
  const xemelgoClient = useXemelgoClient();
  const xemelgoClientAppSync = useXemelgoAppsyncClient();

  const assetMapConfig = configProvider.getValue(ASSET_MAP_CONFIG, "object") || {};
  const { initialLocationId = "" } = assetMapConfig;
  const possibleDetectorLocations = configProvider.getValue(POSSIBLE_DETECTOR_LOCATIONS, "string") || [];

  const [assetMapClient] = useState(xemelgoClient.getAssetMapClient());
  const [assetMapClientAppSync] = useState(xemelgoClientAppSync.getAssetMapClient());

  const [isLoading, setIsLoading] = useState(true);
  const { mapConfig } = assetMapConfig;
  const [isInformationHide, setIsInformationHide] = useState(!!mapConfig.isOnlyTrackingAssetsWithoutAlertingStatus);

  const [searchString, setSearchString] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [searchLoading, setSearchLoading] = useState(false);
  const [selectedSearchResult, setSelectedSearchResult] = useState({});
  const [searchAssetResults, setSearchAssetResults] = useState([]);
  const [searchType, setSearchType] = useState(SEARCH_TYPE_MAP.asset_number);

  const [showAssetResults, setShowAssetResults] = useState(false);
  const [isAssetResultsLoading, setIsAssetResultsLoading] = useState(false);

  const [locationMap, setLocationMap] = useState({});
  const [allAssetWithLocation, setAllAssetWithLocation] = useState([]);
  const [selectedLocationId, setSelectedLocationId] = useState(initialLocationId);
  const [selectedMetricType, setSelectedMetricType] = useState(undefined);
  const [isTableListLoading, setIsTableListLoading] = useState(false);
  const [assetsFilteredByLocationAndMetrics, setAssetsFilteredByLocationAndMetrics] = useState([]);

  const clearSearchResult = () => {
    setSelectedSearchResult({});
    setShowAssetResults(false);
    setSearchAssetResults([]);
    setSearchResults([]);
  };

  const searchFn = useCallback(
    debounce(async (searchText) => {
      setSearchLoading(true);
      let response = [];
      if (searchText) {
        switch (searchType) {
          case SEARCH_TYPE_MAP.asset_number:
            response = await assetMapClient.searchAssetNumberByIdentifier(searchText);
            break;
          case SEARCH_TYPE_MAP.asset_type:
            response = await assetMapClient.searchAssetTypeByIdentifier(searchText);
            break;
          default:
            break;
        }
      }

      setSearchResults(
        naturalSort(
          response.map(({ id, identifier }) => {
            return { id, label: identifier, value: id };
          }),
          "label"
        )
      );
      setSearchLoading(false);
    }),
    [searchType]
  );
  const assetSearchResultsGroupByLocation = useMemo(() => {
    return Object.values(searchAssetResults).map((eachLocation) => {
      const { lastDetectedLocation = {}, assets = [] } = eachLocation;
      const { id = "", identifier = "" } = lastDetectedLocation;
      return {
        id,
        identifier,
        assets
      };
    });
  }, [searchAssetResults]);

  const colorMap = useMemo(() => {
    const warningLocations = ["defaultWarningLocation"];
    const criticalLocations = ["defaultCriticalLocation"];
    const healthyLocations = ["defaultHealthyLocation"];
    const emptyLocations = ["defaultEmptyLocation"];

    Object.values(assetSearchResultsGroupByLocation).forEach((eachLocation) => {
      const locationStatus = getLocationStatusByAssetsCalibrationDate(eachLocation);
      switch (locationStatus) {
        case "Critical":
          criticalLocations.push(eachLocation.id);
          break;
        case "Warning":
          warningLocations.push(eachLocation.id);
          break;
        case "Healthy":
          healthyLocations.push(eachLocation.id);
          break;
        case "EmptyLocation":
        default:
          emptyLocations.push(eachLocation.id);
      }
    });

    return {
      [STATUS_COLOR_MAP.Healthy]: {
        label: "Healthy",
        locations: healthyLocations
      },
      [STATUS_COLOR_MAP.Warning]: {
        label: "Warning",
        locations: warningLocations
      },
      [STATUS_COLOR_MAP.Critical]: {
        label: "Critical",
        locations: criticalLocations
      },
      [STATUS_COLOR_MAP.EmptyLocation]: {
        label: mapConfig.isOnlyTrackingAssetsWithoutAlertingStatus ? "Tracked Location" : "Empty Location",
        locations: emptyLocations
      }
    };
  }, [assetSearchResultsGroupByLocation]);

  const [defaultColorMap, locationStatusMap] = useMemo(() => {
    const warningLocations = ["defaultWarningLocation"];
    const criticalLocations = ["defaultCriticalLocation"];
    const healthyLocations = ["defaultHealthyLocation"];
    const emptyLocations = ["defaultNoStatusLocation"];

    const assetGroupedByLocationMap = {};
    Object.keys(locationMap).forEach((eachLocationId) => {
      if (locationMap[eachLocationId].childLocations.length === 1) {
        assetGroupedByLocationMap[eachLocationId] = { id: eachLocationId, assets: [] };
      }
    });
    allAssetWithLocation.forEach((eachAsset) => {
      if (assetGroupedByLocationMap[eachAsset.location?.id]) {
        assetGroupedByLocationMap[eachAsset.location?.id].assets.push(eachAsset);
      }
    });
    Object.values(assetGroupedByLocationMap).forEach((eachLocation) => {
      const locationStatus = getLocationStatusByAssetsCalibrationDate(eachLocation);
      switch (locationStatus) {
        case "Critical":
          criticalLocations.push(eachLocation.id);
          break;
        case "Warning":
          warningLocations.push(eachLocation.id);
          break;
        case "Healthy":
          healthyLocations.push(eachLocation.id);
          break;
        case "EmptyLocation":
        default:
          emptyLocations.push(eachLocation.id);
      }
    });

    return [
      {
        [STATUS_COLOR_MAP.Healthy]: {
          label: "Healthy",
          locations: healthyLocations
        },
        [STATUS_COLOR_MAP.Warning]: {
          label: "Warning",
          locations: warningLocations
        },
        [STATUS_COLOR_MAP.Critical]: {
          label: "Critical",
          locations: criticalLocations
        },
        [STATUS_COLOR_MAP.EmptyLocation]: {
          label: mapConfig.isOnlyTrackingAssetsWithoutAlertingStatus ? "Tracked Location" : "Empty Location",
          locations: emptyLocations
        }
      },
      {
        Warning: warningLocations,
        Critical: criticalLocations,
        Healthy: healthyLocations,
        EmptyLocation: emptyLocations
      }
    ];
  }, [allAssetWithLocation, locationMap]);

  const getColorMapForTrackingAssetsWithNoStatus = () => {
    const map = Object.keys(selectedSearchResult).length ? colorMap : defaultColorMap;
    delete map[STATUS_COLOR_MAP.Healthy];
    delete map[STATUS_COLOR_MAP.Warning];
    delete map[STATUS_COLOR_MAP.Critical];

    return map;
  };

  const getLocationMap = async () => {
    const locationClient = xemelgoClient.getLocationClient();
    const newLocationMap = await locationClient.getLocationTree([], possibleDetectorLocations);
    return newLocationMap;
  };

  const getAllAssetWithLocation = async () => {
    const data = await assetMapClient.getAllAssetWithLocation();
    setAllAssetWithLocation(data);
  };

  const getMetricsForAllLocations = async (currentLocationMap) => {
    const [metrics] = await assetMapClientAppSync.getAssetMetricsByLocationIds(Object.keys(currentLocationMap));
    return metrics;
  };

  const getLocationMapWithMetrics = async () => {
    const currentLocationMap = await getLocationMap();
    const metricsByLocation = await getMetricsForAllLocations(currentLocationMap);
    const locationMapWithMetrics = _.cloneDeep(currentLocationMap);

    Object.keys(locationMapWithMetrics).forEach((eachLocationId) => {
      locationMapWithMetrics[eachLocationId].timeExceededMetrics = {};
      locationMapWithMetrics[eachLocationId].countMetrics = {};
    });
    Object.keys(metricsByLocation).forEach((eachLocationId) => {
      const { timeExceededMetrics, countMetrics } = metricsByLocation[eachLocationId];
      if (locationMapWithMetrics[eachLocationId]) {
        locationMapWithMetrics[eachLocationId].countMetrics = { ...countMetrics } || {};
        locationMapWithMetrics[eachLocationId].timeExceededMetrics = { ...timeExceededMetrics } || {};
      }
    });

    // sum up metrics for parent location using leaf location metrics
    Object.keys(locationMapWithMetrics)
      .filter((eachLocationId) => {
        return locationMapWithMetrics[eachLocationId].childLocations.length <= 1;
      })
      .forEach((eachLeafLocationId) => {
        let currentLocation = locationMapWithMetrics[eachLeafLocationId];

        if (!currentLocation.timeExceededMetrics) {
          currentLocation.timeExceededMetrics = {};
        }
        if (!currentLocation.countMetrics) {
          currentLocation.countMetrics = {};
        }

        while (currentLocation.directParentId) {
          Object.keys(currentLocation?.timeExceededMetrics).forEach((eachMetricKey) => {
            if (!locationMapWithMetrics[currentLocation.directParentId].timeExceededMetrics[eachMetricKey]) {
              locationMapWithMetrics[currentLocation.directParentId].timeExceededMetrics[eachMetricKey] = 0;
            }
            locationMapWithMetrics[currentLocation.directParentId].timeExceededMetrics[eachMetricKey] +=
              locationMapWithMetrics[eachLeafLocationId].timeExceededMetrics[eachMetricKey] || 0;
          });

          currentLocation = locationMapWithMetrics[currentLocation.directParentId];
        }
      });

    setLocationMap(locationMapWithMetrics);
    setIsLoading(false);
  };

  const selectedLocationData = useMemo(() => {
    const locationStatus = getLocationStatusById(selectedLocationId, locationStatusMap);

    if (selectedLocationId && Object.keys(locationMap).length) {
      return { ...locationMap[selectedLocationId], locationStatus };
    }
    return null;
  }, [locationMap, selectedLocationId]);

  const getAssetFilteredByLocationAndMetricsFunc = async () => {
    setAssetsFilteredByLocationAndMetrics([]);
    setIsTableListLoading(true);
    if (selectedLocationData?.id && selectedMetricType) {
      const [status = "Healthy"] = selectedMetricType?.split("-") || [];
      const assets = await assetMapClient.getAssetsFilteredByLocationAndStatus(
        selectedLocationData?.childLocations,
        status.trim()
      );
      setAssetsFilteredByLocationAndMetrics(naturalSort(assets, "identifier"));
    }

    setIsTableListLoading(false);
  };

  const onLocationClicked = (id) => {
    const locationId = id || initialLocationId;
    if (locationId !== selectedLocationId) {
      setAssetsFilteredByLocationAndMetrics([]);
      setSelectedMetricType(null);
    }
    setSelectedLocationId(locationId);

    const locationStatus = getLocationStatusById(id, locationStatusMap);
    setIsInformationHide(locationStatus === "EmptyLocation" || locationId === initialLocationId);
  };

  const totalAssetCount = useMemo(() => {
    return Object.keys(locationMap).reduce((acc, eachLocationId) => {
      const { countMetrics = {} } = locationMap[eachLocationId];
      const { totalCount = 0 } = countMetrics;
      return acc + totalCount;
    }, 0);
  }, [locationMap]);

  const LoadingTable = () => {
    return (
      <div className={`${Style.flex_column} ${Style.paginated_list_table_loading_container}`}>
        {[...Array(5)].map((_, index) => {
          return (
            <div
              key={index}
              className={`${Style.flex_row} ${Style.table_item}`}
            >
              <div className={`${Style.flex_row} ${Style.table_column}`}>
                <Skeleton
                  height={20}
                  width={120}
                />
              </div>
              <div className={`${Style.flex_row} ${Style.table_column}`}>
                <Skeleton
                  height={20}
                  width={120}
                />
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const TableContent = useCallback(() => {
    const { timeExceededMetrics = {}, locationStatus } = selectedLocationData;
    if (locationStatus === "EmptyLocation") {
      return <div className={Style.no_result_message}> No tracked assets here </div>;
    }
    return (
      <>
        <div className={`${Style.flex_row} ${Style.information_status_tile_list}`}>
          {Object.keys(timeExceededMetrics).length > 0 &&
            Object.keys(timeExceededMetrics).map((eachMetric) => {
              const statusColorTheme = TILE_TITLE_MAP[eachMetric].split("-")[0].trim();
              return (
                <StatusTile
                  key={eachMetric}
                  active={selectedMetricType === TILE_TITLE_MAP[eachMetric]}
                  title={TILE_TITLE_MAP[eachMetric]}
                  subTitle={timeExceededMetrics[eachMetric]}
                  color={STATUS_COLOR_MAP[statusColorTheme]}
                  onClick={() => {
                    setSelectedMetricType(TILE_TITLE_MAP[eachMetric]);
                  }}
                />
              );
            })}
        </div>
        <div className={`${Style.flex_column} ${Style.information_table}`}>
          {isTableListLoading ? (
            <LoadingTable />
          ) : assetsFilteredByLocationAndMetrics.length ? (
            <PaginatedListTable
              header={["Asset #", "Asset Type"]}
              headerContainerClassName={Style.table_header}
              renderHeader={(eachHeader, index) => {
                return (
                  <div
                    key={index}
                    className={Style.table_column}
                  >
                    <p className={Style.table_header_text}>{eachHeader}</p>
                  </div>
                );
              }}
              data={assetsFilteredByLocationAndMetrics}
              renderItem={(eachItem, index) => {
                return (
                  <div
                    key={index}
                    className={`${Style.flex_row} ${Style.table_item}`}
                  >
                    <div className={`${Style.flex_row} ${Style.table_column}`}>
                      <OptionalLink
                        active={!!eachItem.id}
                        route={`/asset/detail?itemId=${eachItem.id}`}
                        onClick={() => {
                          if (eachItem.id) {
                            history.push(`/asset/detail?itemId=${eachItem.id}`);
                          }
                        }}
                        className={`${Style.interactable_table_item_text}`}
                        onMouseEnter={() => {
                          mapRef.current.hoverLocation(eachItem.lastDetectedLocation.id);
                        }}
                        onMouseLeave={() => {
                          mapRef.current.resetHover();
                        }}
                      >
                        {eachItem.identifier || "-"}
                      </OptionalLink>
                    </div>
                    <div className={`${Style.flex_row} ${Style.table_column}`}>
                      <div className={`${Style.table_item_text}`}>{eachItem.itemType.identifier || "-"}</div>
                    </div>
                  </div>
                );
              }}
            />
          ) : (
            <></>
          )}
        </div>
      </>
    );
  }, [selectedLocationData, selectedMetricType, assetsFilteredByLocationAndMetrics, isTableListLoading]);

  useEffect(() => {
    getAllAssetWithLocation();
    getLocationMapWithMetrics();
    const autoRefreshInterval = setInterval(() => {
      getLocationMapWithMetrics();
    }, 1000 * 60 * 30);

    return () => {
      clearInterval(autoRefreshInterval);
    };
  }, []);

  // Set default selected metric type on each location
  useEffect(() => {
    if (selectedLocationData) {
      const { timeExceededMetrics = {} } = selectedLocationData;
      const { calibrationDueSoonCount = 0, calibrationOverdueCount = 0 } = timeExceededMetrics;
      setSelectedMetricType("Healthy");
      if (calibrationOverdueCount > 0) {
        setSelectedMetricType(TILE_TITLE_MAP.calibrationOverdueCount);
      } else if (calibrationDueSoonCount > 0) {
        setSelectedMetricType(TILE_TITLE_MAP.calibrationDueSoonCount);
      }
    }
  }, [selectedLocationData]);

  useEffect(() => {
    setSearchString("");
    setSearchResults([]);
    setSelectedSearchResult({});
  }, [searchType]);

  useEffect(() => {
    setSearchResults([]);
    setSearchLoading(true);
    searchFn(searchString);
  }, [searchString]);

  useEffect(() => {
    if (selectedSearchResult.id) {
      setIsAssetResultsLoading(true);
      setShowAssetResults(true);
      switch (searchType) {
        case SEARCH_TYPE_MAP.asset_number:
          assetMapClient.getAssetByAssetNumber(selectedSearchResult.id).then((data) => {
            setSearchAssetResults(data);
            setIsAssetResultsLoading(false);
          });
          break;

        case SEARCH_TYPE_MAP.asset_type:
        default:
          assetMapClient.getAssetByAssetTypeId(selectedSearchResult.id).then((data) => {
            setSearchAssetResults(data);
            setIsAssetResultsLoading(false);
          });
          break;
      }
    }
  }, [selectedSearchResult, searchType]);

  useEffect(() => {
    if (selectedLocationData) {
      getAssetFilteredByLocationAndMetricsFunc();
    }
  }, [selectedMetricType, selectedLocationData]);

  const popUp = useCallback(
    (locationId) => {
      const { countMetrics = {}, timeExceededMetrics = {} } = locationMap[locationId];
      const { totalCount = 0 } = countMetrics;

      const timeExceededMetricsInfo = Object.keys(timeExceededMetrics).map((eachMetric) => {
        const statusColorTheme = TILE_TITLE_MAP[eachMetric].split("-")[0].trim();
        return (
          <div key={eachMetric}>
            {`${TILE_TITLE_MAP[eachMetric]}: `}
            <p
              className={Style.popup_value}
              style={{
                color: STATUS_COLOR_MAP[statusColorTheme]
              }}
            >
              {timeExceededMetrics[eachMetric]}
            </p>
          </div>
        );
      });

      return (
        <>
          <div className={Style.popup_title_container}>{locationMap[locationId]?.name}</div>

          <div className={Style.popup_content_container}>
            <div className={Style.popup_label}>
              Total #:
              <p className={Style.popup_value}>{totalCount}</p>
            </div>

            {!mapConfig.isOnlyTrackingAssetsWithoutAlertingStatus && timeExceededMetricsInfo}
          </div>
        </>
      );
    },
    [locationMap]
  );

  const mapNavigationControlClassName = `${Style.map_control} ${isInformationHide && Style.map_control_collapsed}`;

  return (
    <ScreenFrame
      verticalExpandDisabled
      title="Dashboard"
      color={MAIN_COLOR}
      secondaryColor={SECONDARY_COLOR}
      titleIconComponent={() => {
        return (
          <img
            alt="asset-map-icon"
            src={require("../../../../img/asset-map_icon_red.png")}
            width="40x"
            height="40px"
          />
        );
      }}
    >
      {!isLoading && (
        <FloorMap
          ref={mapRef}
          mapStyleURL={mapConfig.mapStyleURL}
          floorPlanTilesets={
            mapConfig?.floorPlanTilesetURL
              ? [...(mapConfig?.floorPlanTilesets || []), { url: mapConfig.floorPlanTilesetURL }]
              : mapConfig?.floorPlanTilesets
          }
          locationsSourceTilesets={
            mapConfig?.locationsSourceDatasetURL && mapConfig?.locationsSourceDatasetName
              ? [
                  ...(mapConfig?.locationsSourceDataset || []),
                  { url: mapConfig.locationsSourceDatasetURL, name: mapConfig.locationsSourceDatasetName }
                ]
              : mapConfig?.locationsSourceTilesets
          }
          markersGeojson={mapConfig?.markersGeojson || null}
          initialViewStates={mapConfig.initialViewStates}
          colorMap={
            mapConfig.isOnlyTrackingAssetsWithoutAlertingStatus
              ? getColorMapForTrackingAssetsWithNoStatus()
              : Object.keys(selectedSearchResult).length
              ? colorMap
              : defaultColorMap
          }
          showColorIndicator
          mapCameraPadding={!isInformationHide && { right: 550 }}
          mapNavigationControlClassName={mapNavigationControlClassName}
          mapColorIndicatorClassName={`${Style.map_control} ${
            isInformationHide && `${Style.map_control_collapsed} ${Style.color_indicator_container_on_panel_collapse}`
          }`}
          popupEnabled
          popupRenderer={(locationId) => {
            return popUp(locationId);
          }}
          onLocationClicked={onLocationClicked}
        >
          <div className={`${Style.flex_column} ${Style.top_right_action_container}`}>
            <div className={`${Style.flex_row} ${Style.top_right_action}`}>
              <div className={Style.search_type_container}>
                <SearchDropdown
                  showIcon
                  withoutOptionFilter
                  options={Object.values(SEARCH_TYPE_MAP)}
                  selectedItem={searchType}
                  onItemSelected={(type) => {
                    clearSearchResult();
                    setSearchType(type);
                  }}
                  textareaClassName={Style.search_type_textarea}
                />
              </div>
              <div className={`${Style.search_container}`}>
                <SearchDropdown
                  showIcon
                  disableDropDownChevIcon
                  placeholder={`Search for an ${searchType.label.toLowerCase()}`}
                  options={searchResults}
                  inputValue={selectedSearchResult.id ? selectedSearchResult.label : searchString}
                  onChangeText={(text) => {
                    if (!text) {
                      setSearchString(text);
                      setShowAssetResults(false);
                    }
                    if (text !== selectedSearchResult.label) {
                      setSelectedSearchResult({});
                      setSearchString(text);
                      setShowAssetResults(false);
                    }
                    if (text === "") {
                      setIsInformationHide(true);
                    }
                  }}
                  selectedItem={selectedSearchResult}
                  textareaClassName={Style.search_textarea}
                  renderContent={(data, blurFunction) => {
                    if (searchLoading) {
                      return (
                        <div style={{ padding: "10px 20px" }}>
                          {[...Array(3)].map((_, index) => {
                            return <Skeleton key={index} />;
                          })}
                        </div>
                      );
                    }
                    if (data.length) {
                      return data.map((item) => {
                        const { id, label } = item;

                        return (
                          <li
                            key={id}
                            className={SearchDropdownStyle.dropdown_item}
                            onClick={() => {
                              setSelectedSearchResult(item);
                              setIsInformationHide(false);
                              blurFunction();
                            }}
                          >
                            {label}
                          </li>
                        );
                      });
                    }
                    return (
                      <li className={SearchDropdownStyle.dropdown_no_result_message}>
                        {!searchString
                          ? `Please enter an ${searchType.label.toLowerCase()} to search`
                          : "No matching results"}
                      </li>
                    );
                  }}
                />
              </div>
            </div>
          </div>

          {mapConfig.isOnlyTrackingAssetsWithoutAlertingStatus && (
            <div
              className={`${Style.total_count_container} ${Style.top_right_action} ${mapNavigationControlClassName}`}
            >
              <h1 className={`${Style.facility_name}`}>{locationMap[initialLocationId]?.name}</h1>
              <div className={`${Style.divider_container}`}>
                <div className={`${Style.divider}`} />
              </div>
              <span>
                {"Total #:  "}
                <span className={`${Style.total_count_number}`}>{totalAssetCount}</span>
              </span>
            </div>
          )}

          {!mapConfig.isOnlyTrackingAssetsWithoutAlertingStatus && (
            <div
              className={`${Style.flex_row} ${Style.information_collapsible_button} ${
                isInformationHide && Style.information_collapsible_button_collapsed
              }`}
              onClick={() => {
                setIsInformationHide((currentValue) => {
                  return !currentValue;
                });
              }}
            >
              {isInformationHide ? <ChevronLeft /> : <ChevronRight />}
            </div>
          )}

          <div
            className={`${Style.flex_column} ${Style.information_container} ${
              isInformationHide && Style.information_container_collapsed
            }`}
          >
            {showAssetResults ? (
              <>
                <div className={Style.information_header}>
                  <div className={`${Style.flex_row} ${Style.information_title_container}`}>
                    {showAssetResults ? (
                      <p className={Style.information_title}>{`Search Results for '${selectedSearchResult.label}'`}</p>
                    ) : (
                      <p className={Style.information_title}>
                        {`Please enter an "${searchType.label.toLowerCase()}" to search`}
                      </p>
                    )}
                  </div>
                </div>

                <div className={`${Style.flex_column}`}>
                  {isAssetResultsLoading ? (
                    <LoadingTable />
                  ) : (
                    assetSearchResultsGroupByLocation.map((eachLocation) => {
                      return (
                        <div
                          className={`${Style.flex_column} ${Style.location_result_group}`}
                          key={eachLocation.id}
                          onMouseEnter={() => {
                            mapRef.current.hoverLocation(eachLocation.id);
                          }}
                          onMouseLeave={() => {
                            mapRef.current.resetHover();
                          }}
                        >
                          <div
                            className={`${Style.flex_row}`}
                            style={{ alignItems: "center" }}
                          >
                            <div className={`${Style.flex_row} ${Style.location_point_icon}`}>
                              <LocationOn />
                            </div>
                            <p className={Style.location_result_label}>{eachLocation.identifier}</p>
                            {searchType === SEARCH_TYPE_MAP.asset_type && (
                              <p className={Style.location_metric}>
                                {eachLocation.assets.length}
                                {eachLocation.assets.length === 1 ? ` Asset` : ` Assets`}
                              </p>
                            )}
                          </div>
                          {eachLocation.assets.map((eachAsset, index) => {
                            const { id, identifier, expiry_date, refresh_date, assetType } = eachAsset;
                            const status = getStatusByExpiredTime(expiry_date);
                            return (
                              <div
                                key={id}
                                className={`${Style.flex_row} ${
                                  eachLocation.assets.length === 1 || index === eachLocation.assets.length - 1
                                    ? Style.location_asset_group
                                    : Style.location_assets_group
                                }`}
                              >
                                <div className={`${Style.flex_row} ${Style.location_result_detail}`}>
                                  <div className={`${Style.flex_column} ${Style.asset_row}`}>
                                    <p className={Style.location_asset_label}>Asset #:</p>
                                    <div
                                      className={`${Style.location_asset_label} ${Style.asset_data_value} ${Style.link}`}
                                      onClick={() => {
                                        if (eachAsset.id) {
                                          history.push(`/asset/detail?itemId=${id}`);
                                        }
                                      }}
                                    >
                                      {identifier}
                                    </div>
                                  </div>
                                  {searchType === SEARCH_TYPE_MAP.asset_number && (
                                    <div className={Style.flex_column}>
                                      <p className={Style.location_asset_label}>Asset Type:</p>
                                      <p className={Style.asset_data_value}>{assetType?.identifier}</p>
                                    </div>
                                  )}
                                  <div className={`${Style.flex_row} ${Style.location_asset_status}`}>
                                    {status === "Critical" && (
                                      <div
                                        className={Style.information_title_status}
                                        style={{
                                          marginLeft: 0,
                                          backgroundColor: STATUS_COLOR_MAP[status]
                                        }}
                                      >
                                        {` ${status} - Calibration Over Due`}
                                      </div>
                                    )}
                                    {status === "Warning" && (
                                      <div
                                        className={Style.information_title_status}
                                        style={{
                                          marginLeft: 0,
                                          backgroundColor: STATUS_COLOR_MAP[status]
                                        }}
                                      >
                                        {` ${status} - Calibration Due Soon`}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      );
                    })
                  )}
                </div>
              </>
            ) : (
              <>
                <div className={Style.information_header}>
                  <div className={`${Style.flex_row} ${Style.information_title_container}`}>
                    <p className={Style.information_title}>{selectedLocationData?.name}</p>
                    {selectedLocationData.locationStatus !== "EmptyLocation" && (
                      <div
                        className={Style.information_title_status}
                        style={{
                          backgroundColor: STATUS_COLOR_MAP[selectedLocationData.locationStatus]
                        }}
                      >
                        {selectedLocationData.locationStatus}
                      </div>
                    )}
                  </div>
                </div>
                <div className={`${Style.flex_column} ${Style.information_content}`}>
                  <TableContent />
                </div>
              </>
            )}
          </div>
        </FloorMap>
      )}
    </ScreenFrame>
  );
};
