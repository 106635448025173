import React, { useState, useEffect, useMemo } from "react";
import _ from "lodash";
import Style from "./EditingBanner.module.css";
import { ReactComponent as CheckerIcon } from "../../../../assets/icons/requirement_met.svg";
import { ReactComponent as CloseIcon } from "../../../../assets/icons/requirement_not_met.svg";
import useCustomizableDashboardStateContext from "../../contexts/customizable-dashboard-state-context";
import useCustomizableDashboardDataSourceContext from "../../contexts/customizable-dashboard-data-source-context";

export const EditingBanner = () => {
  const [isRenameMode, setIsRenameMode] = useState(false);
  const [editingLayoutsName, setEditingLayoutsName] = useState("");

  const { storedLayouts, setStoredLayouts } = useCustomizableDashboardDataSourceContext();
  const {
    currentViewTabIndex,
    setCurrentViewTabIndex,
    editingLayouts,
    setEditingLayouts,
    setIsEditMode,
    setIsAddWidgetModalVisible
  } = useCustomizableDashboardStateContext();

  useEffect(() => {
    setEditingLayoutsName("");
  }, [isRenameMode]);

  const layoutName = useMemo(() => {
    return editingLayouts[currentViewTabIndex]?.name || "";
  }, [editingLayouts, currentViewTabIndex]);

  const onSaveLayoutName = () => {
    const newEditingLayout = _.cloneDeep(editingLayouts);
    newEditingLayout[currentViewTabIndex].name = editingLayoutsName.trim();
    setEditingLayouts(newEditingLayout);
    setIsRenameMode(false);
  };

  const onSaveLayout = () => {
    const newStoredLayout = _.cloneDeep(storedLayouts);
    newStoredLayout[currentViewTabIndex] = _.cloneDeep(editingLayouts[currentViewTabIndex]);
    setStoredLayouts(newStoredLayout);
    setIsEditMode(false);
  };

  return (
    <div className={Style.edit_banner_container}>
      <div className={Style.edit_banner_title_container}>
        <p className={Style.edit_banner_title_text}>{`Editing${!isRenameMode ? ` "${layoutName}"` : ""}`}</p>
        {isRenameMode ? (
          <div className={Style.edit_banner_rename_action_container}>
            <input
              className={Style.edit_banner_rename_input_field}
              placeholder={layoutName}
              onChange={(event) => {
                setEditingLayoutsName(event.target.value);
              }}
            />
            <CheckerIcon
              className={Style.edit_banner_rename_action_button}
              onClick={onSaveLayoutName}
            />
            <CloseIcon
              className={Style.edit_banner_rename_action_button}
              onClick={() => {
                setIsRenameMode(false);
              }}
            />
          </div>
        ) : (
          <button
            type="button"
            className={Style.edit_banner_action_button}
            onClick={() => {
              setIsRenameMode(true);
            }}
          >
            Rename
          </button>
        )}
      </div>
      <div className={Style.edit_banner_action_buttons_container}>
        <button
          type="button"
          className={`${Style.edit_banner_action_button} ${Style.edit_banner_action_button_delete}`}
          onClick={() => {
            const newStoredLayouts = _.cloneDeep(storedLayouts);
            newStoredLayouts.splice(currentViewTabIndex, 1);
            setStoredLayouts(newStoredLayouts);
            setCurrentViewTabIndex(currentViewTabIndex - 1 >= 0 ? currentViewTabIndex - 1 : 0);
            setIsEditMode(false);
          }}
        >
          Delete View
        </button>
        <button
          type="button"
          className={Style.edit_banner_action_button}
          onClick={() => {
            setIsAddWidgetModalVisible(true);
          }}
        >
          Add New Component
        </button>
        <button
          type="button"
          className={Style.edit_banner_action_button}
          onClick={onSaveLayout}
        >
          Save and Exit
        </button>
        <button
          type="button"
          className={Style.edit_banner_action_button}
          onClick={() => {
            if (currentViewTabIndex >= storedLayouts.length) {
              setCurrentViewTabIndex(storedLayouts.length - 1);
            }
            setIsEditMode(false);
          }}
        >
          Exit without Saving
        </button>
      </div>
    </div>
  );
};

EditingBanner.defaultProps = {};

EditingBanner.propTypes = {};
