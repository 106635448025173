import React, { useMemo } from "react";
import Style from "./SiteSelectionContent.module.css";
import useManagementDashboardStateContext from "../../contexts/management-dashboard-state-context";
import PaginatedList from "../../../../components/PaginatedList/PaginatedList";
import TableStyle from "../selected-location-content/SelectedLocationContent.module.css";
import { VIEW_MODE_MAP, STATUS_COLOR_MAP } from "../../data/constants";
import useManagementDashboardConfigContext from "../../contexts/management-dashboard-config-context";

export const SiteSelectionContent = () => {
  const { mapRef, viewMode, setSiteLocation, setSelectedLocationId, activeLocationTreeMap } =
    useManagementDashboardStateContext();

  const { switchSiteControl } = useManagementDashboardConfigContext();

  const siteLocations = useMemo(() => {
    const children = Object.keys(activeLocationTreeMap)
      .filter((eachLocationId) => {
        return switchSiteControl?.locationCategory
          ? activeLocationTreeMap[eachLocationId].category === switchSiteControl.locationCategory
          : activeLocationTreeMap[eachLocationId.category];
      })
      .map((eachLocationId) => {
        return { ...activeLocationTreeMap[eachLocationId] };
      });

    return children;
  }, [activeLocationTreeMap, switchSiteControl]);

  return (
    <>
      <div className={Style.information_header}>
        <div className={`${Style.flex_row} ${Style.information_title_container}`}>
          <p className={Style.information_title}>
            {`Select a ${switchSiteControl?.locationCategory || "Site"} to View`}
          </p>
        </div>
      </div>
      <div className={`${Style.flex_column}`}>
        <div className={Style.list_container}>
          <PaginatedList
            paginatorLocation="top"
            header={[
              `${switchSiteControl?.locationCategory || "Site"} `,
              `${switchSiteControl.locationCategory || "Site"} Status`
            ]}
            headerContainerClassName={TableStyle.table_header}
            renderHeader={(eachHeader, index) => {
              return (
                <div
                  key={index}
                  className={TableStyle.table_column}
                >
                  <p className={TableStyle.table_header_text}>{eachHeader}</p>
                </div>
              );
            }}
            data={siteLocations}
            renderItem={(eachLocation, index) => {
              return (
                <div
                  key={index}
                  className={`${Style.flex_row} ${TableStyle.table_item}`}
                >
                  <div className={`${Style.flex_row} ${TableStyle.table_column}`}>
                    <div
                      className={`${TableStyle.table_item_text} ${TableStyle.table_item_text_active}`}
                      onClick={async () => {
                        const { properties, center } = await mapRef.current.selectMarker(eachLocation.id, true);

                        setSiteLocation(eachLocation.id, {
                          ...JSON.parse(properties?.viewStates || "{}"),
                          zoom: properties?.zoom,
                          center
                        });
                        setSelectedLocationId(eachLocation.id);
                      }}
                      onMouseEnter={() => {
                        mapRef.current.hoverLocation(eachLocation.id);
                      }}
                      onMouseLeave={() => {
                        mapRef.current.resetHover();
                      }}
                    >
                      {eachLocation.name}
                    </div>
                  </div>
                  <div className={`${Style.flex_column} ${TableStyle.table_column}`}>
                    <div
                      className={Style.information_title_status}
                      style={{
                        backgroundColor:
                          STATUS_COLOR_MAP[
                            eachLocation.statuses[
                              viewMode === VIEW_MODE_MAP.expedited_parts_mode ? "expeditedStatus" : "status"
                            ]
                          ],
                        marginLeft: 0
                      }}
                    >
                      {
                        eachLocation.statuses[
                          viewMode === VIEW_MODE_MAP.expedited_parts_mode ? "expeditedStatus" : "status"
                        ]
                      }
                    </div>
                  </div>
                </div>
              );
            }}
          />
        </div>
      </div>
    </>
  );
};
