import React from "react";
import PropTypes from "prop-types";
import xemelgoStyle from "../../../../styles/variable";
import Style from "./UploadStatusComponent.module.css";
import { ReactComponent as SuccessIcon } from "../../../../assets/icons/success.svg";
import { ReactComponent as FailIcon } from "../../../../assets/icons/fail.svg";

export const UploadStatusComponent = ({ uploadProgress, uploadMessage, uploadError }) => {
  if (uploadProgress < 100) {
    return <p className={`${Style.upload_progress_text} ${xemelgoStyle.subtext}`}>{`${uploadProgress}% Completed`}</p>;
  }

  // status message
  if (uploadMessage) {
    return (
      <div className={Style.upload_status}>
        <span
          className={`${xemelgoStyle.subtext}`}
          style={{ color: uploadError ? xemelgoStyle.theme.STATUS_RED : xemelgoStyle.theme.STATUS_GREEN }}
        >
          {uploadError ? (
            <FailIcon
              className={Style.status_icon}
              color={xemelgoStyle.theme.STATUS_RED}
            />
          ) : (
            <SuccessIcon
              className={Style.status_icon}
              color={xemelgoStyle.theme.STATUS_GREEN}
            />
          )}
          {` ${uploadMessage}`}
        </span>
      </div>
    );
  }

  return null;
};

UploadStatusComponent.propTypes = {
  uploadProgress: PropTypes.number.isRequired,
  uploadMessage: PropTypes.string.isRequired,
  uploadError: PropTypes.bool.isRequired
};
