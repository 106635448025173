import React from "react";
import Style from "./SiteMap.module.css";
import MapImage from "./Group 1799.png";

export const SiteMap = () => {
  return (
    <div className={Style.container}>
      <p className={Style.title}>All Customer Locations</p>
      <div className={Style.image_container}>
        <img
          src={MapImage}
          className={Style.image}
        />
      </div>
    </div>
  );
};
