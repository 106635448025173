import React from "react";
import PropTypes from "prop-types";
import Style from "./AddInventoryV2Components.module.css";

const InventoryModalFooter = ({ onClose, onSubmit, disabled, submitLabel }) => {
  return (
    <div className={Style.footer_container}>
      <div className={Style.button_container}>
        <button
          tabIndex="-1"
          className={Style.gray_button}
          onClick={onClose}
        >
          Cancel
        </button>
        <button
          disabled={disabled}
          className={Style.blue_button}
          onClick={onSubmit}
        >
          {submitLabel}
        </button>
      </div>
    </div>
  );
};

InventoryModalFooter.defaultProps = {
  submitLabel: "Submit",
  disabled: false
};

InventoryModalFooter.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitLabel: PropTypes.string,
  disabled: PropTypes.bool
};

export default InventoryModalFooter;
