import { getCurrentTimestamp, getFormattedDate } from "../../../../../../common/Utilities";
import { useXemelgoClient } from "../../../../../../services/xemelgo-service";

const NO_LOCATION_ID = "no_location";

export const useAssetFormDataParser = () => {
  const xemelgoClient = useXemelgoClient();
  const locationClient = xemelgoClient.getLocationClient();

  const generateTags = (timestamp, quantity = 1) => {
    const tags = [];

    const date = getFormattedDate(timestamp, "YYMMDD");

    const startingSerial = 1;
    for (let i = startingSerial; i <= quantity; i++) {
      const paddedString = i.toString().padStart(5, "0");
      tags.push(`${date}${timestamp}${paddedString}`);
    }
    return tags;
  };

  const generatePayload = async (formDataList, formFields) => {
    const timestamp = getCurrentTimestamp();

    const payloadsByLocationIdMap = {};

    const augmentedFormDataList = formDataList.map((formData) => {
      const augmentedFormData = { ...formData };
      const missingFields = Object.keys(formFields).filter((fieldId) => {
        const field = formFields[fieldId];
        return (field.isHidden || field.isDisabled) && !formData[fieldId] && field.defaultValue
      });

      missingFields.forEach((field) => {
        const value = formFields[field].defaultValue.value ?? formFields[field].defaultValue;
        augmentedFormData[field] = value;
      });

      return augmentedFormData
    });

    const locationIdentifierList = augmentedFormDataList.map((eachForm) => {
      return eachForm?.location;
    });

    const locationInfoMap = (await locationClient.getLocationsByIdentifiers(locationIdentifierList)).reduce(
      (locationMap, eachLocation) => {
        const { identifier } = eachLocation;
        locationMap[identifier] = eachLocation;
        return locationMap;
      },
      {}
    );

    augmentedFormDataList.forEach((formData, rowIndex) => {
      let payloads = [];
      const { print_quantity = 1, location, tracker_serial: trackerSerial, ...additionalProperties } = formData;
      // use provided tracker serial or generate
      if (trackerSerial) {
        payloads.push({
          class: "Asset",
          tracker_serial: trackerSerial,
          name: trackerSerial
        });
      } else {
        // generate tag by print quantity
        const newVids = generateTags(timestamp + rowIndex, print_quantity);

        newVids.forEach((tag) => {
          const itemPayload = {
            class: "Asset",
            tracker_serial: tag,
            name: tag
          };

          payloads.push(itemPayload);
        });
      }

      // add other fields to the payload
      payloads = payloads.map((eachPayload) => {
        const newItemPayload = Object.keys(additionalProperties).reduce(
          (tempItemPayload, propName) => {
            const { id, type, numberOnly } = formFields[propName] || {};

            if (id && additionalProperties[propName]) {
              switch (type) {
                case "date":
                case "datepicker":
                  // parse date if it is not parsed
                  const date =
                    typeof additionalProperties[propName] !== "number"
                      ? Date.parse(additionalProperties[propName])
                      : additionalProperties[propName];

                  tempItemPayload[id] = date;
                  break;
                default:
                  const value = numberOnly
                    ? parseFloat(additionalProperties[propName])
                    : additionalProperties[propName];

                  // If the input field was a seletor, get the value
                  tempItemPayload[id] = value.value ?? value;
              }
            }
            return tempItemPayload;
          },

          { ...eachPayload }
        );
        return newItemPayload;
      });

      // group payload by location
      const locationId = locationInfoMap[location]?.id || NO_LOCATION_ID;
      if (!payloadsByLocationIdMap[locationId]) {
        payloadsByLocationIdMap[locationId] = [];
      }
      payloadsByLocationIdMap[locationId].push(...payloads);
    });

    return Object.keys(payloadsByLocationIdMap).map((locationId) => {
      const payloads = payloadsByLocationIdMap[locationId];

      const newLocationId = locationId === NO_LOCATION_ID ? null : locationId;
      return { payloads, locationId: newLocationId };
    });
  };

  return {
    generatePayload
  };
};
