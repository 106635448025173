import React, { useMemo } from "react";
import PropTypes from "prop-types";
import xemelgoStyle from "../../../../styles/variable";
import Style from "./UploadedFileInfoSection.module.css";
import { ReactComponent as CsvIcon } from "../../../../assets/icons/csv.svg";

import UploadStatusComponent from "../upload-status-component";
import ProgressBar from "../../../progress-bar";

export const UploadedFileInfoSection = ({ uploadedFileName, onRemove, progressPercentage, isError, message }) => {
  const isUploadCompleted = useMemo(() => {
    return progressPercentage === 100;
  }, [progressPercentage]);

  return (
    <div className={Style.uploaded_file_container}>
      <div className={Style.upload_process_container}>
        <CsvIcon
          width={100}
          height={100}
        />
        <div className={Style.progress_bar_container}>
          <div className={Style.upload_info_section}>
            <div className={Style.upload_file_text_container}>
              <p className={`${xemelgoStyle.subtext}`}>
                {`${isUploadCompleted ? "" : "Uploading "}`}
                <span className={`${Style.filename} ${xemelgoStyle.subtextBold}`}>{uploadedFileName}</span>
              </p>
            </div>

            <button
              type="button"
              className={`${xemelgoStyle.subtext} ${Style.remove_button}`}
              onClick={onRemove}
            >
              {isUploadCompleted ? "Remove" : "Cancel"}
            </button>
          </div>

          {!isUploadCompleted && (
            <ProgressBar
              percentage={progressPercentage}
              color={xemelgoStyle.theme.APP_BLUE}
              containerClassName={Style.progress_bar}
            />
          )}

          <UploadStatusComponent
            uploadMessage={message}
            uploadError={isError}
            uploadProgress={progressPercentage}
          />
        </div>
      </div>
    </div>
  );
};

UploadedFileInfoSection.propTypes = {
  uploadedFileName: PropTypes.string.isRequired,
  onRemove: PropTypes.func.isRequired,
  progressPercentage: PropTypes.number.isRequired,
  isError: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired
};
