import React, { useContext, useState, useEffect } from "react";
import { useAppConfigProvider } from "../../../../../../services/soft-cache-service";
import { AddAssetFeatureFunctionMap, processOnboardingPageAttributes } from "../../../../../../common/commonAPICalls";
import { PRINT_TYPES } from "../../../../../../data/constants";
import { fieldIdMap } from "./data/constants";

const FEATURE_ID = "addAsset";
const APP_ID = "asset";
const DEFAULT_ONBOARDING_TAB = "defaultOnboardingTab";
const FORM_SECTIONS = "formSections";
const CUSTOM_TEMPLATE = "customTemplate";
const IS_PRINT_ENABLED = "isPrintEnabled";
const IS_BULK_CREATE_ENABLED = "isBulkCreateEnabled";
const PRINT_TYPE = "printType";

const initialState = {
  formSections: [],
  formFields: {},
  bartenderConfig: {},
  defaultOnboardingTab: "single",
  customTemplate: "",
  isPrintEnabled: false,
  printType: PRINT_TYPES.ZPL,
  isBulkCreateEnabled: false
};

const AddAssetFeatureConfigContext = React.createContext();

export const useAddAssetFeatureConfigContext = () => {
  return useContext(AddAssetFeatureConfigContext);
};

export const AddAssetFeatureConfigContextProvider = ({ children }) => {
  const configProvider = useAppConfigProvider(APP_ID);

  const [isLoading, setIsLoading] = useState(true);
  const [formSections, setFormSections] = useState(initialState.formSections);
  const [formFields, setFormFields] = useState(initialState.formFields);

  const [defaultOnboardingTab, setDefaultOnboardingTab] = useState(initialState.defaultOnboardingTab);
  const [customTemplate, setCustomTemplate] = useState(initialState.customTemplate);
  const [isPrintEnabled, setIsPrintEnabled] = useState(initialState.isPrintEnabled);
  const [bartenderConfig, setBartenderConfig] = useState(initialState.bartenderConfig);
  const [printType, setPrintType] = useState(initialState.printType);
  const [zplConfig, setZplConfig] = useState(initialState.zplConfig);
  const [isBulkCreateEnabled, setIsBulkCreateEnabled] = useState(initialState.isBulkCreateEnabled);

  const fetchFormOptions = async (newFormSections) => {
    const newFormFields = newFormSections.reduce((accumulator, section) => {
      const formFieldsMap = {};
      section.forEach((field) => {
        formFieldsMap[fieldIdMap[field.id] || field.id] = field;
      });
      
      return {
        ...accumulator,
        ...formFieldsMap
      };
    }, {});

    const processedFormFields = (await processOnboardingPageAttributes(newFormFields, {}, AddAssetFeatureFunctionMap))
      .defaultAttributeMap;
    
    processedFormFields.item_number = processedFormFields.itemType;
    processedFormFields.location = processedFormFields.onboardingLocation;

    delete processedFormFields.itemType;
    delete processedFormFields.onboardingLocation;

    setFormSections(newFormSections);
    setFormFields(processedFormFields);
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    const addAssetConfigurationV1 = configProvider.getValue(FEATURE_ID, "object");
    const addAssetConfiguration = addAssetConfigurationV1.v2 || {};
    const { assetBartenderConfig = {} } = configProvider.getModelMap();

    const newFormSections = addAssetConfiguration[FORM_SECTIONS] || initialState.formSections;
    const newIsBulkCreateEnabled = addAssetConfiguration[IS_BULK_CREATE_ENABLED] || initialState.isBulkCreateEnabled;
    const newCustomTemplate = addAssetConfiguration[CUSTOM_TEMPLATE] || initialState.customTemplate;
    const newDefaultOnboardingTab = addAssetConfiguration[DEFAULT_ONBOARDING_TAB] || initialState.defaultOnboardingTab;
    const newIsPrintEnabled = addAssetConfiguration[IS_PRINT_ENABLED] || initialState.isPrintEnabled;
    const newPrintType = addAssetConfiguration[PRINT_TYPE] || initialState.printType;
    const newAssetBartenderConfig = assetBartenderConfig.def || initialState.bartenderConfig;

    setBartenderConfig(newAssetBartenderConfig);
    setIsBulkCreateEnabled(newIsBulkCreateEnabled);
    setCustomTemplate(newCustomTemplate);
    setPrintType(newPrintType);
    setZplConfig({ customTemplate: newCustomTemplate });
    setDefaultOnboardingTab(newDefaultOnboardingTab);
    setIsPrintEnabled(newIsPrintEnabled);
    fetchFormOptions(newFormSections);
  }, [configProvider]);

  return (
    <AddAssetFeatureConfigContext.Provider
      value={{
        isLoading,
        formSections,
        formFields,
        bartenderConfig,
        defaultOnboardingTab,
        customTemplate,
        isPrintEnabled,
        printType,
        zplConfig,
        isBulkCreateEnabled
      }}
    >
      {children}
    </AddAssetFeatureConfigContext.Provider>
  );
};
