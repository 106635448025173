import React, { useMemo } from "react";
import Style from "./JobStatusContent.module.css";
import QuickActionButtons from "../quick-action-buttons";
import { STATUS_COLOR_MAP, VIEW_MODE_MAP, STATUS_TITLE_MAP } from "../../../../data/constants";
import LoadingTable from "../../../../components/loading-table";
import useManagementDashboardStateContext from "../../../../contexts/management-dashboard-state-context";
import useManagementDashboardDataSourceContext from "../../../../contexts/management-dashboard-data-source-context";
import useManagementDashboardConfigContext from "../../../../contexts/management-dashboard-config-context";
import PaginatedListTable from "../../../../../../components/PaginatedList/PaginatedList";
import StatusTile from "../../../../../../components/StatusTile/StatusTile";
import TableStyle from "../../SelectedLocationContent.module.css";
import WorkOrderTableRow from "../../components/work-order-table-row";
import useSortableHeader from "../../../../../../hooks/use-sortable-header";
import { SORT_DIRECTION } from "./data/constants";

export const JobStatusContent = () => {
  const {
    listTableControl: { workOrderTable, workOrderTableNumItemsPerPage }
  } = useManagementDashboardConfigContext();

  const {
    mapRef,
    viewMode,
    selectedMetricType,
    setSelectedMetricType,
    selectedLocationId,
    isWorkOrdersFilteredByLocationAndMetricsLoading,
    activeLocationTreeMap
  } = useManagementDashboardStateContext();

  const { workOrdersFilteredByLocationAndMetrics } = useManagementDashboardDataSourceContext();

  const { defaultOrderBy, defaultOrderDirection } = useMemo(() => {
    const defaultHeader =
      workOrderTable.find((eachHeader) => {
        return eachHeader.defaultSort;
      }) ||
      workOrderTable[0] ||
      {};

    return {
      defaultOrderBy: defaultHeader.id,
      defaultOrderDirection: defaultHeader.defaultDirection || SORT_DIRECTION.ascending
    };
  }, [workOrderTable]);

  const { order, orderBy, getSortedData, getSortableHeader } = useSortableHeader(defaultOrderDirection, defaultOrderBy);

  const sortedWorkOrdersFilteredByLocationAndMetrics = useMemo(() => {
    return getSortedData(workOrdersFilteredByLocationAndMetrics);
  }, [order, orderBy, workOrdersFilteredByLocationAndMetrics]);

  const selectedLocation = useMemo(() => {
    if (selectedLocationId && Object.keys(activeLocationTreeMap).length) {
      return activeLocationTreeMap[selectedLocationId];
    }
    return null;
  }, [activeLocationTreeMap, selectedLocationId]);

  const totalCount = selectedLocation?.metrics.total || 0;
  const totalExpeditedCount = selectedLocation?.metrics.expedited || 0;

  let timeExceededWarningCount = 0;
  let timeExceededCriticalCount = 0;
  let lateToStartCriticalCount = 0;
  let lateToStartWarningCount = 0;
  let expeditedHealthyCount = 0;
  const expedited = selectedLocation?.metrics.expedited;

  if (viewMode === VIEW_MODE_MAP.expedited_parts_mode) {
    timeExceededWarningCount = selectedLocation?.metrics.expeditedTimeExceededWarning;
    timeExceededCriticalCount = selectedLocation?.metrics.expeditedTimeExceededCritical;
    lateToStartCriticalCount = selectedLocation?.metrics.expeditedLateToStartCritical;
    lateToStartWarningCount = selectedLocation?.metrics.expeditedLateToStartWarning;
    expeditedHealthyCount =
      expedited -
      timeExceededCriticalCount -
      timeExceededWarningCount -
      lateToStartCriticalCount -
      lateToStartWarningCount;
  } else {
    timeExceededWarningCount =
      selectedLocation?.metrics.timeExceededWarning + selectedLocation?.metrics.expeditedTimeExceededWarning;
    timeExceededCriticalCount =
      selectedLocation?.metrics.timeExceededCritical + selectedLocation?.metrics.expeditedTimeExceededCritical;
    lateToStartCriticalCount =
      selectedLocation?.metrics.lateToStartCritical + selectedLocation?.metrics.expeditedLateToStartCritical;
    lateToStartWarningCount =
      selectedLocation?.metrics.lateToStartWarning + selectedLocation?.metrics.expeditedLateToStartWarning;
  }

  return (
    <>
      <QuickActionButtons />
      <div className={`${Style.flex_row} ${Style.total_count_container}`}>
        <p className={Style.total_count_text}>
          {`Total ${viewMode === VIEW_MODE_MAP.floor_status_mode ? "" : "Expedited "}Work Orders:`}
        </p>
        <p className={`${Style.total_count_text} ${Style.total_count_value}`}>
          {viewMode === VIEW_MODE_MAP.floor_status_mode ? totalCount : totalExpeditedCount}
        </p>
      </div>
      <div className={`${Style.flex_row} ${Style.information_status_tile_list}`}>
        {!!timeExceededCriticalCount && (
          <StatusTile
            active={selectedMetricType === STATUS_TITLE_MAP.idleCritical}
            title={STATUS_TITLE_MAP.idleCritical}
            subTitle={timeExceededCriticalCount}
            color={STATUS_COLOR_MAP.Critical}
            onClick={() => {
              setSelectedMetricType(STATUS_TITLE_MAP.idleCritical);
            }}
          />
        )}
        {!!lateToStartCriticalCount && (
          <StatusTile
            active={selectedMetricType === STATUS_TITLE_MAP.lateToStartCritical}
            title={STATUS_TITLE_MAP.lateToStartCritical}
            subTitle={lateToStartCriticalCount}
            color={STATUS_COLOR_MAP.Critical}
            onClick={() => {
              setSelectedMetricType(STATUS_TITLE_MAP.lateToStartCritical);
            }}
          />
        )}
        {!!timeExceededWarningCount && (
          <StatusTile
            active={selectedMetricType === STATUS_TITLE_MAP.idleWarning}
            title={STATUS_TITLE_MAP.idleWarning}
            subTitle={timeExceededWarningCount}
            color={STATUS_COLOR_MAP.Warning}
            onClick={() => {
              setSelectedMetricType(STATUS_TITLE_MAP.idleWarning);
            }}
          />
        )}
        {!!lateToStartWarningCount && (
          <StatusTile
            active={selectedMetricType === STATUS_TITLE_MAP.lateToStartWarning}
            title={STATUS_TITLE_MAP.lateToStartWarning}
            subTitle={lateToStartWarningCount}
            color={STATUS_COLOR_MAP.Warning}
            onClick={() => {
              setSelectedMetricType(STATUS_TITLE_MAP.lateToStartWarning);
            }}
          />
        )}
        {viewMode === VIEW_MODE_MAP.expedited_parts_mode && !!expeditedHealthyCount && (
          <StatusTile
            active={selectedMetricType === STATUS_TITLE_MAP.healthy}
            title={STATUS_TITLE_MAP.healthy}
            subTitle={expeditedHealthyCount}
            color={STATUS_COLOR_MAP.Healthy}
            onClick={() => {
              setSelectedMetricType(STATUS_TITLE_MAP.healthy);
            }}
          />
        )}
      </div>
      <div className={`${Style.flex_column} ${Style.information_table}`}>
        {isWorkOrdersFilteredByLocationAndMetricsLoading ? (
          <LoadingTable />
        ) : sortedWorkOrdersFilteredByLocationAndMetrics.length ? (
          <PaginatedListTable
            paginatorLocation="top"
            numItemsPerPage={workOrderTableNumItemsPerPage}
            header={workOrderTable}
            headerContainerClassName={TableStyle.table_header}
            renderHeader={(eachHeader) => {
              return getSortableHeader(eachHeader, {
                headerContainerClass: `${TableStyle.table_column}`,
                className: TableStyle.table_header_text
              });
            }}
            data={sortedWorkOrdersFilteredByLocationAndMetrics}
            renderItem={(eachItem) => {
              return (
                <WorkOrderTableRow
                  key={eachItem.id}
                  workOrderTableConfig={workOrderTable}
                  workOrderData={eachItem}
                  onMouseEnter={() => {
                    mapRef.current.hoverLocation(eachItem.lastDetectedLocationId);
                  }}
                  onMouseLeave={() => {
                    mapRef.current.resetHover();
                  }}
                />
              );
            }}
          />
        ) : (
          <div className={TableStyle.no_result_message}>No work order information available</div>
        )}
      </div>
    </>
  );
};
