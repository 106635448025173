// import './mocks/axios-interceptor';
import "babel-polyfill"; // IE11 Compatibility Import for 'Promise' and 'Object'
import React from "react";
import ReactDOM from "react-dom";
import "font-awesome/css/font-awesome.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import "./index.css";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import LoginPage from "./pages/login";
import "mapbox-gl/dist/mapbox-gl.css";
import LoadingCircle from "./components/loading/LoadingCircle";
import MainApp from "./MainApp";
import useAuthenticationContext, { AuthenticationContextProvider } from "./context/authentication-context";

const queryClient = new QueryClient();

const App = () => {
  const { isLoading, isAuthenticated } = useAuthenticationContext();
  if (isLoading) {
    return <LoadingCircle />;
  }
  return (
    <>
      <Route
        exact
        path="/login"
        component={LoginPage}
      />
      {isAuthenticated && (
        <Route
          path="/"
          component={MainApp}
        />
      )}
    </>
  );
};

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <Router>
      <AuthenticationContextProvider>
        <App />
      </AuthenticationContextProvider>
    </Router>
  </QueryClientProvider>,
  document.getElementById("root")
);
