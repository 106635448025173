import React, { Fragment, useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { ModalForm } from "../../../../components/modal-form";
import { DeleteLocationFormFooter } from "./delete-location-form-footer";
import { useXemelgoClient } from "../../../../services/xemelgo-service";
import Loading from "../../../../components/loading/LoadingWord";

export const DeleteLocationForm = ({ show, onSubmit, location, onCancel }) => {
  const [showDialog, setShowDialog] = useState(show);
  const [locationName, setLocationName] = useState("location");
  const [locationId, setLocationId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [xemelgoClient] = useState(useXemelgoClient());

  useEffect(() => {
    let cancelled = false;
    const cancelCallback = () => {
      cancelled = true;
    };

    const { id, name } = location;

    if (!cancelled) {
      setLocationName(name);
      setLocationId(id);
    }

    return cancelCallback;
  }, [location]);

  const onSubmitCallback = useCallback(() => {
    setLoading(true);
    const locationClient = xemelgoClient.getLocationClient();
    locationClient.removeLocation(locationId).then(() => {
      setLoading(false);
      setShowDialog(false);
      onSubmit(locationId);
    });
  }, [onSubmit, xemelgoClient, locationId]);

  const onCancelCallback = useCallback(() => {
    setShowDialog(false);
    onCancel(locationId);
  }, [onCancel, locationId]);

  return (
    <>
      {loading && <Loading />}
      <ModalForm
        show={showDialog}
        title={`Delete ${locationName}?`}
        body={`You're about to delete ${locationName}, are you sure you want to do this?`}
        footer={
          <DeleteLocationFormFooter
            onSubmit={onSubmitCallback}
            onCancel={onCancelCallback}
          />
        }
      />
    </>
  );
};

DeleteLocationForm.defaultProps = {
  show: false,
  onSubmit: () => {},
  onCancel: () => {}
};

DeleteLocationForm.propTypes = {
  location: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }).isRequired,
  show: PropTypes.bool,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func
};
