import React, { useState, useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import useDebounce from "hooks/use-debounce";
import Skeleton from "react-loading-skeleton";
import SearchDropdown from "../SearchDropdown/SearchDropdown";
import SearchDropdownStyle from "./AddInventoryV2Components.module.css";

const Selector = ({
  disableTabFocus,
  options,
  loading,
  onSelect,
  onDebounce,
  debounceInput,
  value,
  placeholder,
  error
}) => {
  const [input, setInput] = useState("");
  const debouncedString = useDebounce(input, 250);
  useEffect(() => {
    if (onDebounce) {
      onDebounce(debouncedString);
    }
  }, [debouncedString]);
  const filteredOptions = useMemo(() => {
    return debounceInput
      ? options
      : options.filter((option) => {
          return option.label.toLowerCase().includes(input);
        });
  }, [options, input]);

  const renderSkeleton = () => {
    const skeletonsToRender = [];
    for (let index = 0; index < 3; index++) {
      skeletonsToRender.push(<Skeleton key={index} />);
    }
    return skeletonsToRender;
  };

  return (
    <div style={{ width: "235px" }}>
      <SearchDropdown
        disableTabFocus={disableTabFocus}
        options={filteredOptions}
        selectedItem={value}
        onItemSelected={onSelect}
        error={error}
        inputValue={input}
        onChangeText={setInput}
        showIcon
        placeholder={placeholder}
        renderContent={(data, blurFunction) => {
          if (loading) {
            return <div style={{ padding: "10px 20px" }}>{renderSkeleton()}</div>;
          }
          if (data.length) {
            return data.map((item, index) => {
              const { id, label } = item;
              return (
                // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                <li
                  key={`${id}_${index}`}
                  className={SearchDropdownStyle.dropdown_item}
                  onClick={() => {
                    onSelect(item);
                    blurFunction();
                  }}
                >
                  <pre className={SearchDropdownStyle.dropdown_label_container}>{label}</pre>
                </li>
              );
            });
          }
          return (
            <li className={SearchDropdownStyle.dropdown_no_result_message}>
              {(!input || input.length < 3) && debounceInput
                ? `Please enter at least 3 characters to search`
                : "No matching results"}
            </li>
          );
        }}
      />
    </div>
  );
};

Selector.defaultProps = {
  disableTabFocus: false,
  options: [],
  onSelect: () => {},
  placeholder: "",
  error: false,
  onDebounce: () => {},
  debounceInput: null,
  loading: false
};

Selector.propTypes = {
  disableTabFocus: PropTypes.bool,
  options: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  value: PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.any
  }),
  placeholder: PropTypes.string,
  error: PropTypes.bool,
  onDebounce: PropTypes.func,
  debounceInput: PropTypes.string,
  loading: PropTypes.bool
};

export default Selector;
