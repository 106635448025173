import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { ReactComponent as AddFileIcon } from "../../../../assets/icons/add-file.svg";
import xemelgoStyle from "../../../../styles/variable";
import Style from "./FileDropOffSection.module.css";
import validateCsvHeaders from "../../utils/validate-csv-headers";
import validateCsvData from "../../utils/validate-csv-data";
import { useXemelgoClient } from "../../../../services/xemelgo-service";

export const FileDropOffSection = ({
  csvHeadersMap,
  onUploadProgressChange,
  onFileUpload,
  onError,
  onInvalidDataInput,
  onValidDataInput
}) => {
  const xemelgoClient = useXemelgoClient();

  const [isDragging, setIsDragging] = useState(false);
  const fileInputRef = useRef(null);

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const onFileDrop = (event) => {
    event.preventDefault();
    setIsDragging(false);
    const droppedFile = event.dataTransfer.files[0];
    handleFileInput(droppedFile);
  };

  const onFileBrowse = (event) => {
    const file = event.target.files[0];
    handleFileInput(file);
  };

  const handleFileInput = (file) => {
    const headersToIdMap = {};
    Object.keys(csvHeadersMap).forEach((id) => {
      headersToIdMap[csvHeadersMap[id].label] = id;
    });

    if (file) {
      const reader = new FileReader();
      // Update progress as the file is being read
      reader.onprogress = (event) => {
        if (event.lengthComputable) {
          const percentage = Math.floor((event.loaded / event.total) * 90);
          onUploadProgressChange(percentage);
        }
      };

      // validate headers and data
      reader.onload = async () => {
        try {
          const { result } = reader;
          const allRows = result.split(/\r|\n/).filter((each) => {
            return each;
          });
          // remove possible encoded characters when reading from file and the asterisk from the header that added for required headers
          const headers = allRows[0].replace("ï»¿", "").replace(/\*/g, "").split(",");
          const { valid: isCsvHeadersValid, errorMessage } = validateCsvHeaders(headers, csvHeadersMap);

          // continue validating when headers are valid
          if (isCsvHeadersValid) {
            // looping through each row except the header and return an array of properties
            const newCsvData = allRows.slice(1, allRows.length).reduce((accumulator, eachRow) => {
              const columns = eachRow.replace('"', "").split(",");
              accumulator.push(
                columns.reduce((accu, eachData, index) => {
                  const headerId = headersToIdMap[headers[index]];
                  if (headerId) {
                    accu[headerId] = eachData && eachData !== "-" ? eachData : undefined;
                  }
                  return accu;
                }, {})
              );
              return accumulator;
            }, []);

            const {
              valid: isDataValid,
              numberOfInvalidItems,
              validatedData
            } = await validateCsvData(newCsvData, csvHeadersMap, xemelgoClient);

            // show resolve error modal when data is not valid
            if (!isDataValid) {
              onInvalidDataInput(numberOfInvalidItems, validatedData);
              // setCsvData(validatedData);
            } else {
              // return validated to the callback
              onValidDataInput(validatedData);
            }
          } else {
            onError(errorMessage);
          }
        } catch (e) {
          onError("Cannot read uploaded file. Please try again");
        }
        onUploadProgressChange(100);
      };
      reader.readAsBinaryString(file);
    }

    onFileUpload(file);
  };

  return (
    <div
      className={isDragging ? Style.file_drop_container_dragging : Style.file_drop_container}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={onFileDrop}
    >
      <AddFileIcon />
      <p className={`${xemelgoStyle.subtextBold}`}>Drag and Drop File Here to Upload</p>
      <p className={`${xemelgoStyle.subtextBold}`}>or</p>
      <input
        type="file"
        style={{ display: "none" }}
        onChange={onFileBrowse}
        ref={fileInputRef}
        accept=".csv"
      />
      <button
        type="button"
        className={Style.button}
        onClick={() => {
          if (fileInputRef.current) {
            fileInputRef.current.click();
          }
        }}
      >
        Browse
      </button>
      <p className={`${xemelgoStyle.regularText}`}>Please upload an attachment with .csv file extension</p>
    </div>
  );
};

FileDropOffSection.propTypes = {
  csvHeadersMap: PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      isRequired: PropTypes.bool.isRequired,
      type: PropTypes.string,
      hidden: PropTypes.bool
    })
  ).isRequired,
  onUploadProgressChange: PropTypes.func.isRequired,
  onFileUpload: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  onInvalidDataInput: PropTypes.func.isRequired,
  onValidDataInput: PropTypes.func.isRequired
};
