import React, { useMemo } from "react";
import Style from "./ConfirmItemsList.module.css";

export const ConfirmItemsList = ({ itemNames, solutionDisplayName }) => {
  const rows = useMemo(() => {
    const rows = [];
    for (let i = 0; i < itemNames.length; i += 2) {
      rows.push(itemNames.slice(i, Math.min(i + 2, itemNames.length)));
    }
    return rows;
  }, [itemNames]);

  return (
    <>
      <p className={Style.list_title}>{`List of ${solutionDisplayName}s:`}</p>
      <div className={`${rows.length > 5 ? Style.list_scroll : ""}`}>
        {rows.map((row, index) => (
          <div
            key={index}
            className={`${Style.list_row} ${index < rows.length - 1 ? Style.list_row_underline : ""}`}
          >
            {row.map((item) => (
              <div
                key={item}
                className={Style.list_element}
              >
                {item.length > 17 ? `${item.substring(0, 17)}...` : item}
              </div>
            ))}
          </div>
        ))}
      </div>
    </>
  );
};
