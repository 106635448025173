import React, { useCallback, useState } from "react";
import { MDBInput } from "mdbreact";
import PropTypes from "prop-types";
import { useStore } from "react-hookstore";
import PasswordInputFormWithValidation from "../../../features/password-form-with-input-validation/PasswordInputFormWithValidation";
import { loggingUserStore } from "../../../state-managements/stores/login-store";

const ForgotPasswordVerificationForm = ({ logo, onSubmit }) => {
  const [loggingUser] = useStore(loggingUserStore);
  const [errorMessage, setErrorMessage] = useState("");
  const [verificationCode, setVerificationCode] = useState("");

  const onVerificationCodeChange = (event) => {
    const veriCode = event.target.value;
    setVerificationCode(veriCode);
  };

  const onSubmit_ = useCallback(
    async (newPassword_) => {
      try {
        await onSubmit(loggingUser, verificationCode, newPassword_);
      } catch (error) {
        setErrorMessage(error.message);
      }
    },
    [loggingUser, verificationCode, onSubmit]
  );

  return (
    <div className="content-body login-page-body">
      <div
        className="container login-form"
        style={{ textAlign: "initial" }}
      >
        <form className="login-form-background">
          <img
            src={logo}
            className="brand-logo"
            alt="brand-logo"
          />
          <div className="form-group">
            <p
              style={{
                textAlign: "center",
                color: "white",
                marginBottom: 20
              }}
            >
              Check your email and Please enter the verification code you received along with a new password.
            </p>
            <div className="error_message">{errorMessage}</div>
            <MDBInput
              className="input-default"
              label="Verification Code"
              value={verificationCode}
              id="verificationCode"
              onInput={onVerificationCodeChange}
            />
            <PasswordInputFormWithValidation
              onSubmit={onSubmit_}
              submitButtonText="Submit"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

ForgotPasswordVerificationForm.propTypes = {
  logo: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export { ForgotPasswordVerificationForm };
