import React from "react";
import PropTypes from "prop-types";
import GridCardContentDefaultStyle from "./GridCardContentDefault.module.css";
import { getFormattedDate } from "../../../../../common/Utilities";

const GridCardContentDefault = ({
  image,
  showStatusFlags,
  title = {},
  subTitle = {},
  statusFlags = [],
  bottomContentList = []
}) => {
  const renderStatus = () => {
    return (
      <div className={GridCardContentDefaultStyle.status_group}>
        {statusFlags &&
          statusFlags.map((eachFlag, index) => {
            return [
              <p
                key={eachFlag.id}
                className={GridCardContentDefaultStyle.status_style}
                style={{ color: eachFlag.color }}
              >
                {eachFlag.displayText}
              </p>,
              statusFlags[index + 1] && (
                <p
                  key={`separator-${eachFlag.id}`}
                  className={GridCardContentDefaultStyle.status_style}
                  style={{ color: "#000000" }}
                >
                  -
                </p>
              )
            ];
          })}
      </div>
    );
  };

  const renderSubtitleContainer = () => {
    return (
      <div className={GridCardContentDefaultStyle.sub_title_container}>
        {subTitle.label && <p className={GridCardContentDefaultStyle.sub_title_label}>{subTitle.label}</p>}
        <p className={GridCardContentDefaultStyle.sub_title}>{subTitle.value || subTitle.defaultValue || "-"}</p>
      </div>
    );
  };

  const renderTitleContainer = () => {
    return (
      <div
        title={`${title.level ? `${title.level}: ` : ""}${title.value}`}
        className={GridCardContentDefaultStyle.title_container}
      >
        {title.label && <div className={GridCardContentDefaultStyle.top_title_label}>{title.label}</div>}
        <div className={GridCardContentDefaultStyle.top_title}>{title.value}</div>
      </div>
    );
  };

  return (
    <div className={GridCardContentDefaultStyle.container}>
      <div className={GridCardContentDefaultStyle.top_section}>
        <img
          className={GridCardContentDefaultStyle.image}
          src={image}
          alt="failed to load img"
        />
        <div className={GridCardContentDefaultStyle.top_left_container}>
          {showStatusFlags && renderStatus()}
          {title && title.value && renderTitleContainer()}
          {subTitle && subTitle.value && renderSubtitleContainer()}
        </div>
      </div>
      <div className={GridCardContentDefaultStyle.bottom_section}>
        {bottomContentList.map((eachContent, i) => {
          switch (eachContent.type) {
            case "text":
              return (
                <div
                  className={GridCardContentDefaultStyle.bottom_data_group}
                  key={i}
                >
                  {`${eachContent.label || ""}`}
                  <p className={GridCardContentDefaultStyle.bottom_data_content}>
                    {`${eachContent.value || eachContent.defaultValue || "-"}`}
                  </p>
                </div>
              );
            case "date":
              return (
                <div
                  className={GridCardContentDefaultStyle.bottom_data_group}
                  key={i}
                >
                  {`${eachContent.label || ""}`}
                  <p className={GridCardContentDefaultStyle.bottom_data_content}>
                    {`${
                      eachContent.value
                        ? getFormattedDate(eachContent.value, eachContent.format || "MMM DD, YYYY")
                        : eachContent.defaultValue
                    }`}
                  </p>
                </div>
              );
            default:
              return (
                <div
                  className={GridCardContentDefaultStyle.bottom_data_group}
                  key={i}
                >
                  {`${eachContent.label || ""}`}
                  <p className={GridCardContentDefaultStyle.bottom_data_content}>
                    {`${eachContent.value || eachContent.defaultValue || "-"}`}
                  </p>
                </div>
              );
          }
        })}
      </div>
    </div>
  );
};

export default GridCardContentDefault;

GridCardContentDefault.propTypes = {
  statusFlags: PropTypes.arrayOf(PropTypes.object).isRequired,
  showStatusFlags: PropTypes.bool,
  title: PropTypes.object,
  subTitle: PropTypes.object,
  bottomContentList: PropTypes.arrayOf(PropTypes.object),
  image: PropTypes.string.isRequired
};
