import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { SOLUTION_ID } from "../../../../../../constants/tabConfigConstants";
import Style from "./AssetBulkCreateTabFeature.module.css";
import { useAddAssetFeatureConfigContext } from "../../contexts/add-asset-feature-config-context";
import UploadCsvComponent from "../../../../../../components/upload-csv-component";
import PrinterSelectionComponent from "../../../../../../components/printer-selection-component";
import { useAddAssetFeatureStateContext } from "../../contexts/add-asset-feature-state-context";
import { STATUS_OPTIONS } from "../../../../../../components/status-popup-component";
import { PRINT_TYPES } from '../../../../../../data/constants';

export const AssetBulkCreateTabFeature = ({ printService }) => {
  const { formFields, isPrintEnabled, bartenderConfig, printType } = useAddAssetFeatureConfigContext();
  const { setFormDataList, formDataList, setIsSubmitDisabled, submitStatus } = useAddAssetFeatureStateContext();
  const { selectedPrinterInfo, onSelectPrinter, printers, isLoading, printerErrorMessage } = printService || {};
  const { endpoint: bartenderUrl } = bartenderConfig;

  const csvHeadersMap = useMemo(() => {
    const newCsvHeadersMap = Object.values(formFields).reduce((tempCsvHeadersMap, field) => {
      const clonedCsvHeadersMap = { ...tempCsvHeadersMap };
      const { id, isDisabled, isHidden } = field;
      if (id && !isDisabled && !isHidden) {
        clonedCsvHeadersMap[id] = { ...field };
      }
      return clonedCsvHeadersMap;
    }, {});

    return newCsvHeadersMap;
  }, [formFields]);

  useEffect(() => {
    const isPrinterSelected = Object.keys(selectedPrinterInfo).length > 0;
    const isBartenderPrint = Boolean(bartenderUrl);
    const isPrinterOnline = selectedPrinterInfo?.message?.value === "Online";

    const isPrinterReady = isPrinterSelected && (isBartenderPrint || isPrinterOnline);

    if ((isPrintEnabled && !isPrinterReady) || !formDataList.length || submitStatus === STATUS_OPTIONS.LOADING) {
      setIsSubmitDisabled(true);
    } else {
      setIsSubmitDisabled(false);
    }
  }, [formDataList, submitStatus, selectedPrinterInfo]);

  return (
    <div className={Style.bulk_create_container}>
      <div className={Style.upload_container}>
        <UploadCsvComponent
          key={String(submitStatus)}
          csvHeadersMap={csvHeadersMap}
          onFileInput={setFormDataList}
          solution={SOLUTION_ID.ASSET}
        />
      </div>
      {isPrintEnabled && printType !== PRINT_TYPES.BARTENDER_UPLOAD && (
        <div className={Style.printer_options_container}>
          <PrinterSelectionComponent
            isLoading={isLoading}
            printerList={printers}
            selectedPrinterInfo={selectedPrinterInfo}
            onPrinterSelect={onSelectPrinter}
            errorMessage={printerErrorMessage}
          />
        </div>
      )}
    </div>
  );
};

AssetBulkCreateTabFeature.propTypes = {
  printService: PropTypes.shape({
    selectedPrinterInfo: PropTypes.shape({}).isRequired,
    onSelectPrinter: PropTypes.func.isRequired,
    printers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    isLoading: PropTypes.bool.isRequired,
    printerErrorMessage: PropTypes.string.isRequired
  }).isRequired
};
