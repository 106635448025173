import React, { useState } from "react";
import PropTypes from "prop-types";

import { PartnerGroupHeader } from "./partner-group-header/PartnerGroupHeader";
import { Div } from "../../../../components/div";
import { CollapsibleListView } from "../../../../components/collapsible-list-view";
import { PartnerRows } from "./partner-rows/PartnerRows";
import TextFilterInputComponent from "../../../../components/text-filter-input-component/TextFilterInputComponent";
import { getPartnerDisplayValue } from "../../ListPartnersUtil";

import "./style.css";

export const ListPartnerGroupsPanel = ({ partners, onPartnerSelected, onAddClicked, focus, displayProperty }) => {
  const [filteredPartners, setFilteredPartners] = useState(partners);

  const trimAndLower = (text) => {
    return text.toLowerCase().trim();
  };

  const filterPartners = (filterText) => {
    const newFilterestList = partners.filter((p) => {
      return trimAndLower(getPartnerDisplayValue(p, displayProperty)).includes(trimAndLower(filterText));
    });

    setFilteredPartners(newFilterestList);
  };

  return (
    <Div className="my-facility-partners">
      <Div className="my-facility-partners-title-section">
        <Div className="my-facility-partners-title-header">Customers</Div>
        <Div className="my-facility-partners-title-subtitle">Set up and edit the structure of your organization</Div>
      </Div>
      <TextFilterInputComponent
        placeholder="Type to Filter"
        onChange={filterPartners}
      />
      <Div>
        <CollapsibleListView
          className="category-detail-view"
          title={
            // eslint-disable-next-line react/jsx-wrap-multilines
            <PartnerGroupHeader
              groupName="Customers"
              resourceCount={partners.length}
              onAddClick={onAddClicked}
            />
          }
          content={
            <PartnerRows
              partners={filteredPartners}
              onPartnerSelected={onPartnerSelected}
              focus={focus}
              displayProperty={displayProperty}
            />
          }
        />
      </Div>
    </Div>
  );
};

ListPartnerGroupsPanel.defaultProps = {
  focus: false
};

ListPartnerGroupsPanel.propTypes = {
  partners: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string,
      identifier: PropTypes.string.isRequired,
      description: PropTypes.string
    })
  ).isRequired,
  onPartnerSelected: PropTypes.func.isRequired,
  onAddClicked: PropTypes.func.isRequired,
  focus: PropTypes.bool
};
