import React, { useState, useMemo, useEffect } from "react";
import { useStore } from "react-hookstore";
import PropTypes from "prop-types";
import Tooltip from "@material-ui/core/Tooltip";
import { Link, useHistory } from "react-router-dom";
import { LocalCacheService } from "services/local-cache-service";
import xemelgoStyle from "../../styles/variable";
import SideNavigationBar from "../side-navigation-bar/SideNavigationBar";
import { getDefaultRoles } from "../../apps/hooks/useRoleAuthorization";
import { ReactComponent as ChevUpIcon } from "../../assets/icons/chev-up.svg";
import { ReactComponent as ChevDownIcon } from "../../assets/icons/chev-down.svg";
import { solutionMenuOptionsFactory, settingsMenuOptionsFactory } from "./tabStructureFactory";
import { userProfileStore } from "../../state-managements/stores/user-profile-store";
import { SOLUTION_ID } from "../../constants/tabConfigConstants";
import SideNavIcon from "./SideNavIcon";
import { useConfigurationProvider } from "../../services/soft-cache-service";
import MainNavigationBarStyle from "./MainNavigationBar.module.css";

const DEFAULT_NESTED_TAB_LIST_COLLAPSED_STATE = true;

const MainNavigationBar = ({ navbarCollapsed, onNavbarCollapse, containerStyle }) => {
  const history = useHistory();
  const customerConfig = useConfigurationProvider().config;
  const [sideNavigationNestedTabListCollapsed, setSideNavigationNestedTabListCollapsed] = useState({});
  const [userProfile] = useStore(userProfileStore);

  useEffect(() => {
    setSideNavigationNestedTabListCollapsed(initTabCollapseState(sideNavigationConfiguration));
  }, [sideNavigationConfiguration]);

  const tabStyleOverride = {
    position: "relative",
    display: "flex",
    alignItems: "center",
    height: "55px",
    width: "150%",
    overflow: "hidden",
    paddingLeft: "25px",
    marginLeft: "-25px"
  };

  const generateIcon = (tabId, displayName, isFocused, route, ref, isParent, tabCollapsed) => {
    const tabTitleStyleOverride = {
      display: navbarCollapsed ? "none" : "block"
    };

    const children = (
      <>
        <Tooltip
          title={displayName}
          placement="bottom-end"
          arrow={false}
          enterDelay={300}
        >
          <SideNavIcon
            appName={tabId}
            isFocused={isFocused}
            classOverride={MainNavigationBarStyle.nav_icon}
          />
        </Tooltip>

        {isParent && tabCollapsed && (
          <div
            className={`
            ${MainNavigationBarStyle.tab_collapse_icon} 
            ${MainNavigationBarStyle.tab_collapse_icon_on_navbar_collapsed}`}
            onClick={(event) => {
              event.stopPropagation();
              onClickNestedTabListCollapseToggle(tabId);
            }}
          >
            {getChevronIcon(tabId, isFocused, tabCollapsed)}
          </div>
        )}

        <div
          className={MainNavigationBarStyle.tab_title}
          style={tabTitleStyleOverride}
        >
          {displayName}
        </div>

        {isParent && !tabCollapsed && (
          <div
            className={`
          ${MainNavigationBarStyle.tab_collapse_icon}
          ${MainNavigationBarStyle.tab_collapse_icon_on_navbar_expanded}
          ${MainNavigationBarStyle.tab_collapse_icon_animation_on_expand}`}
            onClick={(event) => {
              event.stopPropagation();
              onClickNestedTabListCollapseToggle(tabId);
            }}
          >
            {getChevronIcon(tabId, isFocused)}
          </div>
        )}
      </>
    );

    return route ? (
      <Link
        to={route}
        style={tabStyleOverride}
        ref={ref || null}
      >
        {children}
      </Link>
    ) : (
      <div
        style={tabStyleOverride}
        ref={ref || null}
      >
        {children}
      </div>
    );
  };

  const onClickNestedTabListCollapseToggle = (tabId) => {
    const copy = { ...sideNavigationNestedTabListCollapsed };
    copy[tabId] = !copy[tabId];
    LocalCacheService.saveSideNavigationNestedTabVerticalCollapsed(JSON.stringify(copy));
    setSideNavigationNestedTabListCollapsed(copy);
  };

  const getChevronIcon = (appName, isFocused) => {
    const isCollapsed = sideNavigationNestedTabListCollapsed[appName];
    const color = isFocused ? xemelgoStyle.theme.APP_BLUE : xemelgoStyle.theme.APP_SIDENAV_ICON_DARK;

    return isCollapsed ? <ChevDownIcon color={color} /> : <ChevUpIcon color={color} />;
  };

  const attachRenderMethodToTab = (tabOptions) => {
    tabOptions.render = (isFocused, ref = undefined, tabCollapsed = false) => {
      return generateIcon(
        tabOptions.id,
        tabOptions.title,
        isFocused,
        tabOptions.href,
        ref,
        !!tabOptions.children?.length,
        tabCollapsed
      );
    };

    if (tabOptions.children) {
      tabOptions.children.forEach((child) => {
        return attachRenderMethodToTab(child);
      });
    }
  };

  const getAllowedMenuOptions = (options, optionFactory) => {
    return options
      .filter(({ adminOnly, roles = getDefaultRoles(adminOnly) }) => {
        return roles.includes(userProfile.getRole());
      })
      .reduce((acc, { id, title }) => {
        const tabOptions = optionFactory(customerConfig, id, title);

        if (tabOptions) {
          attachRenderMethodToTab(tabOptions);
          acc.push(tabOptions);
        }
        return acc;
      }, []);
  };

  const solutionTabConfiguration = useMemo(() => {
    const { solutionOptions = [] } = customerConfig.webClient;

    return getAllowedMenuOptions(
      [
        ...solutionOptions,
        { id: "asset", title: "Asset" },
        {
          adminOnly: false,
          id: "order",
          title: "Work Orders"
        }
      ],
      solutionMenuOptionsFactory
    ).sort((a, b) => {
      if (a.id === SOLUTION_ID.MANAGEMENT_DASHBOARD) {
        return -1;
      }
      if (b.id === SOLUTION_ID.MANAGEMENT_DASHBOARD) {
        return 1;
      }
      if (a.id === SOLUTION_ID.GOODS_RECEIPT && b.id === SOLUTION_ID.INVENTORY) {
        return 1;
      }
      return a.title.localeCompare(b.title, undefined, {
        numeric: true,
        sensitivity: "base"
      });
    });
  }, [customerConfig, userProfile, getAllowedMenuOptions]);

  const settingTabConfiguration = useMemo(() => {
    const { settingOptions = [] } = customerConfig.webClient;

    return getAllowedMenuOptions(settingOptions, (_, id, title) => {
      return settingsMenuOptionsFactory(id, title);
    }).sort((a, b) => {
      return a.title.localeCompare(b.title, undefined, {
        numeric: true,
        sensitivity: "base"
      });
    });
  }, [customerConfig, userProfile, getAllowedMenuOptions]);

  const sideNavigationConfiguration = useMemo(() => {
    return {
      groups: [
        {
          items: solutionTabConfiguration
        },
        {
          items: settingTabConfiguration
        }
      ]
    };
  }, [solutionTabConfiguration, settingTabConfiguration]);

  return (
    <div className={containerStyle}>
      <SideNavigationBar
        hasCollapseIcon
        containerStyle={containerStyle}
        tabStructure={sideNavigationConfiguration}
        defaultTab={
          resolveDefaultTabFromUrl(history, sideNavigationConfiguration) || history.location.pathname.split("/")[1]
        }
        collapsed={navbarCollapsed}
        onCollapse={onNavbarCollapse}
        nestedTabListCollapsed={sideNavigationNestedTabListCollapsed}
      />
    </div>
  );
};

const getAllTabsFromConfiguration = (items) => {
  return items.reduce((allItems, item) => {
    return allItems.concat([item, ...getAllTabsFromConfiguration(item.children || [])]);
  }, []);
};

const resolveDefaultTabFromUrl = (history, tabConfiguration) => {
  const allRootTabs = tabConfiguration?.groups.reduce((tabs, group) => {
    return tabs.concat(group.items);
  }, []);

  const allTabs = getAllTabsFromConfiguration(allRootTabs);

  let bestMatch;
  for (const tab of allTabs) {
    if (history.location.pathname.includes(tab.href) && (!bestMatch || tab.href.length > bestMatch.href.length)) {
      bestMatch = tab;
    }
  }

  return bestMatch?.id;
};

const initTabCollapseState = (tabStructure) => {
  let tabIdWithNestedChildren = [];
  tabStructure.groups.forEach((group) => {
    tabIdWithNestedChildren = tabIdWithNestedChildren.concat(traverseTabItems(group.items));
  });

  return tabIdWithNestedChildren.reduce((acc, tabId) => {
    const initialCollapsedState = LocalCacheService.getSideNavigationNestedTabVerticalCollapsed(tabId);
    const isTabCollapsed =
      typeof initialCollapsedState === "boolean" ? initialCollapsedState : DEFAULT_NESTED_TAB_LIST_COLLAPSED_STATE;
    acc[tabId] = isTabCollapsed;
    return acc;
  }, {});
};

// Definition: Given a array of tab items, return an array of tabId (included deeply nested tab) of tabs that has children
const traverseTabItems = (items) => {
  let res = [];
  items.forEach((item) => {
    if (item.children) {
      res.push(item.id);
      res = res.concat(traverseTabItems(item.children));
    }
  });

  return res;
};

export default MainNavigationBar;

MainNavigationBar.defaultProps = {
  containerStyle: null,
  onNavbarCollapse: () => {},
  navbarCollapsed: false
};

MainNavigationBar.propTypes = {
  containerStyle: PropTypes.string,
  onNavbarCollapse: PropTypes.func,
  navbarCollapsed: PropTypes.bool
};
