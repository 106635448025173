import React, { useState, useMemo, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import {
  ChevronLeft,
  ChevronRight,
  KeyboardArrowDown,
  ArrowDropUp,
  ArrowDropDown,
  GetApp as ExportIcon
} from "@material-ui/icons";
import { ClipLoader } from "react-spinners";
import ListTable from "../../../../../../components/ListTable/ListTable";
import LoadingCircle from "../../../../../../components/loading/LoadingCircle";
import { exportCsv } from "../../../../../../common/Utilities";
import Style from "./PaginatedListTable.module.css";
import xemelgoStyle from "../../../../../../styles/variable";

const SIZE_MAP = {
  small: 1,
  medium: 2,
  large: 4
};

const useOutsideAlerter = (ref, func) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        func();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
};

const MultiSelectDropdown = ({ title }) => {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, () => {
    setShowDropdown(false);
  });
  const [, setShowDropdown] = useState(false);
  return (
    <div
      className={Style.dropdown_container}
      ref={wrapperRef}
      style={{ marginLeft: "15px" }}
    >
      <div className={Style.dropdown_button}>
        {title}
        <KeyboardArrowDown style={{ color: xemelgoStyle.theme.APP_BLUE }} />
      </div>
    </div>
  );
};

export const PaginatedListTable = ({
  data,
  title,
  filterDisabled,
  filterInput,
  onFilterChange,
  numItemsPerPage,
  renderHeaderProp,
  isLoading,
  isCSVDataLoading,
  csvData,
  ...restProps
}) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(numItemsPerPage);
  const [selectedSortProperty, setSelectedSortProperty] = useState("");
  // sud todo const
  const [sortDirection, setSortDirection] = useState("desc");
  const { pagedItemList, pageStart, pageEnd } = useMemo(() => {
    const pageStart = pageNumber * itemsPerPage - itemsPerPage;
    const pageEnd = pageNumber * itemsPerPage < data.length ? pageNumber * itemsPerPage : data.length;
    return { pagedItemList: data.slice(pageStart, pageEnd), pageStart, pageEnd };
  }, [data, pageNumber, itemsPerPage]);

  // reset page to 1 after filter
  const [prevDataLength, setPrevDataLength] = useState(0);
  useEffect(() => {
    if (data.length !== prevDataLength) {
      setPrevDataLength(data.length);
      setPageNumber(1);
    }
  }, [data]);

  const totalSize = useMemo(() => {
    return restProps.header.reduce((accumulator, header) => {
      return (accumulator += SIZE_MAP[header?.size || "medium"]);
    }, 0);
  }, [restProps.header, SIZE_MAP]);

  const isFirstPage = pageNumber === 1;
  const isLastPage = pageNumber >= Math.ceil(data.length / itemsPerPage);

  const leftButton = isFirstPage
    ? `${Style.table_pager_button} ${Style.disabledButton}`
    : `${Style.table_pager_button} ${Style.enabledButton}`;
  const rightButton = isLastPage
    ? `${Style.table_pager_button} ${Style.disabledButton}`
    : `${Style.table_pager_button} ${Style.enabledButton}`;

  const Paginator = () => {
    const fontWeight = restProps.paginatorFontWeight ?? "normal";
    return (
      <div
        className={`${Style.flex_row} ${Style.table_pager}`}
        style={{ fontWeight }}
      >
        <div>
          {restProps.showRowsPerPage && (
            <div className={`${Style.flex_row}`}>
              <p
                className={Style.table_pager_size_text}
                style={{ fontWeight }}
              >
                {"Rows per page: "}
              </p>
              <select
                className={Style.table_pager_size_selector}
                style={{ fontWeight }}
                value={itemsPerPage}
                onChange={(e) => {
                  setPageNumber(1);
                  setItemsPerPage(e.target.value);
                }}
              >
                <option
                  className={Style.table_pager_size_item}
                  value={10}
                >
                  10
                </option>
                <option
                  className={Style.table_pager_size_item}
                  value={20}
                >
                  20
                </option>
                <option
                  className={Style.table_pager_size_item}
                  value={30}
                >
                  30
                </option>
                <option
                  className={Style.table_pager_size_item}
                  value={40}
                >
                  40
                </option>
                <option
                  className={Style.table_pager_size_item}
                  value={50}
                >
                  50
                </option>
              </select>
            </div>
          )}
        </div>
        <button
          type="button"
          className={leftButton}
          onClick={() => {
            if (!isFirstPage) {
              setPageNumber(pageNumber - 1);
            }
          }}
          disabled={isFirstPage}
        >
          <ChevronLeft />
        </button>
        <p
          className={Style.table_pager_number}
          style={{ fontWeight }}
        >
          {`${data.length ? pageStart + 1 : 0} - ${pageEnd} of ${data.length}`}
        </p>
        <button
          type="button"
          className={rightButton}
          onClick={() => {
            if (!isLastPage) {
              setPageNumber(pageNumber + 1);
            }
          }}
          disabled={isLastPage}
        >
          <ChevronRight />
        </button>
      </div>
    );
  };

  const renderHeader = (header) => {
    const percentage = Math.round((SIZE_MAP[header?.size || "medium"] / totalSize) * 100);
    return (
      <li
        key={header?.label}
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          width: `${percentage}%`,
          paddingRight: 5,
          overflowWrap: "break-word"
        }}
      >
        <p>{header?.label}</p>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <ArrowDropUp
            onClick={() => {
              setSelectedSortProperty(header.id);
              setSortDirection("asc");
            }}
            style={{
              cursor: "pointer",
              color:
                selectedSortProperty === header?.id && sortDirection === "asc"
                  ? xemelgoStyle.theme.APP_BLUE
                  : xemelgoStyle.theme.APP_LIGHTGREY
            }}
          />
          <ArrowDropDown
            onClick={() => {
              setSelectedSortProperty(header.id);
              setSortDirection("desc");
            }}
            style={{
              cursor: "pointer",
              color:
                selectedSortProperty === header?.id && sortDirection === "desc"
                  ? xemelgoStyle.theme.APP_BLUE
                  : xemelgoStyle.theme.APP_LIGHTGREY
            }}
          />
        </div>
      </li>
    );
  };

  const onExportCSVClick = () => {
    const csvHeaders = Object.keys(csvData[0] || {});

    exportCsv(
      csvData,
      {
        header: csvHeaders,
        cellDates: true
      },
      `data.csv`
    );
  };

  return (
    <div
      className={Style.flex_column}
      style={{ width: "100%", alignSelf: "flex-start", height: "100%", gap: 10 }}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <p style={{ fontWeight: "bold" }}>{title}</p>
        {!isLoading && <Paginator />}
      </div>
      {isLoading ? (
        <LoadingCircle />
      ) : (
        <>
          {!filterDisabled && (
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
              <div className={Style.filter_bar_container}>
                <span className={`fa fa-search ${Style.filter_bar_icon}`} />
                <input
                  onChange={({ currentTarget }) => {
                    onFilterChange(currentTarget.value);
                  }}
                  value={filterInput}
                  className={Style.filter_bar}
                  placeholder="Type to Filter"
                />
              </div>
              <MultiSelectDropdown
                title="Columns"
                className={Style.dropdown_button}
              />
              <MultiSelectDropdown
                title="Filters"
                className={Style.dropdown_button}
              />
              <button
                type="button"
                className={Style.export_csv_button}
                onClick={onExportCSVClick}
                disabled={isCSVDataLoading}
              >
                {isCSVDataLoading ? (
                  <ClipLoader
                    color="#ffffff"
                    size={24}
                  />
                ) : (
                  <>
                    <ExportIcon />
                    Export
                  </>
                )}
              </button>
            </div>
          )}
          <div style={{ overflowY: "auto", height: "100%" }}>
            <ListTable
              className={Style.list_table}
              data={pagedItemList}
              renderHeader={renderHeaderProp || renderHeader}
              itemsContainerClassName={Style.item_container}
              {...restProps}
            />
          </div>
        </>
      )}
    </div>
  );
};

PaginatedListTable.defaultProps = {
  data: [],
  numItemsPerPage: 10,
  paginatorLocation: "bottom",
  filterDisabled: false,
  header: [],
  renderHeaderProp: null,
  renderEmptyList: () => {
    return <div className={Style.empty_list_container}>No Matching Results</div>;
  },
  csvData: []
};

PaginatedListTable.propTypes = {
  paginatorLocation: PropTypes.string,
  data: PropTypes.array,
  numItemsPerPage: PropTypes.number,
  csvData: PropTypes.array
};
