import React from "react";
import { Auth, API } from "aws-amplify";
import xemelgoStyle from "../../../../styles/variable";
import exportCSVQueryBatchHelper from "./utils/export-csv-query-batch-helper";

const ONE_DAY = 60 * 60 * 24 * 1000;

export default {
  "heat-map-metrics": async () => {
    const {
      signInUserSession: {
        idToken: { jwtToken }
      }
    } = await Auth.currentAuthenticatedUser();

    const { results } = await API.post("Vallen", "/query?requestType=supplierreport", {
      headers: { Authorization: jwtToken },
      body: {
        reportName: "SupplierReport",
        selectFields: [
          {
            field: "metrics"
          },
          {
            field: "description"
          },
          {
            field: "multi-location opportunties"
          },
          {
            field: "actionable opportunities"
          },
          {
            field: "$ value"
          },
          {
            field: "$ value unique"
          }
        ],
        orderByFields: [
          {
            field: "metrics",
            direction: "desc"
          }
        ],
        filterByFields: [
          {
            field: "report type",
            comparisonType: "=",
            filterValue: "heatmap"
          }
        ],
        limit: 20
      }
    });
    return results.map(
      ({ metrics, description, "actionable opportunities": actionableOpportunities, "$ value": value }) => {
        let formatOptions = {};

        if (
          metrics === "Total Inventory Value" ||
          metrics === "Reduction of Order Mins" ||
          metrics === "Reduction of Order Max" ||
          metrics === "Consignment Eligible"
        ) {
          formatOptions = {
            style: "currency",
            currency: "USD",
            notation: "compact"
          };
        }

        const formatter = new Intl.NumberFormat("en-US", formatOptions);
        const subTitle = formatter.format(value);

        return {
          id: metrics,
          label: metrics,
          subTitle,
          subTitleColor: parseInt(value) > 0 ? xemelgoStyle.theme.STATUS_GREEN : xemelgoStyle.theme.STATUS_RED,
          description
        };
      }
    );
  },
  // needed since the heatMap query is too large
  "heat-map-export-heatMap": async () => {
    const {
      signInUserSession: {
        idToken: { jwtToken }
      }
    } = await Auth.currentAuthenticatedUser();

    const { results } = await API.post("Vallen", "/query?requestType=heatmapreport", {
      headers: { Authorization: jwtToken },
      body: {
        reportName: "HeatMapReport",
        selectFields: [
          {
            field: "whse",
            alias: "Warehouse"
          },
          {
            field: "cust part",
            alias: "Part Number"
          },
          {
            field: "vendor part number"
          },
          {
            field: "vendor name"
          },
          {
            field: "lead time"
          },
          {
            field: "order pt/min"
          },
          {
            field: "order qty"
          },
          {
            field: "line point/max"
          },
          {
            field: "sugg min"
          },
          {
            field: "sugg max"
          },
          {
            field: "bin loc1"
          },
          {
            field: "show"
          },
          {
            field: "max"
          },
          {
            field: "op+20%"
          },
          {
            field: "oh supply weeks"
          },
          {
            field: "cust only"
          },
          {
            field: "bor"
          }
        ],
        orderByFields: [
          {
            field: "order qty",
            direction: "asc"
          }
        ],
        filterByFields: [],
        limit: 10000
      }
    });

    return results;
  },
  "heat-map-table-heatMap": async () => {
    const {
      signInUserSession: {
        idToken: { jwtToken }
      }
    } = await Auth.currentAuthenticatedUser();

    const { results } = await API.post("Vallen", "/query?requestType=heatmapreport", {
      headers: { Authorization: jwtToken },
      body: {
        reportName: "HeatMapReport",
        selectFields: [
          {
            field: "whse",
            alias: "Warehouse"
          },
          {
            field: "cust part",
            alias: "Part Number"
          },
          {
            field: "critical",
            alias: "Critical"
          },
          {
            field: "description1",
            alias: "Description"
          },
          {
            field: "stock uom",
            alias: "Stock UOM"
          },
          {
            field: "status",
            alias: "Status"
          },
          {
            field: "total qty oh",
            alias: "Qty On Hand"
          },
          {
            field: "qty on order",
            alias: "Qty Ordered"
          },
          {
            field: "projected exhaustion date",
            alias: "Projected Exhaustion Date"
          },
          {
            field: "next replenishment",
            alias: "Promise Date"
          },
          {
            field: "below order point"
          },
          {
            field: "within parameters, no risk"
          },
          {
            field: "at or within 20% of order point"
          },
          {
            field: "at risk of stockout"
          },
          {
            field: "vendor part number"
          }
        ],
        orderByFields: [
          {
            field: "order qty",
            direction: "asc"
          }
        ],
        filterByFields: [],
        limit: 10000
      }
    });

    return results;
  },
  "heat-map-table-heatMap-export-csv": async () => {
    const {
      signInUserSession: {
        idToken: { jwtToken }
      }
    } = await Auth.currentAuthenticatedUser();

    return exportCSVQueryBatchHelper((offset, limit) => {
      return API.post("Vallen", "/query?requestType=query", {
        headers: { Authorization: jwtToken },
        body: {
          query: `SELECT * FROM "AwsDataCatalog"."controltower-2"."heatmap_latest" ORDER BY "cust part" DESC OFFSET ${offset} rows LIMIT ${limit}`
        }
      });
    });
  },
  "heat-map-table-minMax": async () => {
    const {
      signInUserSession: {
        idToken: { jwtToken }
      }
    } = await Auth.currentAuthenticatedUser();

    const { results } = await API.post("Vallen", "/query?requestType=MinMaxReport", {
      headers: { Authorization: jwtToken },
      body: {
        reportName: "MinMaxReport",
        selectFields: [
          { field: "suggested min", alias: "Suggested Min" },
          { field: "suggested max", alias: "Suggested Max" },
          { field: "current min", alias: "Current Min" },
          { field: "current max", alias: "Current Max" },
          { field: "sugg oq diff", alias: "Suggested OQ Diff Value" },
          { field: "cust part", alias: "Part Number" },
          { field: "cust avg cost", alias: "Average Cost" },
          { field: "status", alias: "Status" },
          { field: "critical", alias: "Critical" },
          { field: "stock uom", alias: "Stock UOM" },
          { field: "description1", alias: "Description" },
          { field: "inv value of increased turns to add consignment" },
          { field: "min wks usage" },
          { field: "max weeks usage" },
          { field: "avg wk usage (12 mos)" },
          { field: "avg wk usage (6 mos)" },
          { field: "std-pkg" },
          { field: "min std pck?" },
          { field: "last 6 mos" },
          { field: "average lead time" },
          { field: "stockout list?" },
          { field: "cost" },
          { field: "current annual turns" },
          { field: "new annual turns" },
          { field: "current oq based on std pk?" },
          { field: "current oq value" },
          { field: "min stock over-ride" },
          { field: "max stock over-ride" },
          { field: "total qty oh" },
          { field: "order pt/min" },
          { field: "line point/max" },
          { field: "lead time" },
          { field: "bor" }
        ],
        orderByFields: [],
        filterByFields: [],
        groupByFields: [],
        limit: 10000
      }
    });

    return results;
  },
  "heat-map-table-openOrders": async () => {
    const {
      signInUserSession: {
        idToken: { jwtToken }
      }
    } = await Auth.currentAuthenticatedUser();

    const { results } = await API.post("Vallen", "/query?requestType=OpenOrdersReport", {
      headers: { Authorization: jwtToken },
      body: {
        reportName: "OpenOrdersReport",
        selectFields: [
          {
            field: "cust part number",
            alias: "Part Number"
          },
          {
            field: "po number",
            alias: "PO Number"
          },
          {
            field: "erp part number",
            alias: "ERP Part Number"
          },
          {
            field: "ord qty",
            alias: "Order Quantity"
          },
          {
            field: "order date",
            alias: "Order Date"
          },
          {
            field: "due date",
            alias: "Promise Date"
          },
          {
            field: "confirmed",
            alias: "Confirmed"
          },
          {
            field: "description",
            alias: "Description"
          },
          {
            field: "vendor",
            alias: "Vendor"
          },
          {
            field: "critical",
            alias: "Critical"
          },
          {
            field: "status",
            alias: "Status"
          }
        ],
        orderByFields: [
          {
            field: "order date",
            direction: "asc"
          }
        ],
        filterByFields: [],
        limit: 10000
      }
    });

    return results;
  },
  "heat-map-table-critical": async () => {
    const {
      signInUserSession: {
        idToken: { jwtToken }
      }
    } = await Auth.currentAuthenticatedUser();

    const { results } = await API.post("Vallen", "/query?requestType=CriticalReport", {
      headers: { Authorization: jwtToken },
      body: {
        reportName: "CriticalReport",
        selectFields: [
          { field: "cust part num", alias: "Part Number" },
          { field: "description", alias: "Description" },
          { field: " total qoh", alias: "Qty On-Hand" },
          { field: "weeks of supply", alias: "Weeks of Supply" },
          { field: "lead time (days)", alias: "Lead Time (Days)" },
          { field: "projected zero stock", alias: "Projected Zero Stock" },
          { field: "min order point" },
          { field: "order qty" },
          { field: "vendor" },
          { field: "vendor #" },
          { field: "vendor pn" },
          { field: "bor" },
          { field: "perm c" }
        ],
        orderByFields: [
          {
            field: "cust part num",
            direction: "asc"
          }
        ],
        filterByFields: [],
        limit: 10000
      }
    });

    return results;
  },
  "heat-map-table-nonCritical": async () => {
    const {
      signInUserSession: {
        idToken: { jwtToken }
      }
    } = await Auth.currentAuthenticatedUser();

    const { results } = await API.post("Vallen", "/query?requestType=NonCriticalReport", {
      headers: { Authorization: jwtToken },
      body: {
        reportName: "NonCriticalReport",
        selectFields: [
          { field: "cust part num", alias: "Part Number" },
          { field: "description", alias: "Description" },
          { field: " total qoh", alias: "Qty On-Hand" },
          { field: "weeks of supply", alias: "Weeks of Supply" },
          { field: "lead time (days)", alias: "Lead Time (Days)" },
          { field: "projected zero stock", alias: "Projected Zero Stock" },
          { field: "min order point" },
          { field: "order qty" },
          { field: "vendor" },
          { field: "vendor #" },
          { field: "vendor pn" },
          { field: "bor" },
          { field: "perm c" }
        ],
        orderByFields: [
          {
            field: "cust part num",
            direction: "asc"
          }
        ],
        filterByFields: [],
        limit: 10000
      }
    });

    return results;
  },
  "strategic-sourcing-metrics": async () => {
    const descriptionMap = {
      "Obsolete Material on Order": "Open PO(s) for material with a 999 Product Line",
      "EB Conversion Potential": "EB part number alternative available",
      "Potential Core Supplier Buy":
        "Based on description and/or past ERP purchases these material have the potential to buy from a Core Supplier",
      "Site Excess Material on Order":
        "Material on order has an Inventory Reserve hit on the P&L and/or is marked with an OAN status with a quantity on hand",
      "Material Sharing Opportunity":
        "Material on order either has an Inventory Reserve or is marked with and OAN status with quantity available"
    };

    const reversedStatusMap = {
      "Obsolete Material on Order": true,
      "Site Excess Material on Order": true,
      "Material Sharing Opportunity": true
    };

    const {
      signInUserSession: {
        idToken: { jwtToken }
      }
    } = await Auth.currentAuthenticatedUser();

    const { results } = await API.post("Vallen", "/query?requestType=supplierreport", {
      headers: { Authorization: jwtToken },
      body: {
        reportName: "SupplierReport",
        selectFields: [
          {
            field: "metrics"
          },
          {
            field: "cur actionable opportunities"
          },
          {
            field: "cur value"
          },

          {
            field: "value delta %"
          },
          { field: "description" }
        ],
        orderByFields: [
          {
            field: "value delta",
            direction: "desc"
          }
        ],
        filterByFields: [
          {
            field: "report type",
            comparisonType: "=",
            filterValue: "supplier"
          },
          {
            field: "days",
            comparisonType: "=",
            filterValue: "2"
          }
        ],
        limit: 20
      }
    });
    return results.map(
      ({
        metrics,
        description,
        "cur actionable opportunities": actionableOpportunities,
        "cur value": value,
        "value delta %": percentChange
      }) => {
        return {
          id: metrics,
          label: metrics,
          subTitle: (
            <>
              {Intl.NumberFormat("en-US").format(parseInt(actionableOpportunities).toFixed(0).toString())}
              <span style={{ fontSize: "17px", marginLeft: "8px" }}>
                {`($${Intl.NumberFormat("en", {
                  notation: "compact"
                }).format(parseInt(value))})`}
              </span>
            </>
          ),
          description: descriptionMap[metrics] || description,
          percentChange: parseFloat(percentChange).toFixed(2).toString(),
          status: reversedStatusMap[metrics]
            ? percentChange?.includes("-")
              ? "healthy"
              : "critical"
            : percentChange?.includes("-")
            ? "critical"
            : "healthy"
        };
      }
    );
  },
  "strategic-sourcing-table-Site Excess Material on Order": async () => {
    const {
      signInUserSession: {
        idToken: { jwtToken }
      }
    } = await Auth.currentAuthenticatedUser();

    let finalResult = [];
    let index = 0;

    while (!finalResult?.length) {
      const { results } = await API.post("Vallen", "/query?requestType=excessmaterialsreport", {
        headers: { Authorization: jwtToken },
        body: {
          reportName: "ExcessMaterialsReport",
          selectFields: [
            {
              field: "po nbr",
              alias: "PO Number"
            },
            {
              field: "line nbr"
            },
            {
              field: "part nbr",
              alias: "Part Number"
            },
            {
              field: "prod desc",
              alias: "Description"
            },
            {
              field: "vend name",
              alias: "Vendor Name"
            },
            {
              field: "qty ord",
              alias: "Qty Ordered"
            },
            {
              field: "qty on hand",
              alias: "Qty on Hand"
            },
            {
              field: "whse",
              alias: "Warehouse"
            },
            {
              field: "po uom",
              alias: "UOM"
            },
            {
              field: "unit stock",
              alias: "Unit Stock"
            },
            {
              field: "price",
              alias: "Price"
            }
          ],
          orderByFields: [
            {
              field: "price",
              direction: "desc"
            }
          ],
          filterByFields: [
            {
              field: "as_of_date",
              comparisonType: "=",
              filterValue: new Date(Date.now() - ONE_DAY * index).toISOString().split("T")[0]
            }
          ],
          limit: 20000
        }
      });
      if (results?.length) {
        finalResult = results;
      }
      index += 1;
    }
    return finalResult;
  },
  "strategic-sourcing-table-Site Excess Material on Order-export-csv": async () => {
    const {
      signInUserSession: {
        idToken: { jwtToken }
      }
    } = await Auth.currentAuthenticatedUser();

    let finalResult = [];
    let index = 0;

    while (!finalResult?.length) {
      const results = await exportCSVQueryBatchHelper((offset, limit) => {
        return API.post("Vallen", "/query?requestType=query", {
          headers: { Authorization: jwtToken },
          body: {
            query: `SELECT * FROM "AwsDataCatalog"."controltower-2"."excessmaterials_latest" WHERE "as_of_date" = DATE('${
              new Date(Date.now() - ONE_DAY * index).toISOString().split("T")[0]
            }') ORDER BY "po nbr" DESC OFFSET ${offset} rows LIMIT ${limit}`
          }
        });
      });
      if (results?.length) {
        finalResult = results;
      }
      index += 1;
    }
    return finalResult;
  },
  "strategic-sourcing-table-Potential Core Supplier Buy": async () => {
    const {
      signInUserSession: {
        idToken: { jwtToken }
      }
    } = await Auth.currentAuthenticatedUser();

    let finalResult = [];
    let index = 0;
    while (!finalResult?.length) {
      const { results } = await API.post("Vallen", "/query?requestType=coreopportunitiesreport", {
        headers: { Authorization: jwtToken },
        body: {
          reportName: "CoreOpportunitiesReport",
          selectFields: [
            { field: "as_of_date" },
            {
              field: "po nbr",
              alias: "PO Number"
            },
            {
              field: "email address",
              alias: "Email"
            },
            {
              field: "part nbr",
              alias: "Part Number"
            },
            {
              field: "prod desc",
              alias: "Product Description"
            },
            {
              field: "vend name",
              alias: "Vendor Name"
            },
            {
              field: "qty ord",
              alias: "Qty Ordered"
            },
            {
              field: "po uom",
              alias: "UOM"
            },
            {
              field: "price",
              alias: "Unit Price"
            },
            {
              field: "due dt",
              alias: "Due Date"
            }
          ],
          orderByFields: [
            {
              field: "qty on hand",
              direction: "asc"
            }
          ],
          filterByFields: [
            {
              field: "as_of_date",
              comparisonType: "=",
              filterValue: new Date(Date.now() - ONE_DAY * index).toISOString().split("T")[0]
            }
          ],
          limit: 20000
        }
      });
      if (results?.length) {
        finalResult = results;
      }
      index += 1;
    }

    return finalResult;
  },
  "strategic-sourcing-table-Potential Core Supplier Buy-export-csv": async () => {
    const {
      signInUserSession: {
        idToken: { jwtToken }
      }
    } = await Auth.currentAuthenticatedUser();

    let finalResult = [];
    let index = 0;
    while (!finalResult?.length) {
      const results = await exportCSVQueryBatchHelper((offset, limit) => {
        return API.post("Vallen", "/query?requestType=query", {
          headers: { Authorization: jwtToken },
          body: {
            query: `SELECT * FROM "AwsDataCatalog"."controltower-2"."coreopportunities_latest" WHERE "as_of_date" = DATE('${
              new Date(Date.now() - ONE_DAY * index).toISOString().split("T")[0]
            }') ORDER BY "price" DESC OFFSET ${offset} rows LIMIT ${limit}`
          }
        });
      });
      if (results?.length) {
        finalResult = results;
      }
      index += 1;
    }
    return finalResult;
  },
  "strategic-sourcing-table-Obsolete Material on Order": async () => {
    const {
      signInUserSession: {
        idToken: { jwtToken }
      }
    } = await Auth.currentAuthenticatedUser();
    let finalResult = [];
    let index = 0;
    while (!finalResult?.length) {
      const { results } = await API.post("Vallen", "/query?requestType=obsoletereport", {
        headers: { Authorization: jwtToken },
        body: {
          reportName: "ObsoleteReport",
          selectFields: [
            {
              field: "po nbr",
              alias: "PO Number"
            },
            {
              field: "email address",
              alias: "Email"
            },
            {
              field: "part nbr",
              alias: "Part Number"
            },
            {
              field: "prod desc",
              alias: "Product Description"
            },
            {
              field: "vend name",
              alias: "Vendor Name"
            },
            {
              field: "qty ord",
              alias: "Qty Ordered"
            },
            {
              field: "po uom",
              alias: "UOM"
            },
            {
              field: "price",
              alias: "Unit Price"
            },
            {
              field: "value",
              alias: "Total Price"
            },
            {
              field: "due dt",
              alias: "Due Date"
            }
          ],
          orderByFields: [
            {
              field: "qty on hand",
              direction: "asc"
            }
          ],
          filterByFields: [
            {
              field: "as_of_date",
              comparisonType: "=",
              filterValue: new Date(Date.now() - ONE_DAY * index).toISOString().split("T")[0]
            }
          ],
          limit: 20000
        }
      });
      if (results?.length) {
        finalResult = results;
      }
      index += 1;
    }
    return finalResult;
  },
  "strategic-sourcing-table-Obsolete Material on Order-export-csv": async () => {
    const {
      signInUserSession: {
        idToken: { jwtToken }
      }
    } = await Auth.currentAuthenticatedUser();

    let finalResult = [];
    let index = 0;
    while (!finalResult?.length) {
      const results = await exportCSVQueryBatchHelper((offset, limit) => {
        return API.post("Vallen", "/query?requestType=query", {
          headers: { Authorization: jwtToken },
          body: {
            query: `SELECT * FROM "AwsDataCatalog"."controltower-2"."obsolete_latest" WHERE "as_of_date" = DATE('${
              new Date(Date.now() - ONE_DAY * index).toISOString().split("T")[0]
            }') ORDER BY "price" DESC OFFSET ${offset} rows LIMIT ${limit}`
          }
        });
      });
      if (results?.length) {
        finalResult = results;
      }
      index += 1;
    }
    return finalResult;
  },
  "strategic-sourcing-table-Material Sharing Opportunity": async () => {
    const {
      signInUserSession: {
        idToken: { jwtToken }
      }
    } = await Auth.currentAuthenticatedUser();

    let finalResult = [];
    let index = 0;

    while (!finalResult?.length) {
      const { results } = await API.post("Vallen", "/query?requestType=sharingopportunitiesreport", {
        headers: { Authorization: jwtToken },
        body: {
          reportName: "SharingOpportunitiesReport",
          selectFields: [
            {
              field: "whse"
            },
            {
              field: "vend name"
            },
            {
              field: "po nbr"
            },
            {
              field: "part nbr"
            },
            {
              field: "qty ord"
            },
            {
              field: "po uom"
            },
            {
              field: "unit stock"
            },
            {
              field: "price"
            },
            {
              field: "qty_onhand"
            },
            {
              field: "value"
            },
            {
              field: "geolocation_reserve"
            },
            {
              field: "optype"
            },
            {
              field: "source"
            },
            {
              field: "as_of_date"
            }
          ],
          orderByFields: [
            {
              field: "as_of_date",
              direction: "desc"
            }
          ],
          filterByFields: [
            {
              field: "as_of_date",
              comparisonType: "=",
              filterValue: new Date(Date.now() - ONE_DAY * index).toISOString().split("T")[0]
            }
          ],
          limit: 10000
        }
      });
      if (results?.length) {
        finalResult = results;
      }
      index += 1;
    }
    return finalResult;
  },
  "strategic-sourcing-table-Material Sharing Opportunity-export-csv": async () => {
    const {
      signInUserSession: {
        idToken: { jwtToken }
      }
    } = await Auth.currentAuthenticatedUser();

    let finalResult = [];
    let index = 0;

    while (!finalResult?.length) {
      const results = await exportCSVQueryBatchHelper((offset, limit) => {
        return API.post("Vallen", "/query?requestType=query", {
          headers: { Authorization: jwtToken },
          body: {
            query: `SELECT * FROM "AwsDataCatalog"."controltower-2"."sharingopportunities_latest" WHERE "as_of_date" = DATE('${
              new Date(Date.now() - ONE_DAY * index).toISOString().split("T")[0]
            }')  ORDER BY "price" DESC OFFSET ${offset} rows LIMIT ${limit}`
          }
        });
      });
      if (results?.length) {
        finalResult = results;
      }
      index += 1;
    }
    return finalResult;
  },
  "strategic-sourcing-table-EB Conversion Potential": async () => {
    const {
      signInUserSession: {
        idToken: { jwtToken }
      }
    } = await Auth.currentAuthenticatedUser();

    let finalResult = [];
    let index = 0;
    while (!finalResult?.length) {
      const { results } = await API.post("Vallen", "/query?requestType=ebconversionreport", {
        headers: { Authorization: jwtToken },
        body: {
          reportName: "EBConversionReport",
          selectFields: [
            {
              field: "po nbr",
              alias: "PO Number"
            },
            {
              field: "line nbr",
              alias: "Line Number"
            },
            {
              field: "part nbr",
              alias: "Part Number"
            },
            {
              field: "prod desc",
              alias: "Description"
            },
            {
              field: "eb sxe part #",
              alias: "SXe Part #"
            },
            {
              field: "qty ord",
              alias: "Qty Ordered"
            },
            {
              field: "vend name",
              alias: "Vendor Name"
            },
            {
              field: "whse",
              alias: "Warehouse"
            },
            {
              field: "po uom",
              alias: "UOM"
            },
            {
              field: "price",
              alias: "Price"
            }
          ],
          orderByFields: [
            {
              field: "price",
              direction: "desc"
            }
          ],
          filterByFields: [
            {
              field: "as_of_date",
              comparisonType: "=",
              filterValue: new Date(Date.now() - ONE_DAY * index).toISOString().split("T")[0]
            }
          ],
          limit: 20000
        }
      });
      if (results?.length > 0) {
        finalResult = results;
      }
      index += 1;
    }
    return finalResult;
  },
  "strategic-sourcing-table-EB Conversion Potential-export-csv": async () => {
    const {
      signInUserSession: {
        idToken: { jwtToken }
      }
    } = await Auth.currentAuthenticatedUser();

    let finalResult = [];
    let index = 0;
    while (!finalResult?.length) {
      const results = await exportCSVQueryBatchHelper((offset, limit) => {
        return API.post("Vallen", "/query?requestType=query", {
          headers: { Authorization: jwtToken },
          body: {
            query: `SELECT * FROM "AwsDataCatalog"."controltower-2"."mfgbuys_latest" WHERE "as_of_date" = DATE('${
              new Date(Date.now() - ONE_DAY * index).toISOString().split("T")[0]
            }') ORDER BY "price" DESC OFFSET ${offset} rows LIMIT ${limit}`
          }
        });
      });
      if (results?.length) {
        finalResult = results;
      }
      index += 1;
    }
    return finalResult;
  }
};
