import React, { useState, useEffect } from "react";
import Style from "./InitialMessageComponent.module.css";
import factoryImage from "../../assets/images/install-img.png";
import { ReactComponent as ArrowRight } from "../../../assets/icons/arrow-right.svg";
import { LocalCacheService } from "../../../services/local-cache-service";

const INITIAL_MESSAGE = "Get insights to what’s happening in your facilities";
const IS_A_FAQ_QUESTION = true;

const InitialMessageComponent = (props) => {
  const [userIdToken, setUserIdToken] = useState(null);
  const userProfile = LocalCacheService.loadUserProfile();

  useEffect(() => {
    const { idToken } = userProfile.tokens;
    setUserIdToken(idToken);
  }, []);

  const options = [
    {
      text: "“What purchase orders are currently at risk?”",
      handler: () => {
        const message = "What purchase orders are currently at risk?";
        props.actionProvider.handleQuestion(message, userIdToken, IS_A_FAQ_QUESTION);
      },
      id: 1
    },
    {
      text: "“What items need to be expedited immediately?”",
      handler: () => {
        const message = "What items need to be expedited immediately?";
        props.actionProvider.handleQuestion(message, userIdToken, IS_A_FAQ_QUESTION);
      },
      id: 2
    },
    {
      text: "“What are my priority items from my min max report that are scheduled for delivery?”",
      handler: () => {
        const message = "What are my priority items from my min max report that are scheduled for delivery?";
        props.actionProvider.handleQuestion(message, userIdToken, IS_A_FAQ_QUESTION);
      },
      id: 3
    }
  ];

  const optionsMarkup = options.map((option) => (
    <button
      className={Style.option}
      key={option.id}
      onClick={option.handler}
    >
      <p className={Style.option_text}>{option.text}</p>

      <ArrowRight
        width={12}
        style={{ flex: ".1" }}
      />
    </button>
  ));

  return (
    <div className={Style.initial_message_container}>
      <img
        className={Style.image_container}
        width="73%"
        alt="logo"
        src={factoryImage}
      />
      <p className={Style.initial_message_text}>{INITIAL_MESSAGE}</p>
      <div className={Style.options_container}>
        <p className={Style.options_header_text}>Frequently Asked Questions:</p>
        {optionsMarkup}
      </div>
    </div>
  );
};

export default InitialMessageComponent;
