import { useEffect, useRef, useState } from "react";
import { naturalSort } from "../../common/Utilities";

const PRINTER_STATUS = {
  ONLINE: {
    label: "Online",
    color: "green"
  },
  OFFLINE: {
    label: "Offline",
    color: "red"
  }
};

const ONE_MINUTE = 60000;

export const useZebraPrinter = () => {
  const [selectedPrinterInfo, setSelectedPrinterInfo] = useState({});
  const [zebraPrinter, setZebraPrinter] = useState({});
  const [isPrinterScriptsReady, setIsPrinterScriptsReady] = useState(false);

  const intervalId = useRef(null);

  // update printer info
  const setPrinterStatus = (newPrinterStatus, newSelectedPrinterInfo) => {
    const { message, readyToPrint } = newPrinterStatus;
    const clonedNewSelectedPrnterInfo = { ...newSelectedPrinterInfo };
    clonedNewSelectedPrnterInfo.message.value = readyToPrint ? PRINTER_STATUS.ONLINE.label : message;
    clonedNewSelectedPrnterInfo.message.statusColor = readyToPrint
      ? PRINTER_STATUS.ONLINE.color
      : PRINTER_STATUS.OFFLINE.color;

    setSelectedPrinterInfo(clonedNewSelectedPrnterInfo);
  };

  // get printer status for new printer and set up interval to get printer status
  const startGetPrinterStatus = async (newZebraPrinter, newSelectedPrinterInfo) => {
    if (!Object.keys(newZebraPrinter).length) {
      return;
    }
    try {
      const status = await getPrinterStatus(newZebraPrinter);
      const { message, readyToPrint } = status;
      setPrinterStatus({ message, readyToPrint }, newSelectedPrinterInfo);
    } catch (e) {
      throw "Could not get printer status";
    }

    const interval = setInterval(async () => {
      try {
        const status = await getPrinterStatus(newZebraPrinter);
        const { message, readyToPrint } = status;
        setPrinterStatus({ message, readyToPrint }, newSelectedPrinterInfo);
      } catch (e) {
        throw "Could not get printer status";
      }
    }, ONE_MINUTE);

    intervalId.current = interval;
  };

  const stopGetPrinterStatus = () => {
    clearInterval(intervalId.current);
  };

  // register zebraPrinter object for other function to make request to the printer
  const registerPrinter = async (newPrinter) => {
    if (!Object.keys(newPrinter).length) {
      setSelectedPrinterInfo({});
      setZebraPrinter({});
      return;
    }

    const { id, name, label, printerObject } = newPrinter;
    try {
      const newZebraPrinter = new window.Zebra.Printer(printerObject);

      // default printer info
      const newSelectedPrinterInfo = {
        name: { hiddenOnInfoCard: true, value: name, label },
        message: {
          label: "Status",
          value: "--"
        },
        id: { label: "IP Address", value: id }
      };
      setSelectedPrinterInfo(newSelectedPrinterInfo);
      setZebraPrinter(newZebraPrinter);

      // restart get printer status when new printer selected ( or after a print job)
      stopGetPrinterStatus();
      await startGetPrinterStatus(newZebraPrinter, newSelectedPrinterInfo);
    } catch (e) {
      throw e;
    }
  };

  useEffect(() => {
    let browserScript;
    let zebraBrowserScript;

    let browserScriptLoaded = false;
    let zebraBrowserScriptLoaded = false;

    const existingBrowserScript = document.getElementById("browserPrint");
    if (!existingBrowserScript) {
      browserScript = document.createElement("script");
      browserScript.type = "text/javascript";
      browserScript.src = "zebra-printer/BrowserPrint.js";
      browserScript.id = "browserPrint";
      document.body.appendChild(browserScript);

      // if both the required script are loaded, run the callback
      browserScript.onload = () => {
        browserScriptLoaded = true;
        if (zebraBrowserScriptLoaded) {
          setIsPrinterScriptsReady(true);
        }
      };
    }

    const existingZebraBrowserScript = document.getElementById("browserPrintZebra");
    if (!existingZebraBrowserScript) {
      zebraBrowserScript = document.createElement("script");
      zebraBrowserScript.type = "text/javascript";
      zebraBrowserScript.src = "zebra-printer/BrowserPrint-Zebra.js";
      zebraBrowserScript.id = "browserPrintZebra";
      document.body.appendChild(zebraBrowserScript);

      // if both the required script are loaded, run the callback
      zebraBrowserScript.onload = () => {
        zebraBrowserScriptLoaded = true;
        if (browserScriptLoaded) {
          setIsPrinterScriptsReady(true);
        }
      };
    }

    if (existingBrowserScript && existingZebraBrowserScript) {
      setIsPrinterScriptsReady(true);
    }

    return () => {
      if (existingBrowserScript && existingZebraBrowserScript) {
        existingBrowserScript.remove();
        existingZebraBrowserScript.remove();
      }

      if (browserScript) {
        document.body.removeChild(browserScript);
      }
      if (zebraBrowserScript) {
        document.body.removeChild(zebraBrowserScript);
      }
      // stop get printer status interval when dismount
      stopGetPrinterStatus();
    };
  }, []);

  const getDefaultPrinter = () => {
    return new Promise((resolve, reject) => {
      window.BrowserPrint.getDefaultDevice(
        "printer",
        (device) => {
          resolve(device);
        },
        (error) => {
          reject(
            `Cannot search for printers.
        Ensure that Zebra Browser Print is running.`
          );
        }
      );
    });
  };

  const getAvailablePrinters = () => {
    return new Promise((resolve, reject) => {
      window.BrowserPrint.getLocalDevices(
        (deviceList) => {
          resolve(deviceList.printer);
        },
        (error) => {
          reject(
            `Cannot search for printers.
        Ensure that Zebra Browser Print is running.`
          );
        }
      );
    });
  };

  const getPrinterStatus = async (newZebraPrinter) => {
    return new Promise((resolve, reject) => {
      newZebraPrinter.getStatus(
        (status) => {
          resolve({ message: status.getMessage(), readyToPrint: status.isPrinterReady() });
        },
        (error) => {
          reject(error);
        }
      );
    });
  };

  const queryPrinters = async () => {
    try {
      const [defaultPrinter = {}, availablePrinters = []] = await Promise.all([
        getDefaultPrinter(),
        getAvailablePrinters()
      ]);

      const printersToSet = naturalSort(
        availablePrinters.map((printer) => {
          const { uid: id, name } = printer;
          return { id, name, label: name, printerObject: printer };
        }),
        "label"
      );

      const defaultPrinterToSet = printersToSet.find((printer) => {
        return printer.id === defaultPrinter.uid;
      });

      return { printers: printersToSet, defaultPrinter: defaultPrinterToSet };
    } catch (e) {
      throw e;
    }
  };

  const sendToPrinter = async (printZPLCommand) => {
    return new Promise((resolve, reject) => {
      zebraPrinter.send(
        printZPLCommand,
        (success) => {
          resolve(success);
        },
        (error) => {
          reject(error);
        }
      );
    });
  };

  return {
    isPrinterScriptsReady,
    queryPrinters,
    selectedPrinterInfo,
    setSelectedPrinterInfo,
    sendToPrinter,
    registerPrinter,
    stopGetPrinterStatus
  };
};
