import React, { useContext, useState, useEffect } from "react";
import { useAppConfigProvider } from "../../../../../../services/soft-cache-service";

const FEATURE_ID = "addTransferOrder";
const APP_ID = "inventory";
const CSV_HEADER_MAP = "transferOrderCsvHeadersMap";
const ALLOW_MULTIPLE_TRANSFER_ORDERS_PER_FILE = "allowMultipleTransferOrdersPerFile";
const LOCATION_DEFAULTS = "locationDefaults";
const TO_LOCATION_DEFAULT = "toLocationDefault";
const FROM_LOCATION_DEFAULT = "fromLocationDefault";
const USE_DEFAULT_LOCATION = "useDefaultLocation";
const TRANSFER_ORDER_TITLE_LABEL = "transferOrderTitleLabel";
const SUBMIT_LABEL = "submitLabel";

const initialState = {
  transferOrderCsvHeadersMap: {},
  transferOrderTitleLabel: "Transfer Order",
  submitLabel: "Submit",
  allowMultipleTransferOrdersPerFile: false,
  locationDefaults: {
    useDefaultLocation: false,
    fromLocationDefault: "",
    toLocationDefault: ""
  }
};

const AddTransferOrderFeatureConfigContext = React.createContext({
  transferOrderCsvHeadersMap: {},
  useDefaultLocation: false,
  fromLocationDefault: "",
  toLocationDefault: "",
  transferOrderTitleLabel: "",
  submitLabel: "",
  allowMultipleTransferOrdersPerFile: false
});

export const useAddTransferOrderFeatureConfigContext = () => {
  return useContext(AddTransferOrderFeatureConfigContext);
};

export const AddTransferOrderFeatureConfigContextProvider = ({ children }) => {
  const configProvider = useAppConfigProvider(APP_ID);
  const addTransferOrderConfiguration = configProvider.getValue(FEATURE_ID, "object") || {};
  const [transferOrderCsvHeadersMap, setTransferOrderCsvHeadersMap] = useState(initialState.transferOrderCsvHeadersMap);
  const [useDefaultLocation, setUseDefaultLocation] = useState(initialState.locationDefaults.useDefaultLocation);
  const [fromLocationDefault, setFromLocationDefault] = useState(initialState.locationDefaults.fromLocationDefault);
  const [toLocationDefault, setToLocationDefault] = useState(initialState.locationDefaults.toLocationDefault);
  const [transferOrderTitleLabel, setTransferOrderTitleLabel] = useState(initialState.transferOrderTitleLabel);
  const [submitLabel, setSubmitLabel] = useState(initialState.submitLabel);
  const [allowMultipleTransferOrdersPerFile, setAllowMultipleTransferOrdersPerFile] = useState(
    initialState.allowMultipleTransferOrdersPerFile
  );
  useEffect(() => {
    /**
     * This function is used to get the csv headers map for transfer orders.
     * @returns  {Object} csvAttributesMap
     */
    const getTransferOrderCsvHeadersMap = () => {
      const csvAttributesMap = {
        item_number: {
          id: "item_number",
          label: "Item Number",
          isRequired: true,
          type: "dropdownWithOptionsFromAPI"
        },
        quantity: {
          id: "quantity",
          label: "Quantity",
          isRequired: true
        },
        tracking_number: {
          id: "tracking_number",
          label: "Tracking Number",
          isRequired: true,
          type: "input"
        },
        from_location: {
          id: "from_location",
          label: "From Location",
          isRequired: true,
          type: "input"
        },
        to_location: {
          id: "to_location",
          label: "To Location",
          isRequired: true,
          type: "input"
        }
      };

      return csvAttributesMap;
    };

    const newTransferOrderCsvHeadersMap =
      addTransferOrderConfiguration[CSV_HEADER_MAP] || getTransferOrderCsvHeadersMap();
    const newLocationDefaultsMap = addTransferOrderConfiguration[LOCATION_DEFAULTS] || initialState.locationDefaults;

    setTransferOrderCsvHeadersMap(newTransferOrderCsvHeadersMap);
    setUseDefaultLocation(
      newLocationDefaultsMap[USE_DEFAULT_LOCATION] || initialState.locationDefaults.useDefaultLocation
    );
    setFromLocationDefault(
      newLocationDefaultsMap[FROM_LOCATION_DEFAULT] || initialState.locationDefaults.fromLocationDefault
    );
    setToLocationDefault(
      newLocationDefaultsMap[TO_LOCATION_DEFAULT] || initialState.locationDefaults.toLocationDefault
    );
    setTransferOrderTitleLabel(
      addTransferOrderConfiguration[TRANSFER_ORDER_TITLE_LABEL] || initialState.transferOrderTitleLabel
    );
    setSubmitLabel(addTransferOrderConfiguration[SUBMIT_LABEL] || initialState.submitLabel);
    setAllowMultipleTransferOrdersPerFile(
      addTransferOrderConfiguration[ALLOW_MULTIPLE_TRANSFER_ORDERS_PER_FILE] ||
        initialState.allowMultipleTransferOrdersPerFile
    );
  }, []);

  return (
    <AddTransferOrderFeatureConfigContext.Provider
      value={{
        transferOrderCsvHeadersMap,
        useDefaultLocation,
        fromLocationDefault,
        toLocationDefault,
        transferOrderTitleLabel,
        submitLabel,
        allowMultipleTransferOrdersPerFile
      }}
    >
      {children}
    </AddTransferOrderFeatureConfigContext.Provider>
  );
};
