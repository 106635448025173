import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import PrinterSelectionComponent from "../../../../../../components/printer-selection-component";
import { useAddAssetFeatureConfigContext } from "../../contexts/add-asset-feature-config-context";
import { useAddAssetFeatureStateContext } from "../../contexts/add-asset-feature-state-context";
import { STATUS_OPTIONS } from "../../../../../../components/status-popup-component";
import calculateDependentFormValue from '../../../../../../utils/calculate-dependent-form-value';
import MultiInputForm from "../../../../../../components/multi-input-form";
import { AddPageInputTypeMap } from '../../../../../../common/Utilities';
import Style from "./AssetSingleCreateTab.module.css";
import { PRINT_TYPES } from '../../../../../../data/constants';

const AssetSingleCreateTab = ({ printService }) => {
  const { bartenderConfig, formFields, formSections, isLoading, isPrintEnabled, printType } = useAddAssetFeatureConfigContext();
  const {
    setFormDataList,
    submitStatus,
    setSubmitStatus,
    setIsSubmitDisabled
  } = useAddAssetFeatureStateContext();
  const { endpoint: bartenderUrl } = bartenderConfig;

  const {
    printers,
    selectedPrinterInfo,
    onSelectPrinter,
    isLoading: isPrintServiceLoading,
    printerErrorMessage
  } = printService;

  const [singleFormData, setSingleFormData] = useState({});

  const processedFormSections = useMemo(() => {
    const updatedFormSections = formSections.map((section) => {
      const sectionFieldList = section.map((field) => {
        const newField = field;

        // Field should be read only if it is dependent on other fields or it is filled using metaData
        newField.isReadOnly = field.isReadOnly || field.dependency !== undefined || !!field.metaDataOf;

        // Structure value that will be passed in to CheckBoxGroup
        if (newField.type === AddPageInputTypeMap.CHECK_BOX_GROUP && newField.options) {
          const checkBoxValue = {};
          newField.options.forEach((option) => {
            checkBoxValue[option.id] = singleFormData[field.id] === option.id;
          });
          newField.value = checkBoxValue;
        } else {
          newField.value = singleFormData[field.id];
        }

        return newField;
      });

      return sectionFieldList;
    });

    return updatedFormSections;
  }, [formSections, singleFormData]);

  useEffect(() => {
    resetFormData();
  }, [formFields]);

  useEffect(() => {
    if (submitStatus === STATUS_OPTIONS.SUCCESS) {
      resetFormData();
    }
  }, [submitStatus]);

  useEffect(() => {
    // Clears popup message
    if (submitStatus === STATUS_OPTIONS.ERROR) {
      setSubmitStatus(STATUS_OPTIONS.NONE);
    } else if (submitStatus === STATUS_OPTIONS.SUCCESS && singleFormData?.item_number) {
      setSubmitStatus(STATUS_OPTIONS.NONE);
    }

    const clonedSingleFormData = _.cloneDeep(singleFormData);
    const { location } = clonedSingleFormData;

    // Data in formDataList must contain only string or number
    if (location) {
      const { identifier: locationIdentifier = "" } = clonedSingleFormData.location;
      clonedSingleFormData.location = locationIdentifier;
    }
    setFormDataList([clonedSingleFormData]);
  }, [singleFormData]);

  // Enables or disables the submit button
  useEffect(() => {
    const isPrinterSelected = Object.keys(selectedPrinterInfo).length > 0;
    const isBartenderPrint = Boolean(bartenderUrl);
    const isPrinterOnline = selectedPrinterInfo?.message?.value === "Online";

    const isPrinterReady = isPrinterSelected && (isBartenderPrint || isPrinterOnline);

    if (
      isLoading ||
      !Object.keys(singleFormData).length ||
      submitStatus === STATUS_OPTIONS.LOADING ||
      printerErrorMessage ||
      (isPrintEnabled && !isPrinterReady)
    ) {
      setIsSubmitDisabled(true);
      return;
    }

    let disabled = false;

    Object.keys(formFields).forEach((fieldName) => {
      // Check if a required field is missing. If a field is a Selector, need to check if it has keys
      const isMissingRequiredField =
        formFields[fieldName].isRequired && (!singleFormData[fieldName] ||
            (typeof singleFormData[fieldName] === 'object' && Object.keys(singleFormData[fieldName]).length === 0));

      
      if (isMissingRequiredField) {
        disabled = true;
      }
    });

    setIsSubmitDisabled(disabled);
  }, [formSections, singleFormData, submitStatus, selectedPrinterInfo]);

  const resetFormData = () => {
    if (formFields) {
      const newFormData = {};
      Object.keys(formFields).forEach((fieldName) => {
        newFormData[fieldName] = formFields[fieldName].defaultValue;
      });
      setSingleFormData(newFormData);
    }
  };

  const onFormChange = useCallback((fieldId, value) => {
    let newValue = value;

    // Parse value returned from CheckBoxGroup
    if (formFields[fieldId].type === AddPageInputTypeMap.CHECK_BOX_GROUP) {
      newValue = "";
      for (const option in value) {
        if (value[option] && singleFormData[fieldId] !== option) {
          newValue = option;
          break;
        }
      }
    }

    const newFormData = {
      ...singleFormData,
      [fieldId]: newValue
    };

    // When a type is selected, fill in other fields based on values for that type
    if (fieldId === "item_number") {
      Object.values(formFields).forEach((field) => {
        const { id, metaDataOf } = field;
        if (id !== "item_number" && metaDataOf === fieldId) {
          newFormData[id] = value[id] || formFields[id].defaultValue;
        }
      });
    }

    // Looks for fields are dependent on other fields (eg. calibration date) and
    // calculates their values
    formSections.forEach((section) => {
      section.forEach((field) => {
        if (field.dependency && field.dependency.dependentOn.includes(fieldId)) {
          newFormData[field.id] = calculateDependentFormValue(
            field,
            newFormData,
            formFields
          );
        }
      });
    });

    setSingleFormData(newFormData);
  }, [formSections, singleFormData]);

  return (
    <div className={submitStatus === STATUS_OPTIONS.LOADING ? Style.modal_disabled : ""}>
      <div className={Style.single_create_form}>
        <p className={Style.form_title}>Asset Information</p>
        <div className={Style.form_container}>
          <MultiInputForm
            formFields={processedFormSections[0]}
            onChange={onFormChange}
            isLoading={!processedFormSections.length}
            containerClassName={Style.first_section_container}
            fieldContainerClassName={Style.field_container}
          />
          <div className={Style.section_seperator} />
          <MultiInputForm
            formFields={processedFormSections[1]}
            onChange={onFormChange}
            isLoading={!processedFormSections.length}
            containerClassName={Style.second_section_container}
            fieldContainerClassName={Style.field_container}
          />
        </div>
      </div>
      {isPrintEnabled && printType !== PRINT_TYPES.BARTENDER_UPLOAD && (
        <div className={Style.printer_component}>
          <PrinterSelectionComponent
            isLoading={isPrintServiceLoading}
            printerList={printers}
            selectedPrinterInfo={selectedPrinterInfo}
            onPrinterSelect={onSelectPrinter}
            errorMessage={printerErrorMessage}
          />
        </div>
      )}
    </div>
  );
};

AssetSingleCreateTab.propTypes = {
  printService: PropTypes.shape({
    selectedPrinterInfo: PropTypes.shape({
      message: PropTypes.shape({
        value: PropTypes.string
      })
    }).isRequired,
    onSelectPrinter: PropTypes.func.isRequired,
    printers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    isLoading: PropTypes.bool.isRequired,
    printerErrorMessage: PropTypes.string.isRequired
  }).isRequired
};

export default AssetSingleCreateTab;
