/* eslint-disable no-param-reassign */
import React, { useState, useEffect, Fragment } from "react";
import { Tabs, Tab, TabList } from "react-web-tabs";
import PropTypes from "prop-types";

import Style from "./TabMenu.module.css";

/**
 * @typedef MenuItem
 * @property { string } id The id to uniquely identify this menu item
 * @property { string } title The title to be displayed for the menu item
 * @property { () => {} } [render] If provided, will be called to render the menu item instead
 * of using the title property
 * @property { () => {} } [action] A callback to invoke when the menu item is clicked
 */

/**
 * @typedef TabGroup
 * @property { MenuItem[] } items An array of MenuItems for this tab group
 * @property { string } [title] An optional title to display for the tab group
 */

/**
 * @typedef TabConfiguration
 * @property { TabGroup[] } groups An array of TabGroups for the menu
 */

/**
 * A component for building menus where single items can be highlighted and hierarchies
 * can be defined
 * @param {TabConfiguration} tabConfiguration The TabConfiguration for this menu, defining which tabs
 * should be included in the menu
 * @param {string} [defaultTab] If provided, the tab with the matching id will be selected initially
 * @param {boolean} [collapsed] If provided, all sub-menus will be hidden
 * @param {object} [nestedTabListCollapsed] An object where the keys are the ids of the tabs that have children, and value are where the children are collapsed
 * @param {object} [classOverrides] An object where various properties can be set to override
 * the class of the corresponding element
 */
export const TabMenu = ({ tabConfiguration, defaultTab, collapsed, nestedTabListCollapsed, classOverrides }) => {
  const [focusState, setFocusState] = useState(createInitialFocusState(tabConfiguration, defaultTab));

  useEffect(() => {
    if (Object.keys(focusState).length === 0) {
      const initialFocusState = createInitialFocusState(tabConfiguration, defaultTab);
      setFocusState(initialFocusState);
    }
  }, [tabConfiguration]);

  useEffect(() => {
    if (Object.keys(focusState).length >= 0 && defaultTab) {
      const path = resolvePathFromId(focusState, defaultTab);
      if (path) {
        setFocusState(focusTabHierarchy(focusState, path));
      }
    }
  }, [defaultTab]);

  const renderTabList = (currentFocusState, tabs, idPath = "", shouldNotDisplay = false) => {
    return (
      <TabList
        className={`
          ${classOverrides?.menuGroupContainer} 
          ${idPath !== "" ? Style.nested_menu_group_container : null} 
          `}
        style={shouldNotDisplay ? { display: "none" } : {}}
      >
        {tabs.map((tab) => {
          const tabIdPath = idPath ? `${idPath}.children.${tab.id}` : tab.id;
          const isFocused = getPropertyFromState(currentFocusState, tabIdPath, "focused");
          const ref = getPropertyFromState(currentFocusState, tabIdPath, "tabRef");

          return (
            <Fragment key={tab.id}>
              <Tab
                className={`${classOverrides?.menuTab || Style.menu_tab} 
                ${idPath !== "" && classOverrides?.menuTabChild}
                ${
                  isFocused && (idPath === "" ? classOverrides?.focusedMenuTab : classOverrides?.focusedMenuTabChildren)
                }`}
                tabFor={tab.id}
                name={tab.id}
                onClick={(event) => {
                  const matchingNode = retrieveNodeAtPath(currentFocusState, tabIdPath);
                  if (tab.action) {
                    tab.action(matchingNode, event);
                  }

                  if (tab.id !== "work-order" && tab.id !== "asset") {
                    setFocusState(focusTabHierarchy(currentFocusState, tabIdPath, true));
                  }
                }}
                data-cy-tab-menu__tab={tab.id}
              >
                {tab.render?.(isFocused, ref, collapsed) || <div ref={ref}>{tab.title}</div>}
              </Tab>
              {tab.children &&
                tab.children.length > 0 &&
                renderTabList(
                  currentFocusState,
                  tab.children,
                  tabIdPath,
                  nestedTabListCollapsed[tab.id] ? nestedTabListCollapsed[tab.id] : shouldNotDisplay
                )}
            </Fragment>
          );
        })}
      </TabList>
    );
  };

  return (
    <Tabs
      className={classOverrides?.rootContainer || Style.root_container}
      defaultTab={defaultTab}
    >
      {tabConfiguration.groups.map((menuGroup, index) => {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <div key={index}>
            {menuGroup.title && <div className={classOverrides?.menuGroupTitle}>{menuGroup.title}</div>}
            {renderTabList(focusState, menuGroup.items)}
          </div>
        );
      })}
    </Tabs>
  );
};

const getPropertyFromState = (focusState, idPath, property) => {
  const tabFocusState = retrieveNodeAtPath(focusState, idPath);
  return tabFocusState?.[property];
};

const createInitialFocusState = (tabConfiguration, defaultTab) => {
  const structure = {};
  const allMenuItemsForGroups = tabConfiguration.groups.reduce((allMenuItems, group) => {
    return allMenuItems.concat(group.items);
  }, []);
  getFocusStructureForMenuLayer(allMenuItemsForGroups, structure);

  const defaultRootTabId = allMenuItemsForGroups.find((tab) => {
    return tab.default;
  })?.id;

  let defaultTabPath;
  if (defaultTab) {
    defaultTabPath = resolvePathFromId(structure, defaultTab);
  }

  defaultTabPath = defaultTabPath || defaultRootTabId || allMenuItemsForGroups[0]?.id;
  if (defaultTabPath) {
    focusTabHierarchy(structure, defaultTabPath);
  }

  return structure;
};

const resolvePathFromId = (focusState, tabId, currentPath = "") => {
  for (const key of Object.keys(focusState)) {
    if (key === tabId) {
      return currentPath ? `${currentPath}.${key}` : key;
    }

    if (focusState[key].children) {
      const childPathResult = resolvePathFromId(
        focusState[key].children,
        tabId,
        currentPath ? `${currentPath}.${key}.children` : `${key}.children`
      );

      if (childPathResult) {
        return childPathResult;
      }
    }
  }

  return undefined;
};

const getDefaultChild = (children) => {
  return children.find((child) => {
    return child.default;
  })?.id;
};

const getFocusStructureForMenuLayer = (menuItems, focusStructure) => {
  for (const menuItem of menuItems) {
    focusStructure[menuItem.id] = { focused: false, tabRef: React.createRef() };

    if (menuItem.children) {
      focusStructure[menuItem.id].defaultChild = getDefaultChild(menuItem.children);
      focusStructure[menuItem.id].children = {};
      getFocusStructureForMenuLayer(menuItem.children, focusStructure[menuItem.id].children);
    }
  }

  return focusStructure;
};

const focusTabHierarchy = (focusStructure, tabIdPath) => {
  if (Object.keys(focusStructure).length === 0) {
    return focusStructure;
  }

  // Set all focus states to false
  clearFocusStates(focusStructure);

  const splitPath = tabIdPath.split(".");
  if (splitPath.length > 1) {
    // If we have a parent, set the parent's focus state
    // to true and its siblings' focus hierarchy state to false
    let currentTabPath = tabIdPath;
    for (let i = splitPath.length - 2; i >= 0; i--) {
      if (splitPath[i] !== "children") {
        // Setting the parent node's focus state to true
        currentTabPath = splitPath.slice(0, i + 1).join(".");
        const parentNode = retrieveNodeAtPath(focusStructure, currentTabPath);
        parentNode.focused = true;
      }
    }
  }

  // Continuing down the child hierarchy to set focus state as needed
  setNodeAndChildState(focusStructure, tabIdPath);

  return { ...focusStructure };
};

const setNodeAndChildState = (focusStructure, tabIdPath, newFocusState = true, setDefaultChildrenOnly = true) => {
  const targetNode = retrieveNodeAtPath(focusStructure, tabIdPath);
  targetNode.focused = newFocusState;

  if (targetNode.children && Object.keys(targetNode.children).length > 0) {
    const pathsToSet = setDefaultChildrenOnly
      ? targetNode.defaultChild
        ? [`${tabIdPath}.children.${targetNode.defaultChild}`]
        : []
      : Object.keys(targetNode.children).map((childKey) => {
          return `${tabIdPath}.children.${childKey}`;
        });

    for (const pathToSet of pathsToSet) {
      setNodeAndChildState(focusStructure, pathToSet, newFocusState, setDefaultChildrenOnly);
    }
  }
};

const clearFocusStates = (tabs) => {
  for (const tab of Object.values(tabs)) {
    tab.focused = false;

    if (tab.children && Object.keys(tab.children).length > 0) {
      clearFocusStates(tab.children);
    }
  }
};

const retrieveNodeAtPath = (obj, path) => {
  const splitPath = path.split(".");
  const result = splitPath.reduce((prev, cur) => {
    return prev && prev[cur];
  }, obj);

  return result;
};

const menuItemShape = PropTypes.shape({
  id: PropTypes.string,
  title: PropTypes.string,
  render: PropTypes.func,
  action: PropTypes.func
});

menuItemShape.children = PropTypes.arrayOf(menuItemShape);

TabMenu.defaultProps = {
  defaultTab: null,
  collapsed: false,
  nestedTabListCollapsed: {},
  classOverrides: null
};

TabMenu.propTypes = {
  tabConfiguration: PropTypes.shape({
    groups: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        items: PropTypes.arrayOf(menuItemShape)
      })
    )
  }).isRequired,
  defaultTab: PropTypes.string,
  collapsed: PropTypes.bool,
  nestedTabListCollapsed: PropTypes.objectOf(PropTypes.bool),
  classOverrides: PropTypes.shape({
    rootContainer: PropTypes.string,
    menuGroupContainer: PropTypes.string,
    menuTab: PropTypes.string,
    menuTabChild: PropTypes.string,
    focusedMenuTab: PropTypes.string,
    menuGroupTitle: PropTypes.string,
    focusedMenuTabChildren: PropTypes.string
  })
};
