import React from "react";
// sud todo colors aren't overriding (probably cuz exported from figma?)
import { ReactComponent as DownArrowIcon } from "../../assets/down_arrow.svg";
import { ReactComponent as UpArrowIcon } from "../../assets/up_arrow.svg";
import { ReactComponent as LightBulbIcon } from "../../assets/lightbulb.svg";
import { ReactComponent as NeutralArrowIcon } from "../../assets/neutral_arrow.svg";
import xemelgoStyle from "../../../../../../styles/variable";
import Style from "./insights.module.css";

const STATUS_COLOR_MAP = {
  NEUTRAL: xemelgoStyle.theme.APP_LIGHTGREY,
  HEALTHY: xemelgoStyle.theme.STATUS_GREEN,
  CRITICAL: xemelgoStyle.theme.STATUS_RED
};

const MOCK_INSIGHTS = [
  {
    status: "DECREASE",
    color: "HEALTHY",
    message: "There have been less Amazon buys at Y740 over the past month by ~21%"
  },
  {
    status: "DECREASE",
    color: "CRITICAL",
    message:
      "On hand quantity of 7006806-120 has been decreasing rapidly, with a rate of ~15/day. Current on hand quantity is 91"
  },
  {
    status: "MISC",
    message: "Trends predict an increase in buys of 69905370051 (WHEEL FLAP 2AX 80X) in the upcoming month"
  },
  {
    status: "NEUTRAL",
    color: "NEUTRAL",
    message: "Overall, no progress has been made to improve the amount of potential core supplier opportunities "
  },
  {
    status: "INCREASE",
    color: "CRITICAL",
    message: "U525 has hit zero stock 9 more times this week compared to previously, a 75% increase"
  }
];

export const Insights = ({}) => {
  const getIcon = (status, statusColor) => {
    const color = STATUS_COLOR_MAP[statusColor] || xemelgoStyle.theme.APP_BLUE;
    switch (status) {
      case "INCREASE":
        return (
          <UpArrowIcon
            width={35}
            height={35}
            color={color}
          />
        );
      case "DECREASE":
        return (
          <DownArrowIcon
            width={35}
            height={35}
            color={color}
          />
        );
      case "NEUTRAL":
        return (
          <NeutralArrowIcon
            width={35}
            height={35}
            color={color}
          />
        );
      case "MISC":
        return (
          <LightBulbIcon
            width={35}
            height={35}
            color={color}
          />
        );
      default:
        break;
    }
  };

  // sud todo: title?
  return (
    <div className={Style.container}>
      <div className={Style.title}>AI Insights</div>
      <div className={Style.list_container}>
        {MOCK_INSIGHTS.map((element, index) => {
          return (
            <div
              className={Style.list_item}
              key={index}
            >
              {getIcon(element.status, element.color)}
              {element.message}
            </div>
          );
        })}
      </div>
    </div>
  );
};
