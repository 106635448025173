import { useMemo } from "react";
import { STATUS_COLOR_MAP, STATUS_MAP, STATUS_TITLE_MAP, STATUS_SEVERITY_MAP } from "../../../../data/constants";
import { LATE_TO_START_WEIGHT, TIME_EXCEEDED_WEIGHT } from "../../data/constants";
import useWorkOrderTrackPageConfigContext from "../../../../contexts/work-order-track-page-config-context";

export const useWorkOrderParser = (workOrderDataList, locationTreeMap, useBacklogStatusOnly) => {
  // this is a temporary config control to hideLateToStartStatus.
  const { hideLateToStartStatus } = useWorkOrderTrackPageConfigContext();

  const statusBuilder = (eachWorkOrder) => {
    const statusList = [];
    const { priority, operationSeverity, operationStatus, operationDelayedStartSeverity, operationStartStatus } =
      eachWorkOrder;

    if (priority && priority <= 9) {
      statusList.push({
        title: STATUS_TITLE_MAP[STATUS_MAP.expedited],
        status: STATUS_MAP.expedited,
        severity: STATUS_SEVERITY_MAP.expedited,
        color: STATUS_COLOR_MAP.Expedited
      });
    }

    if (!useBacklogStatusOnly) {
      if (operationSeverity && operationStatus === STATUS_MAP.timeExceeded) {
        statusList.push({
          title: STATUS_TITLE_MAP[STATUS_MAP.timeExceeded],
          status: operationStatus,

          severity: operationSeverity,
          color: STATUS_COLOR_MAP[operationSeverity]
        });
      }

      if (!hideLateToStartStatus && operationDelayedStartSeverity && operationStartStatus === STATUS_MAP.lateToStart) {
        statusList.push({
          title: STATUS_TITLE_MAP[STATUS_MAP.lateToStart],
          status: operationStartStatus,
          severity: operationDelayedStartSeverity,
          color: STATUS_COLOR_MAP[operationDelayedStartSeverity]
        });
      }
    }

    if (!statusList.length) {
      statusList.push({
        title: STATUS_TITLE_MAP[STATUS_MAP.healthy],
        status: STATUS_MAP.healthy,
        color: STATUS_COLOR_MAP.Healthy,
        severity: STATUS_SEVERITY_MAP.healthy
      });
    }

    let statusPriority = 0;
    statusList.forEach(({ status, severity }) => {
      switch (status) {
        case STATUS_MAP.expedited:
          statusPriority += 40;
          break;
        case STATUS_MAP.lateToStart:
          statusPriority += LATE_TO_START_WEIGHT[severity];
          break;
        case STATUS_MAP.timeExceeded:
          statusPriority += TIME_EXCEEDED_WEIGHT[severity];
          break;
        default:
          break;
      }
    });
    const statusMap = statusList.reduce((accumulator, { title, severity }) => {
      return { ...accumulator, [`${title}${severity && severity !== title ? ` - ${severity}` : ""}`]: true };
    }, {});
    return { statusList, statusMap, statusPriority };
  };

  const getAccurateToSchedule = (eachWorkOrder) => {
    const { operationStartStatus } = eachWorkOrder;

    if (operationStartStatus === "Delayed") {
      return false;
    }
    if (operationStartStatus === "On Time") {
      return true;
    }
    return undefined;
  };

  const workOrderList = useMemo(() => {
    return workOrderDataList.map((eachWorkOrder) => {
      const { statusList, statusMap, statusPriority } = statusBuilder(eachWorkOrder);
      return {
        ...eachWorkOrder,
        status: statusList,
        statusPriority,
        ...statusMap,
        locationIdentifier: locationTreeMap[eachWorkOrder.locationId]?.name,
        accurateToSchedule: getAccurateToSchedule(eachWorkOrder)
      };
    });
  }, [workOrderDataList, locationTreeMap]);

  return workOrderList;
};
