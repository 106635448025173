import React, { useState, useEffect } from "react";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import { useQuery } from "@tanstack/react-query";
import Popover from "@material-ui/core/Popover";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import xemelgoStyle from "../../../../../../styles/variable";
import Style from "./StrategicSourcingMetrics.module.css";
import useWidgetSharableContext from "../../../../features/dashboard-grid/contexts/widget-sharable-context";
import queryFns from "../../queryFns";
import LoadingCircle from "../../../../../../components/loading/LoadingCircle";

const STATUS_COLORS = {
  healthy: xemelgoStyle.theme.STATUS_GREEN,
  critical: xemelgoStyle.theme.STATUS_RED
};

export const StrategicSourcingMetrics = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [popupMessage, setPopupMessage] = useState("");
  const { selectedStrategicSourcingTableId, setSelectedStrategicSourcingTableId } = useWidgetSharableContext();

  const metricsQuery = useQuery({
    queryKey: ["widgets", "strategic-sourcing-metrics"],
    queryFn: queryFns["strategic-sourcing-metrics"],
    refetchOnWindowFocus: false
  });

  useEffect(() => {
    if (metricsQuery.data?.length && !selectedStrategicSourcingTableId) {
      setSelectedStrategicSourcingTableId(metricsQuery.data[0].id);
    }
  }, [metricsQuery.data]);

  const onMouseLeave = () => {
    setAnchorEl(null);
  };

  return (
    <div className={Style.container}>
      <div className={Style.title}>Metrics</div>
      {metricsQuery.isLoading ? (
        <LoadingCircle />
      ) : (
        <div className={Style.metrics_group_container}>
          {(metricsQuery.data || [])
            .sort((a, b) => {
              return a.id.localeCompare(b.id, undefined, {
                numeric: true,
                sensitivity: "base"
              });
            })
            .map((metric) => {
              const isSelected = selectedStrategicSourcingTableId === metric.id;
              return (
                <div
                  key={metric.id}
                  className={`${Style.metrics_container} ${isSelected && Style.metrics_container_active}`}
                  onMouseEnter={(event) => {
                    setPopupMessage(metric.description);
                    setAnchorEl(event.currentTarget);
                  }}
                  onMouseLeave={onMouseLeave}
                  onClick={() => {
                    setSelectedStrategicSourcingTableId(metric.id);
                  }}
                >
                  <div className={Style.metrics_label}>{metric.label}</div>
                  <div className={Style.metrics_subTitle_container}>
                    <div className={Style.metrics_subTitle}>{metric.subTitle}</div>
                    <div
                      className={Style.metrics_percentage}
                      style={{
                        color: STATUS_COLORS[metric.status],
                        borderColor: STATUS_COLORS[metric.status]
                      }}
                    >
                      {!metric.percentChange ? null : metric.percentChange > 0 ? (
                        <ArrowUpwardIcon fontSize="small" />
                      ) : (
                        <ArrowDownwardIcon fontSize="small" />
                      )}
                      {metric.percentChange ? `${Math.abs(metric.percentChange)} %` : "-"}
                    </div>
                  </div>
                </div>
              );
            })}
          <Popover
            id="mouse-over-popover"
            style={{ pointerEvents: "none" }}
            open={!!anchorEl}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left"
            }}
            onClose={onMouseLeave}
            disableRestoreFocus
          >
            <div className={Style.popover_content_container}>
              <InfoOutlinedIcon className={Style.popover_content_icon} />
              <div className={Style.popover_content_text}>{popupMessage}</div>
            </div>
          </Popover>
        </div>
      )}
    </div>
  );
};
