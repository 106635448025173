export const SORT_DIRECTION = {
  ascending: "asc",
  descending: "desc"
};

export const SOLUTIONS = {
  WORK_ORDER: "workOrder",
  INVENTORY: "inventory",
  ASSET: "asset"
};

export const SOLUTION_UNITS = {
  workOrder: "work orders",
  inventory: "items",
  asset: "assets"
};

export const PRINT_TYPES = {
  ZPL: "zpl",
  BARTENDER: "bartender",
  BARTENDER_UPLOAD: "bartenderUpload"
};

export const TAB_OPTIONS = {
  SINGLE: "single",
  BULK: "bulk"
};
