import React, { useEffect, useState } from "react";
import { ModalForm } from "../../../../components/modal-form";
import { useXemelgoClient } from "../../../../services/xemelgo-service";
import { naturalSort } from "../../../../common/Utilities";
import Style from "./SetLocationModal.module.css";

const SetLocationModal = ({ orderIds, locationCategory, onClose }) => {
  const [client] = useState(useXemelgoClient());
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [locationList, setLocationList] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);

  useEffect(() => {
    onLoad();
  }, []);

  const onLoad = async () => {
    const LocationClient = await client.getLocationClient();
    const batchUpdateLocations = await LocationClient.getLocationsOfCategory(locationCategory);
    const formattedLocations = batchUpdateLocations.map((location) => {
      return {
        id: location.id,
        name: location.name || location.identifier
      };
    });
    setLocationList(naturalSort(formattedLocations, "name"));
    setIsLoading(false);
  };

  const onSubmit = async () => {
    const PublishClient = client.getPublishClient();
    let message;
    let success = true;
    const location = locationList.find((loc) => loc.id === selectedLocation);
    try {
      await PublishClient.publishUserEvent(orderIds, selectedLocation);
      message = `Item(s) successfully moved to ${location.name}. Please refresh the page in order to pull in the latest data`;
    } catch (e) {
      message = "Failed to move item(s)";
      success = false;
    }
    onClose(true, success, message);
  };

  const getTitle = () => {
    if (!isSubmitting) {
      return <div>Set Location</div>;
    }
    return <div>{`Move ${orderIds.length} Item(s)?`}</div>;
  };

  const getBody = () => {
    if (isLoading) {
      return <div>Processing...</div>;
    }

    if (!isSubmitting) {
      return (
        <div>
          <select
            onChange={(event) => {
              setSelectedLocation(event.target.value);
            }}
            value={selectedLocation || locationList[0]?.id}
            className={Style.set_location_dropdown}
          >
            {locationList.map((location) => {
              return (
                <option
                  key={location.id}
                  value={location.id}
                >
                  {location.name}
                </option>
              );
            })}
          </select>
        </div>
      );
    }
    const location = locationList.find((location) => {
      return location.id === selectedLocation;
    });

    return <div>{`The selected ${orderIds.length} item(s) will be moved to ${location?.name}`}</div>;
  };

  const getFooter = () => {
    return (
      <div className={Style.modal_footer_container}>
        <button
          onClick={() => {
            onClose(false);
          }}
          className={`${Style.form_button} ${Style.cancel_button}`}
          disabled={isLoading}
        >
          Cancel
        </button>
        <button
          onClick={() => {
            if (!isSubmitting) {
              setIsSubmitting(true);
            } else {
              setIsLoading(true);
              onSubmit();
            }
          }}
          className={`${Style.form_button} ${Style.confirm_button}`}
          disabled={isLoading}
        >
          {isSubmitting ? "Confirm" : "Submit"}
        </button>
      </div>
    );
  };

  return (
    <ModalForm
      show
      title={getTitle()}
      body={getBody()}
      footer={getFooter()}
      centered
    />
  );
};

export { SetLocationModal };
