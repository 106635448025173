export const SOLUTION_ID = {
  ORDER: "order",
  ORDER_TRACKING: "order-tracking",
  INVENTORY: "inventory",
  INVENTORY_TRACKING: "inventory-tracking",
  INVENTORY_APPROVAL_MANAGEMENT: "inventory-approval-management",
  GOODS_RECEIPT: "goods-receipt",
  ASSET: "asset",
  ASSET_TRACKING: "asset-tracking",
  ASSET_MAP: "asset-map",
  CREATE_ASSET: "create-asset",
  SHIPMENTS: "shipments",
  PURCHASE_ORDER: "purchase-order",
  MANAGEMENT_DASHBOARD: "management-dashboard",
  SCHEDULING_MANAGEMENT: "scheduling-management",
  PACKAGE: "package",
  DASHBOARD: "dashboard"
};

export const SETTING_ID = {
  REPORT: "reports",
  ITEM_TYPES_MANAGEMENT: "item-types-management",
  MY_FACILITY: "my-facility",
  USERS: "users",
  ALERTS: "alerts"
};
